import React from "react";

function FeatureFooter({ data }) {
  return (
    <div className="bg-gray-100 min-h-[65vh] p-8 sm:p-16 sm:pb-0 lg:p-32 pt-16 rounded">
      <div className="flex flex-col lg:flex-row bg-black rounded-xl">
        {/* Left Section */}
        <div
          className={`w-full lg:w-3/5 bg-green p-8 lg:p-16 ${data.className} mt-8`}
        >
          <h1 className="text-2xl sm:text-3xl lg:text-4xl font-bold text-white mb-4 mr-24">
            {data.title}
          </h1>
          <p className="text-md sm:text-lg text-white">{data.description}</p>
          <button className="bg-white hover:bg-gray-500 text-black py-2 px-8 rounded-xl mt-6">
            {data.ctaText1} <i className="fa fa-arrow-right ml-2"></i>
          </button>
          {data.ctaText2 && (
            <button className="bg-black hover:bg-gray-500 text-white py-2 px-4 rounded-xl border border-white ml-2 mt-6">
              {data.ctaText2}
            </button>
          )}
        </div>

        {/* Right Section (Image) */}
        <div className="w-full lg:w-2/5 bg-red p-8 lg:p-16">
          <img
            src={data.image}
            alt={data.altText}
            className="w-full h-full rounded-2xl object-cover"
          />
        </div>
      </div>
    </div>
  );
}

export default FeatureFooter;

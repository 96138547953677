import React, { useState } from "react";
import Footer from "../../components/Footer";
import Navbar from "../../components/Navbar";
import FeatureFooter from "../../components/FeatureFooter";

const features = [
  {
    title: "Enhances understanding by offering step-by-step guidance.",
    content: "Enhances understanding by offering step-by-step guidance.",
  },
  {
    title: "Saves time by clarifying doubts instantly.",
    content: "Explore scientific experiments virtually with interactive tools.",
  },
  {
    title: "Supports personalized learning tailored to individual needs.",
    content: "Build dynamic timelines to visualize historical events.",
  },
  {
    title: "Encourages critical thinking and problem-solving skills.",
    content: "Collaborate on brainstorming ideas using interactive mind maps.",
  },
  {
    title: "Empowers students as an aid tool, not as a do-everything solution.",
    content:
      "Empowers students as an aid tool, not as a do-everything solution.",
  },
];

const features2 = [
  {
    title: "Getting step-by-step explanations for complex math problems.",
    content: "Enhances understanding by offering step-by-step guidance.",
  },
  {
    title:
      "Simplifying difficult science concepts into easily digestible insights.",
    content: "Explore scientific experiments virtually with interactive tools.",
  },
  {
    title: "Assisting with drafting and reviewing essays or assignments.",
    content: "Build dynamic timelines to visualize historical events.",
  },
  {
    title: "Providing tailored study tips and learning techniques.",
    content: "Collaborate on brainstorming ideas using interactive mind maps.",
  },
  {
    title: "Offering alternate approaches to tackle challenging questions.",
    content:
      "Empowers students as an aid tool, not as a do-everything solution.",
  },
];

const footerData = {
  title: "AI-Assisted, You-Driven: Unleash Your Creative Potential.",
  description:
    "At Scoolish, we believe in the synergy between human creativity and artificial intelligence. Our mission is to empower users to harness AI as a supportive tool that complements their unique skills and insights, fostering independence and originality in their projects.",
  ctaText1: "Get Started",
  image: "/assets/images/future.png",
  altText: "Fun Learning",
  className: "pt-11",
};

function AiHomework() {
  const [openIndex, setOpenIndex] = useState(null);
  return (
    <>
      <Navbar />
      {/** Header Section */}
      <div className="bg-black w-full h-auto p-6 pb-16">
        <div className="flex flex-col md:flex-row items-center bg-black mt-24 pt-14 px-6 md:px-20 pb-10 justify-between">
          <div className="w-full md:w-1/2 mb-6 md:mb-0 px-2 md:px-4">
            <h3 className="text-3xl sm:text-4xl md:text-5xl text-white font-bold mb-2">
              Your Smart Companion for Smarter Studies!
            </h3>
            <p className="text-sm sm:text-md md:text-lg mb-4 text-white">
              Get guidance, clarity, and instant solutions for tricky homework
              challenges. The AI-Powered Homework Helper is designed to empower
              students, not replace their efforts.
            </p>

            {/* Flex container for the buttons */}
            <div className="flex flex-col sm:flex-row sm:space-x-6 mb-6">
              <button className="bg-white hover:bg-gray-300 font-bold text-sm sm:text-lg py-3 px-6 rounded-lg mt-6 sm:mt-0">
                Explore Tools
              </button>
              <button className="bg-black text-white border-2 border-white hover:bg-gray-300 font-bold text-sm sm:text-lg py-2.5 px-16 rounded-lg mt-6 sm:mt-0">
                Get Now
              </button>
            </div>
          </div>

          <div className="w-full md:w-1/2 lg:pl-6">
            <img
              src="/assets/images/scienceExp.png"
              alt="AI Homework Helper"
              className="w-full h-auto rounded-xl"
            />
          </div>
        </div>
      </div>

      {/**Hero Section */}
      <div className="bg-gray-100 w-full h-auto md:h-[78vh] p-6 sm:p-12 md:p-16 md:pb-0 lg:p-24 lg:pb-0">
        <div className="flex flex-col md:flex-row items-center justify-between">
          <div className="w-full md:w-1/2 mb-6 md:mb-0 px-4 md:px-8">
            <h3 className="text-2xl sm:text-3xl md:text-4xl font-bold mb-2">
              AI-Powered Homework Helper
            </h3>
            <p className="text-sm sm:text-md md:text-lg text-semibold mb-4">
              Simplifies and enhances the learning process by providing detailed
              solutions for homework problems.
            </p>
            <div className="flex items-center mb-4">
              <label className="text-sm sm:text-md md:text-lg font-inter inline-flex">
                <div className="w-4 h-4 mr-2 mt-1 bg-white border border-black rounded-full"></div>
                Upload or Type Questions
              </label>
            </div>
            <div className="flex items-center mb-4">
              <label className="text-sm sm:text-md md:text-lg font-inter inline-flex">
                <div className="w-4 h-4 mr-2 mt-1 bg-white border border-black rounded-full"></div>
                Receive step-by-step AI-generated solutions and insights.
              </label>
            </div>
            <button className="bg-gray-100 border border-black hover:bg-gray-500 font-bold py-3 text-md sm:text-lg md:text-xl px-8 rounded-xl mt-6">
              Explore Now
            </button>
          </div>
          <div className="w-full md:w-1/2">
            <img
              src="/assets/images/hwsolver.png"
              alt="AI Homework Helper"
              className="w-full h-[30vh] sm:h-[40vh] md:h-[50vh] rounded-xl object-cover"
            />
          </div>
        </div>
      </div>
      {/** Features 1 */}
      <div className="bg-gray-100 w-full p-6 sm:p-12 md:p-16 md:pt-0 lg:p-32 lg:pt-6">
        {/* Header Section */}
        <div className="flex flex-col lg:flex-row justify-between items-center mb-6">
          <div className="flex-1 mb-6 lg:mb-0 px-2 md:px-4">
            <h1 className="text-3xl sm:text-4xl lg:text-5xl font-bold text-black mb-2">
              Use Cases AI-Powered Homework Helper
            </h1>
            <p className="text-sm sm:text-md md:text-lg lg:text-xl text-black mb-4 sm:mr-0 lg:mr-48">
              AI-powered homework helpers provide personalized learning
              experiences, adapting to individual student needs and learning
              styles. They enhance understanding and retention of concepts by
              offering instant feedback and resources, making studying more
              efficient and engaging.
            </p>
          </div>
          <div className="self-start pt-6 py-1.5">
            <button className="bg-black text-white px-6 sm:px-8 lg:px-10 py-4 rounded-md">
              Get This Now
            </button>
          </div>
        </div>

        {/* Accordion and Image Section */}
        <div className="flex flex-col lg:flex-row flex-wrap">
          {/* Accordion Section */}
          <div className="w-full lg:w-[40%] xl:w-2/5 mb-6 lg:mb-0">
            {features.map((feature, index) => (
              <div
                key={index}
                className={`border-b border-black-300 py-4 cursor-pointer ${
                  openIndex === index ? "border-l-4 border-black" : ""
                }`}
              >
                <div
                  className="flex justify-between items-center"
                  onClick={() =>
                    setOpenIndex(index === openIndex ? null : index)
                  }
                >
                  <h2 className="text-xl sm:text-2xl font-bold text-black">
                    {feature.title}
                  </h2>
                  <span>
                    {openIndex === index ? (
                      <i className="fa fa-angle-up"></i>
                    ) : (
                      <i className="fa fa-angle-down"></i>
                    )}
                  </span>
                </div>
                {openIndex === index && (
                  <>
                    <div
                      className="text-black mt-2"
                      dangerouslySetInnerHTML={{ __html: feature.content }}
                    />
                    <button className="bg-gray-100 border border-black py-2 px-4 rounded-md mt-4">
                      Know More <i className="fa fa-angle-right"></i>
                    </button>
                  </>
                )}
              </div>
            ))}
          </div>

          {/* Image Section */}
          <div className="pl-6 lg:pl-20 w-full lg:w-[60%] xl:w-3/5 mt-6 lg:mt-0">
            <div className="rounded overflow-hidden">
              <img
                src="/assets/images/accordianImage.png"
                alt="Placeholder"
                className="w-full h-full object-cover object-center"
              />
            </div>
          </div>
        </div>
      </div>

      {/** Features 2 */}
      <div
        className="bg-gray-100 w-full p-6 sm:p-12 md:p-16 lg:p-32"
        style={{ backgroundImage: "url('/assets/images/background.png')" }}
      >
        {/* Header Section */}
        <div className="flex flex-col lg:flex-row justify-between items-center mb-6">
          <div className="flex-1 mb-6 lg:mb-0 px-2 md:px-4">
            <h1 className="text-3xl sm:text-4xl lg:text-5xl font-bold text-black mb-2">
              Use Cases AI-Powered Homework Helper
            </h1>
            <p className="text-sm sm:text-md md:text-lg lg:text-xl text-black mb-4 sm:mr-0 lg:mr-48">
              Unlock a world of possibilities with Scoolish’s AI-powered tools,
              designed to make learning interactive, creative, and fun. From
              homework assistance to 3D modeling and virtual labs, every tool is
              crafted to inspire and empower young minds.
            </p>
          </div>
          <div className="self-start pt-6 py-1.5">
            <button className="bg-black text-white px-6 sm:px-8 lg:px-10 py-4 rounded-md">
              Try Yourself
            </button>
          </div>
        </div>

        {/* Accordion and Image Section */}
        <div className="flex flex-col lg:flex-row flex-wrap">
          {/* Image Section */}
          <div className="w-full lg:w-[55%] xl:w-3/5 mt-6">
            <div className="rounded overflow-hidden">
              <img
                src="/assets/images/aihome.png"
                alt="Placeholder"
                className="w-full h-full object-cover object-center"
              />
            </div>
          </div>

          {/* Accordion Section */}
          <div className="pl-6 lg:pl-10 w-full lg:w-[45%] xl:w-2/5 mt-6 lg:mt-0">
            {features2.map((feature, index) => (
              <div
                key={index}
                className={`border-b border-black-300 py-4 cursor-pointer ${
                  openIndex === index ? "border-l-4 border-black" : ""
                }`}
              >
                <div
                  className="flex justify-between items-center"
                  onClick={() =>
                    setOpenIndex(index === openIndex ? null : index)
                  }
                >
                  <h2 className="text-xl sm:text-2xl font-bold text-black">
                    {feature.title}
                  </h2>
                  <span>
                    {openIndex === index ? (
                      <i className="fa fa-angle-up"></i>
                    ) : (
                      <i className="fa fa-angle-down"></i>
                    )}
                  </span>
                </div>
                {openIndex === index && (
                  <>
                    <div
                      className="text-black mt-2"
                      dangerouslySetInnerHTML={{ __html: feature.content }}
                    />
                    <button className="bg-gray-100 border border-black py-2 px-4 rounded-md mt-4">
                      Know More <i className="fa fa-angle-right"></i>
                    </button>
                  </>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>

      <FeatureFooter data={footerData} />
      <div className=" bg-gray-100 lg:pt-4 md:pt-12 sm:pt-12"></div>
      <Footer />
    </>
  );
}

export default AiHomework;

import React from "react";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import CardCarousel from "../../components/CardCarousel";
import FeatureHeader from "../../components/FeatureHeader";
import FeatureHeroSection from "../../components/FeatureHeroSection";
import FeatureFooter from "../../components/FeatureFooter";
import FeatureUpload from "../../components/FeatureUpload";
import FeatureBenefitsType1 from "../../components/FeatureBenefitsType1";
import FAQSection from "../../components/FAQSection";

const topSectionData = {
  title: "Understand the Emotions Behind Your Content",
  description:
    "Harness the power of AI to decode sentiments in text, audio, and video. Whether it’s positive, negative, or neutral, gain emotional insights at scale.",
  ctaText: "Try Sentiment Analysis",
  ctaText2: "Explore More Features",
  image: "/assets/images/sentiment1.png",
  altText: "AI Homework Helper",
};

const heroSectionData = {
  title: "What is Sentiment Analysis?",
  description:
    "Sentiment Analysis uses advanced AI models to identify and interpret emotions in your content. It categorizes text, audio, or video into emotional tones, providing actionable insights to improve decision-making, enhance user experiences, and understand audience sentiments.",
  radioOptions: [
    {
      id: "timesave",
      label:
        "Emotion Detection: Uncover hidden emotions in user feedback or conversations.",
    },
    {
      id: "accuracy",
      label:
        "Real-Time Insights: Analyze sentiments as they happen for faster responses.",
    },
    {
      id: "versatile",
      label:
        "Actionable Data: Tailor strategies based on emotional trends in content.",
    },
    {
      id: "productivity",
      label: "Multimodal Support: Works across text, audio, and video formats.",
    },
  ],
  image: "/assets/images/sentiment2.png",
  altText: "AI Homework Helper",
};

const cardsData = [
  {
    image: "/assets/images/sentiment3.png",
    title: "Text Sentiment Analysis",
    description:
      "What It Does: Detects emotions like happiness, anger, sadness, or neutrality in written content.",
    features: [
      "Analyzing customer feedback or reviews.",
      "Understanding audience reactions in social media posts.",
    ],
  },
  {
    image: "/assets/images/sentiment4.png",
    title: "Audio Sentiment Analysis",
    description:
      "What It Does: Analyzes tone, pitch, and speech patterns to identify emotions in spoken content.",
    features: [
      "Enhancing customer support by detecting frustration in calls",
      "Tracking emotional shifts in interviews or podcasts",
    ],
  },
  {
    image: "/assets/images/sentiment5.png",
    title: "Video Sentiment Analysis",
    description:
      "What It Does: Combines facial expressions, tone, and contextual analysis to evaluate emotions in videos",
    features: [
      "Analyzing audience reactions to marketing campaigns",
      "Detecting emotions during virtual meetings or webinars",
    ],
  },
  {
    image: "/assets/images/sentiment6.png",
    title: "Emotion Scoring",
    description:
      "What It Does: Assigns numerical scores to emotions (e.g., +1 for positive, -1 for negative) for quantitative insights",
    features: [
      "Monitoring trends in customer satisfaction",
      "Building emotional datasets for research",
    ],
  },
];

const uploadData = {
  headerSubtitle: "How It Works",
  headerTitle: "How Does Sentiment Analysis Work?",
  image: "/assets/images/segs.png",
  altText: "Fun Learning",
  steps: [
    {
      title: "Upload Your File",
      description: "Add text, audio, or video for Analysis.",
    },
    {
      title: "Choose Sentiment Type",
      description: "Select overall sentiment or deep emotion detection.",
    },
    {
      title: "View Results",
      description:
        "Receive detailed sentiment insights with scores and labels.",
    },
    {
      title: "Export Data",
      description: "Download emotional analysis reports in CSV, PDF, or JSON.",
    },
  ],
};

const featureType1Data = {
  headerTitle: "Who Benefits from Sentiment Analysis?",
  headerDescription:
    "Unlock a world of possibilities with Scoolish’s AI-powered tools, designed to make learning interactive, creative, and fun. From homework assistance to 3D modeling and virtual labs, every tool is crafted to inspire and empower young minds.",
  bannerImage: "/assets/images/sentiana.jpg",
  bannerAltText: "Focused Group",
  bannerTitle: "Benefit From Sentiment Analysis",
  bannerCtaText: "Get in Touch",
  cardsData: [
    {
      title: "Customer Experience Teams",
      description:
        "Analyze support calls or chat feedback for customer satisfaction.",
      icon: "fa-solid fa-location-crosshairs text-3xl",
    },
    {
      title: "Marketers",
      description:
        "Evaluate audience emotions to improve campaigns or content strategies.",
      icon: "fa-solid fa-location-crosshairs text-3xl",
    },
    {
      title: "Human Resources",
      description: "Gauge employee morale during meetings or surveys",
      icon: "fa-solid fa-location-crosshairs text-3xl",
    },
    {
      title: "Researchers",
      description:
        "Understand emotional trends in social, political, or market research.",
      icon: "fa-solid fa-location-crosshairs text-3xl",
    },
    {
      title: "Educators",
      description:
        "Detect emotional tones in classroom feedback or e-learning modules.",
      icon: "fa-solid fa-location-crosshairs text-3xl",
    },
  ],
};

const questions = [
  {
    title: "What Types Of Files Are Supported?",
    content: "We support text, Word documents, PDFs, and more.",
  },
  {
    title: "How Accurate Is The Summary?",
    content:
      "Our summaries aim for high accuracy, though results may vary depending on the content provided.",
  },
  {
    title: "Can I Customize The Length Of The Summary?",
    content: "Yes, you can customize the length to suit your needs.",
  },
  {
    title: "Collaborative Mind Mapping",
    content:
      "Work with others in real-time to create structured summaries and ideas.",
  },
];

const footerData = {
  title: "Ready to Decode Emotions?",
  description:
    "At Scoolish, we believe in the synergy between human creativity and artificial intelligence. Our mission is to empower users to harness AI as a supportive tool that complements their unique skills and insights, fostering independence and originality in their projects.",
  ctaText1: "Try Sentiment Analysis",
  ctaText2: "Sign Up for Scoolish",
  image: "/assets/images/sa.png",
  altText: "Future Learning",
  className: "pt-18",
};

function SentimentAnalysis() {
  return (
    <>
      <Navbar />
      <FeatureHeader data={topSectionData} />
      <FeatureHeroSection data={heroSectionData} />
      <div className="bg-gray-100 h-[60%] text-left pt-0">
        <CardCarousel cardsData={cardsData} buttonText={null} />
      </div>
      <FeatureUpload data={uploadData} />
      <div
        className="bg-cover bg-center bg-no-repeat h-screen w-full"
        style={{ backgroundImage: "url('/assets/images/background.png')" }}
      >
        <FeatureBenefitsType1 data={featureType1Data} />
      </div>
      <div className="p-16 bg-gray-100">
        {/* Header Section */}
        <div className="flex justify-between items-center">
          <div className="flex-1">
            <h1 className="text-4xl text-center text-poppins font-bold text-gray-900 mb-4">
              Try Sentiment Analysis in Action!
            </h1>
            <p className="text-[#212121] text-center text-xl mb-6">
              {" "}
              Discover How Scoollish Features Simplify Text Analysis, Enhance
              Understanding,
              <br /> and Unlock Hidden Insights for Smarter Decisions
            </p>
          </div>
        </div>
        <div className="px-24">
          <div className="p-3 bg-white rounded-lg shadow-md">
            {" "}
            <img
              src="/assets/images/sa1.png"
              className="w-full h-[85vh] object-cover rounded-md"
              alt="Segmentation"
            />
          </div>
        </div>
      </div>
      <FAQSection questions={questions} />
      <FeatureFooter data={footerData} />
      <div className=" bg-gray-100 pt-24 "></div>
      <Footer />
    </>
  );
}

export default SentimentAnalysis;

import React, { useState } from "react";
import Sidebar from "../../components/Sidebar";

const Dashboard = () => {
  const [isDarkMode, setIsDarkMode] = useState(false);

  const toggleDarkMode = () => {
    setIsDarkMode(!isDarkMode);
  };

  return (
    <div
      className={`flex font-inter bg-${isDarkMode ? "black" : "white"} text-${
        isDarkMode ? "white" : "gray-600"
      } h-screen`}
    >
      <Sidebar isDarkMode={isDarkMode} toggleDarkMode={toggleDarkMode} />
      <div className="w-[85%] flex-0 overflow-y-auto bg-white-100">
        <header
          className={`flex pb-2 ${
            isDarkMode ? "border-b border-gray-600" : "shadow-md"
          } bg-${isDarkMode ? "black" : "white"} text-${
            isDarkMode ? "white" : "gray-600"
          }`}
        >
          {/* Search bar */}
          <div className="flex items-left">
            <div className="flex space-x-4 ml-4 mt-1 rounded-lg border border-gray items-center flex-1">
              <span className="flex items-center">
                <button className="text-gray-400 hover:text-gray-500 p-2 rounded-l-lg">
                  <i className="fa fa-search pl-1"></i>
                </button>
                <input
                  type="text"
                  placeholder="Search Projects, features and tools"
                  className={`px-2 py-2.5 text-sm  bg-${
                    isDarkMode ? "black" : "white"
                  } placeholder-gray-400 w-[28rem]`}
                />
                <button className="text-gray-400 hover:text-gray-500 p-2 rounded-r-lg">
                  <i className="fa fa-microphone pr-1"></i>
                </button>
              </span>
            </div>
          </div>
          {/* Profile section */}
          <div className="flex ml-auto">
            <div className="flex space-x-6 text-black items-center pt-2 pl-8 pr-4 rounded-lg ">
              <div className="w-10 h-10 rounded-full flex items-center justify-center"></div>
              <img
                src="/assets/images/avatar.png"
                alt="User Avatar"
                className="w-10 h-10 rounded-full"
              />
              <span
                className={`bg-${isDarkMode ? "black" : "white"} text-${
                  isDarkMode ? "white" : "gray-600"
                }`}
              >
                Novneet <i className="fa fa-chevron-down ml-4"></i>
              </span>
            </div>
          </div>
        </header>
        <main className="text-inner ">
          {/* Top Section */}
          <section className="px-5 pt-4 pb-2">
            <div className="bg-gradient-to-b from-[#6D3AFF] to-[#422399] p-8 text-gray-100 rounded-lg">
              <h2 className="text-2xl font-semibold">
                Explore the all new 3D AI Drawing Section
              </h2>
              <h2 className="text-[12px] text-gray-100">
                Ai Powered gpt models, this module will bring out your creative
                best
              </h2>
              <button className="bg-white font-bold text-black text-xs mt-4 px-6 py-2 rounded-full mr-2">
                Try It Now
              </button>
              <button className="bg-[#6633FF] font-bold text-white text-xs px-6 py-2 rounded-full">
                More Details
              </button>
            </div>
          </section>

          {/* Testing Features */}
          <section className="px-5 text-black">
            <span
              className={`text-lg font-semibold text-${
                isDarkMode ? "white" : "black"
              } `}
            >
              Start with testing Scoolish features
            </span>
            <div className="pt-1 grid grid-cols-3 gap-4">
              {/* First Button */}
              <div
                className={`flex items-center justify-center py-4 rounded-lg border ${
                  isDarkMode
                    ? "border-[#6D3AFF] bg-[#14004d]"
                    : "border-purple-300 bg-purple-50"
                }`}
              >
                <div className="flex items-center space-x-2">
                  <div
                    className={`flex items-center justify-center w-8 h-8 rounded-full ${
                      isDarkMode ? "bg-[#0e0033]" : "bg-purple-100"
                    }`}
                  >
                    <i
                      className={`fa fa-plus ${
                        isDarkMode ? "text-purple-300" : "text-purple-500"
                      }`}
                    ></i>
                  </div>
                  <h4
                    className={`font-medium ${
                      isDarkMode ? "text-white" : "text-purple-700"
                    }`}
                  >
                    Start a New Research
                  </h4>
                </div>
              </div>

              {/* Second Button */}
              <div
                className={`flex items-center justify-center py-4 rounded-lg border ${
                  isDarkMode
                    ? "border-[#663f00] bg-[#331f00]"
                    : "border-yellow-300 bg-yellow-50"
                }`}
              >
                <div className="flex items-center space-x-2">
                  <div
                    className={`flex items-center justify-center w-8 h-8 rounded-full ${
                      isDarkMode ? "bg-[#663f00]" : "bg-yellow-100"
                    }`}
                  >
                    <i
                      className={`fa fa-file-alt ${
                        isDarkMode ? "text-yellow-300" : "text-yellow-500"
                      }`}
                    ></i>
                  </div>
                  <h4
                    className={`font-medium ${
                      isDarkMode ? "text-yellow-300" : "text-yellow-700"
                    }`}
                  >
                    Create a Comic
                  </h4>
                </div>
              </div>

              {/* Third Button */}
              <div
                className={`flex items-center justify-center py-4 rounded-lg border ${
                  isDarkMode
                    ? "border-[#003066] bg-[#001933]"
                    : "border-blue-300 bg-blue-50"
                }`}
              >
                <div className="flex items-center space-x-2">
                  <div
                    className={`flex items-center justify-center w-8 h-8 rounded-full ${
                      isDarkMode ? "bg-[#00244d]" : "bg-blue-100"
                    }`}
                  >
                    <i
                      className={`fa fa-search ${
                        isDarkMode ? "text-blue-300" : "text-blue-500"
                      }`}
                    ></i>
                  </div>
                  <h4
                    className={`font-medium ${
                      isDarkMode ? "text-blue-300" : "text-blue-700"
                    }`}
                  >
                    Explore Knowledge Vault
                  </h4>
                </div>
              </div>
            </div>
          </section>

          {/* Tools Section */}
          <section className="px-5 py-1 text-black">
            <span
              className={`text-lg font-semibold text-${
                isDarkMode ? "white" : "black"
              } `}
            >
              Create
            </span>
          </section>
        </main>
      </div>
    </div>
  );
};

export default Dashboard;

import React from "react";

const Sidebar = ({ isDarkMode, toggleDarkMode }) => {
  return (
    <div
      className={`w-[15%] bg-${isDarkMode ? "black" : "white"} text-${
        isDarkMode ? "white" : "gray-600"
      } flex flex-col  ${
        isDarkMode ? "border-r border-gray-600" : "shadow-md"
      } justify-between px-2 pb-2`}
    >
      <div
        className={` bg-${
          isDarkMode ? "black" : "white"
        } flex items-center space-x-0.5 pt-4`}
      >
        <img
          src={`/assets/images/${isDarkMode ? "whiteOwlLogo" : "owlLogo"}.png`}
          alt="Scoolish Logo"
          className="w-12 h-12 "
        />
        <span
          className={`text-${
            isDarkMode ? "white" : "black"
          } font-raleway font-bold text-xl`}
        >
          Scoolish
        </span>
      </div>
      {/* Top Section */}
      <div>
        {/* Overview */}
        <div
          className={`px-1.5 py-0.25 font-inter text-${
            isDarkMode ? "gray-400" : "gray-600"
          } text-xs pt-4 pb-1 `}
        >
          OVERVIEW
        </div>
        <ul>
          <li
            className={`flex text-${
              isDarkMode ? "white" : "black"
            } text-[11px] font-inter items-center px-2 py-0.5 hover:bg-${
              isDarkMode ? "gray-700" : "gray-100"
            }`}
          >
            <i
              className={`fa fa-home  pr-2 text-${
                isDarkMode ? "white" : "black"
              }`}
              aria-hidden="true"
            ></i>
            Home
          </li>
        </ul>

        {/* Library */}
        <div
          className={`px-1.5 py-0.25 font-inter text-${
            isDarkMode ? "gray-400" : "gray-600"
          } text-xs pt-2 pb-1`}
        >
          LIBRARY
        </div>
        <ul>
          <li
            className={`flex text-${
              isDarkMode ? "white" : "black"
            } text-[11px] font-inter items-center px-2 py-0.5 hover:bg-${
              isDarkMode ? "gray-700" : "gray-100"
            }`}
          >
            <i
              className={`fa fa-download pt-0.5 pr-2 text-${
                isDarkMode ? "white" : "black"
              }`}
              aria-hidden="true"
            ></i>
            My Resources
          </li>
          <li
            className={`flex text-${
              isDarkMode ? "white" : "black"
            } text-[11px] font-inter items-center px-2 py-0.5 hover:bg-${
              isDarkMode ? "gray-700" : "gray-100"
            }`}
          >
            <i
              className={`fa fa-folder pt-0.5 pr-2 text-${
                isDarkMode ? "white" : "black"
              }`}
              aria-hidden="true"
            ></i>
            Shared Resources
          </li>
          <li
            className={`flex text-${
              isDarkMode ? "white" : "black"
            } text-[11px] font-inter items-center px-2 py-0.5 hover:bg-${
              isDarkMode ? "gray-700" : "gray-100"
            }`}
          >
            <i
              class={`fa fa-tasks pt-0.5 pr-2 text-${
                isDarkMode ? "white" : "black"
              }`}
              aria-hidden="true"
            ></i>
            Favourite Resources
          </li>
          <li
            className={`flex text-${
              isDarkMode ? "white" : "black"
            } text-[11px] font-inter items-center px-2 py-0.5 hover:bg-${
              isDarkMode ? "gray-700" : "gray-100"
            }`}
          >
            <i
              class={`fa fa-users pt-0.5 pr-2 text-${
                isDarkMode ? "white" : "black"
              }`}
              aria-hidden="true"
            ></i>
            Recent Tools
          </li>
          <li
            className={`flex text-${
              isDarkMode ? "white" : "black"
            } text-[11px] font-inter items-center px-2 py-0.5 hover:bg-${
              isDarkMode ? "gray-700" : "gray-100"
            }`}
          >
            More Tools{" "}
            <i
              class={`fa fa-angle-right pt-0.5 pl-2 text-${
                isDarkMode ? "white" : "black"
              }`}
              aria-hidden="true"
            ></i>
          </li>
        </ul>

        {/* Tools */}
        <div
          className={`px-1.5 py-0.25 font-inter text-${
            isDarkMode ? "gray-400" : "gray-600"
          } text-xs pt-2 pb-1 `}
        >
          TOOLS
        </div>
        <ul>
          <li
            className={`flex text-${
              isDarkMode ? "white" : "black"
            } text-[11px] font-inter items-center px-2 py-0.5 hover:bg-${
              isDarkMode ? "gray-700" : "gray-100"
            }`}
          >
            <i
              className={`fa fa-download pt-0.5 pr-2 text-${
                isDarkMode ? "white" : "black"
              }`}
              aria-hidden="true"
            ></i>{" "}
            AI Homework Helper
          </li>
          <li
            className={`flex text-${
              isDarkMode ? "white" : "black"
            } text-[11px] font-inter items-center px-2 py-0.5 hover:bg-${
              isDarkMode ? "gray-700" : "gray-100"
            }`}
          >
            <i
              className={`fa fa-folder pt-0.5 pr-2 text-${
                isDarkMode ? "white" : "black"
              }`}
              aria-hidden="true"
            ></i>
            Comics Creator
          </li>
          <li
            className={`flex text-${
              isDarkMode ? "white" : "black"
            } text-[11px] font-inter items-center px-2 py-0.5 hover:bg-${
              isDarkMode ? "gray-700" : "gray-100"
            }`}
          >
            <i
              class={`fa fa-language pt-0.5 pr-2 text-${
                isDarkMode ? "white" : "black"
              }`}
              aria-hidden="true"
            ></i>
            AI Language Translator
          </li>
          <li
            className={`flex text-${
              isDarkMode ? "white" : "black"
            } text-[11px] font-inter items-center px-2 py-0.5 hover:bg-${
              isDarkMode ? "gray-700" : "gray-100"
            }`}
          >
            All Tools{" "}
            <i
              class={`fa fa-angle-right pt-0.5 pl-2 text-${
                isDarkMode ? "white" : "black"
              }`}
              aria-hidden="true"
            ></i>
          </li>
        </ul>
      </div>

      {/* Ai Labs */}

      <div
        className={`px-1.5 py-0.25 font-inter text-${
          isDarkMode ? "gray-400" : "gray-600"
        } text-xs pt-2 pb-1 `}
      >
        AI LAB
      </div>
      <ul>
        <li
          className={`flex text-${
            isDarkMode ? "white" : "black"
          } text-[11px] font-inter items-center px-2 py-0.5 hover:bg-${
            isDarkMode ? "gray-700" : "gray-100"
          }`}
        >
          <i
            className={`fa fa-download pt-0.5 pr-2 text-${
              isDarkMode ? "white" : "black"
            }`}
            aria-hidden="true"
          ></i>
          Research
        </li>
        <li
          className={`flex text-${
            isDarkMode ? "white" : "black"
          } text-[11px] font-inter items-center px-2 py-0.5 hover:bg-${
            isDarkMode ? "gray-700" : "gray-100"
          }`}
        >
          <i
            className={`fa fa-folder pt-0.5 pr-2 text-${
              isDarkMode ? "white" : "black"
            }`}
            aria-hidden="true"
          ></i>
          Learning path
        </li>
      </ul>

      {/* Bottom Section */}
      <div className="mt-auto ">
        <button
          className={`flex text-${
            isDarkMode ? "white" : "black"
          } text-[11px] font-inter items-center px-2 pt-2 hover:bg-${
            isDarkMode ? "gray-700" : "gray-100"
          }`}
        >
          <i
            className={`fa fa-sign-out pt-0.5 pr-2 text-${
              isDarkMode ? "white" : "black"
            }`}
            aria-hidden="true"
          ></i>{" "}
          Logout
        </button>
        <div className="flex items-center justify-between px-2 py-1">
          <span
            className={`text-${
              isDarkMode ? "white" : "black"
            } text-[11px] font-inter`}
          >
            <i
              class={`fa fa-moon pt-0.5 pr-2 text-${
                isDarkMode ? "white" : "black"
              }`}
              aria-hidden="true"
            ></i>{" "}
            Dark mode
          </span>
          <button
            onClick={toggleDarkMode}
            className={`w-10 h-6 rounded-full relative transition-all ${
              isDarkMode ? "bg-gray-600" : "bg-gray-300"
            }`}
          >
            <span
              className={`absolute top-1 w-4 h-4 rounded-full bg-${
                isDarkMode ? "black" : "white"
              } transition-all flex items-center justify-center ${
                isDarkMode ? "left-5" : "left-1"
              }`}
            >
              <i
                className={`fa fa-${isDarkMode ? "moon" : "sun"} text-${
                  isDarkMode ? "white" : "black"
                }  text-[10px]`}
                aria-hidden="true"
              ></i>
            </span>
          </button>
        </div>
      </div>
    </div>
  );
};
export default Sidebar;

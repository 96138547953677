import React from "react";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import CardCarousel from "../../components/CardCarousel";
import FeatureHeroSection from "../../components/FeatureHeroSection";
import FeatureHeader from "../../components/FeatureHeader";
import FeatureFooter from "../../components/FeatureFooter";
import FeatureUpload from "../../components/FeatureUpload";
import FeatureBenefitsType1 from "../../components/FeatureBenefitsType1";
import FAQSection from "../../components/FAQSection";

const topSectionData = {
  title: "Turn Complexity into Clarity",
  subtitle: "with AI Summarization",
  description:
    "Extract the most important insights from lengthy documents, articles, and reports in just seconds. Save time, improve understanding, and make smarter decisions.",
  ctaText: "Try Summarization Now",
  ctaText2: "Explore More Features",
  image: "/assets/images/aiSum.png",
  altText: "Summarization",
};

const heroSectionData = {
  title: "What is Summarization?",
  description:
    "AI Summarization condenses long-form content into shorter, meaningful versions, preserving the key ideas and context. Whether it's a research paper, a business report, or a novel, our AI helps you focus on what matters most.",
  radioOptions: [
    {
      id: "timesave",
      label: "Time-Saving: Get concise summaries instantly.",
    },
    {
      id: "accuracy",
      label: "Accuracy: Retain key points without losing context.",
    },
    {
      id: "versatile",
      label:
        "Versatile Applications: Works for articles, books, emails, and more.",
    },
    {
      id: "productivity",
      label:
        "Improved Productivity: Focus on decision-making, not reading through details.",
    },
  ],
  image: "/assets/images/textSum.png",
  altText: "AI Homework Helper",
};

const cardsData = [
  {
    image: "/assets/images/aiSum1.jpg",
    title: "Extractive Summarization",
    description:
      "What It Does: Identifies the most important sentences from the original text.",
    features: [
      "Applications:Quickly identify highlights in articles or reports.",
      "Simplify complex educational material.",
    ],
  },
  {
    image: "/assets/images/aiSum2.jpg",
    title: "Abstractive Summarization",
    description:
      "What It Does: Uses AI to generate a rewritten, concise version of the text.",
    features: [
      "Applications:Rewriting lengthy emails for clarity.",
      "Creating abstract summaries for research papers.",
    ],
  },
  {
    image: "/assets/images/aiSum3.png",
    title: "Bullet Point Summarization",
    description:
      "What It Does: Converts key insights into easy-to-read bullet points.",
    features: [
      "Applications:For note-taking during meetings.",
      "Quick overviews of books or lectures.",
    ],
  },
  {
    image: "/assets/images/aiSum4.png",
    title: "Topic-Based Summarization",
    description: "Gamifies language learning through interactive challenges.",
    features: [
      "Vocabulary, Grammar, And Pronunciation Exercises.",
      "Leaderboards For Progress Tracking.",
    ],
  },
];

const uploadData = {
  headerSubtitle: "How It Works",
  headerTitle: "How Does Summarization Work?",
  image: "/assets/images/segs.png",
  altText: "Fun Learning",
  steps: [
    {
      title: "Upload Your Document",
      description:
        "Upload text, PDFs, or other formats including text, audio, and video.",
    },
    {
      title: "Select Summarization Type",
      description: "Choose Extractive, Abstractive, Bullet Points, etc.",
    },
    {
      title: "Review Your Summary",
      description: "Get a clean and concise summary instantly.",
    },
    {
      title: "Export Or Share",
      description: "Download as text, PDF, or share directly.",
    },
  ],
};

const featureType1Data = {
  headerTitle: "Who Benefits from Summarization?",
  headerDescription:
    "Unlock a world of possibilities with Scoolish’s AI-powered tools, designed to make learning interactive, creative, and fun. From homework assistance to 3D modeling and virtual labs, every tool is crafted to inspire and empower young minds.",
  bannerImage: "/assets/images/sun.png",
  bannerAltText: "Focused Group",
  bannerTitle: "Benefit From Summarization",
  bannerCtaText: "Get in Touch",
  cardsData: [
    {
      title: "Students & Researchers",
      description:
        "Summarize textbooks, research papers, and notes for easier learning.",
      icon: "fa-solid fa-location-crosshairs text-3xl",
    },
    {
      title: "Educators",
      description:
        "Create teaching material or lesson summaries from multiple sources.",
      icon: "fa-solid fa-location-crosshairs text-3xl",
    },
    {
      title: "Business Professionals",
      description:
        "Extract insights from lengthy reports, emails, or meeting notes.",
      icon: "fa-solid fa-location-crosshairs text-3xl",
    },
    {
      title: "Content Creators",
      description: "Generate concise outlines for articles, videos, or blogs.",
      icon: "fa-solid fa-location-crosshairs text-3xl",
    },
    {
      title: "Legal Professionals",
      description: "Condense case files, contracts, and legal documents.",
      icon: "fa-solid fa-location-crosshairs text-3xl",
    },
  ],
};

const questions = [
  {
    title: "What Types Of Files Are Supported?",
    content: "We support text, Word documents, PDFs, and more.",
  },
  {
    title: "How Accurate Is The Summary?",
    content:
      "Our summaries aim for high accuracy, though results may vary depending on the content provided.",
  },
  {
    title: "Can I Customize The Length Of The Summary?",
    content: "Yes, you can customize the length to suit your needs.",
  },
  {
    title: "Collaborative Mind Mapping",
    content:
      "Work with others in real-time to create structured summaries and ideas.",
  },
];

const footerData = {
  title: "AI-Assisted, You-Driven: Unleash Your Creative Potential.",
  description:
    "At Scoolish, we believe in the synergy between human creativity and artificial intelligence. Our mission is to empower users to harness AI as a supportive tool that complements their unique skills and insights, fostering independence and originality in their projects.",
  ctaText1: "Get Started",
  ctaText2: "Sign Up for Scoolish",
  image: "/assets/images/future.png",
  altText: "Fun Learning",
  className: "pt-11",
};

function Summarization() {
  return (
    <>
      <Navbar />
      <FeatureHeader data={topSectionData} />
      <FeatureHeroSection data={heroSectionData} />
      <div className="bg-gray-100 h-[60%] text-left pt-0">
        <CardCarousel cardsData={cardsData} buttonText={null} />
      </div>
      <FeatureUpload data={uploadData} />
      <div
        className="bg-cover bg-center bg-no-repeat h-screen w-full"
        style={{ backgroundImage: "url('/assets/images/background.png')" }}
      >
        <FeatureBenefitsType1 data={featureType1Data} />
      </div>
      <FAQSection questions={questions} />
      <FeatureFooter data={footerData} />
      <div className=" bg-gray-100 pt-24 "></div>
      <Footer />
    </>
  );
}

export default Summarization;

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "./Button";

const Navbar = () => {
  const navigate = useNavigate();
  const [showMenu, setShowMenu] = useState(false);

  const navLinks = [
    { label: "Home", path: "/" },
    { label: "Tools & Features", path: "/tool-page" },
    // { label: "Features", path: "/feature-page" },
    { label: "Research", path: "/research" },
    { label: "Our Philosophy", path: "/philosophy" },
  ];

  return (
    <nav className="fixed top-0 left-0 w-full bg-white text-black z-50">
      <div className="container mx-auto px-4 py-2 flex justify-between items-center md:px-0 lg:px-4">
        {/* Logo and branding */}
        <div className="flex items-center lg:pl-24 md:pl-0">
          <img
            src={"/assets/images/owlLogo.png"}
            alt="Scoolish Logo"
            className="h-10 w-10"
            onClick={() => navigate("/")}
          />
          <span
            className="text-xl font-bold ml-2 lg:ml-2 md:ml-0"
            onClick={() => navigate("/")}
          >
            Scoolish
          </span>
        </div>

        {/* List icon for small screens */}
        <div className="md:hidden flex">
          <i
            className="fa fa-list-ul text-2xl list"
            onClick={() => setShowMenu(!showMenu)}
          />
        </div>

        {/* Navigation links for large screens */}
        <div className="hidden md:flex lg:space-x-4">
          {navLinks.map((link, index) => (
            <Button
              key={index}
              className="px-1 py-2 hover:text-blue-800 transition"
              onClick={() => navigate(link.path)}
            >
              {link.label}
            </Button>
          ))}
        </div>

        {/* Navigation links for small screens */}
        {showMenu && (
          <div className="md:hidden absolute top-14 left-0 w-full bg-white text-black shadow-lg">
            {navLinks.map((link, index) => (
              <Button
                key={index}
                className="block px-4 py-2 hover:text-blue-800 transition w-full"
                onClick={() => navigate(link.path)}
              >
                {link.label}
              </Button>
            ))}
          </div>
        )}

        {/* Call-to-action button */}
        <div className="flex justify-center lg:pr-24 md:pr-0">
          <Button
            onClick={() => navigate("/login")}
            className="px-1 py-2 pl-6 pr-6 bg-black text-white hover:bg-gray-500 transition"
          >
            Get Started
          </Button>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;

import React from "react";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import KeyFeatures from "../../components/KeyFeatures";
import BenefitCards from "../../components/BenefitCards";
import FeatureFooter from "../../components/FeatureFooter";

const featureData = {
  headerTitle: "Why Choose Music to Study By?",
  cardsData: [
    {
      icon: "fas fa-rocket text-3xl",
      title: "Curated Playlists",
      description:
        "Handpicked tracks optimized for focus and relaxation. Choose from genres like classical, lo-fi, or ambient music.",
    },
    {
      icon: "fas fa-cogs text-3xl",
      title: "Smart Recommendations",
      description:
        "Adaptive suggestions based on your preferences and study habits. Our AI learns your preferences and adapts over time.",
    },
    {
      icon: "fas fa-chart-line text-3xl",
      title: "Distraction-Free Interface",
      description:
        "A simple, clean design to keep you focused. No ads, no clutter, just pure focus-enhancing music.",
    },
  ],
};

const benefitsData = {
  title: "Who Benefits from Music to Study By?",
  benefits: [
    {
      image: "/assets/images/benefits1.avif",
      title: "Enhance Memory Retention",
      description:
        "Music stimulates the brain, helping you retain information better.",
    },
    {
      image: "/assets/images/benefits1.avif",
      title: "Improve Focus",
      description:
        "Background music reduces ambient distractions, keeping you in the zone.",
    },
    {
      image: "/assets/images/benefits1.avif",
      title: "Reduce Stress",
      description:
        "Calm and soothing tracks help create a stress-free study environment.",
    },
  ],
};

const footerData = {
  title: "Be Focus on your study with Music to Study By",
  description:
    '"Music to Study By" is a personalized tool that uses scientifically curated playlists to help you stay focused and motivated during study sessions',
  ctaText1: "Try Music To Study by",
  image: "/assets/images/ms6.png",
  altText: "Try Music To Study by",
  className: "pt-15",
};

function MusicToStudy() {
  return (
    <>
      <Navbar />
      <div className="bg-black w-full h-screen p-6">
        <div className="flex flex-col md:flex-row items-center bg-black mt-24 pt-14 pr-20 pl-20 pb-10 justify-between">
          <div className="w-full md:w-1/2 mb-6 md:mb-0 px-2">
            <h3 className="text-5xl text-white font-bold mb-2">
              Focus Better, Learn Faster, Achieve More
            </h3>
            <p className="text-lg mb-4 text-white pr-10">
              Discover curated music scientifically designed to enhance
              concentration and productivity while you study.
            </p>
            <button className="bg-white hover:bg-gray-300 font-bold text-lg py-3 px-6 rounded-lg mt-6 mr-4">
              Start Listening Now
            </button>
            <button className="bg-black text-white border-2 border-white hover:bg-gray-300 font-bold text-lg py-2.5 px-16 rounded-lg mt-6 mr-4">
              Learn More
            </button>
          </div>
          <div className="w-full md:w-1/2 pl-6">
            <img
              src="/assets/images/ms1.png"
              alt="AI Homework Helper"
              className="w-full h-auto rounded-xl"
            />
          </div>
        </div>
      </div>
      <div className="bg-gray-100 w-full h-[90vh] p-24 pb-0">
        <div className="flex flex-col md:flex-row items-center  p-6 justify-between">
          <div className="w-full md:w-1/2 mb-6 md:mb-0 px-2">
            <h3 className="text-3xl font-bold mb-2">
              What is "Music to Study By"?
            </h3>
            <p className="text-lg text-bold mb-8 pr-10 text-justify">
              "Music to Study By" is a personalized tool that uses
              scientifically curated playlists to help you stay focused and
              motivated during study sessions. From classical symphonies to
              ambient beats, our music is tailored to minimize distractions and
              maximize productivity
            </p>
            <div className="flex items-center mb-2">
              <input
                type="radio"
                name="solution-type"
                id="skills"
                className="mr-2"
              />
              <label htmlFor="skills" className="text-lg font-inter">
                Encourages creativity and storytelling skills.
              </label>
            </div>
            <div className="flex items-center mb-2">
              <input
                type="radio"
                name="solution-type"
                id="engage"
                className="mr-2"
              />
              <label htmlFor="engage" className="text-lg font-inter pr-12">
                Provides a fun and interactive way to engage with literature.
              </label>
            </div>
            <div className="flex items-center mb-2">
              <input
                type="radio"
                name="solution-type"
                id="visual"
                className="mr-2"
              />
              <label htmlFor="visual" className="text-lg font-inter pr-12">
                Helps develop visual literacy and artistic expression.
              </label>
            </div>
            <button className="bg-gray-100 border border-black hover:bg-gray-500 font-bold py-3 text-lg  px-8 rounded-xl mt-6">
              Try Now
            </button>
          </div>
          <div className="w-full md:w-1/2">
            <img
              src="/assets/images/ms2.png"
              alt="AI Homework Helper"
              className="w-full h-[60vh] rounded-xl"
            />
          </div>
        </div>
      </div>
      <KeyFeatures data={featureData} />
      <div className="bg-black h-screen p-24">
        {/* Header Section */}
        <div className="flex justify-between items-center mb-10">
          <h1 className="text-5xl font-semibold text-white text-left mb-0">
            How It Works
          </h1>
          <div className="flex">
            <button className="bg-black text-white border-2 border-white hover:bg-gray-300 font-bold text-lg py-2.5 px-10 rounded-lg mr-24">
              Create Your Playlist
            </button>
          </div>
        </div>

        {/* Scrollable Cards Section */}
        <div className="flex space-x-6 overflow-x-scroll scroll-smooth no-scrollbar">
          <div className="flex-shrink-0 w-[90%] md:w-[30%] bg-[#323232] rounded-xl p-6">
            <h3 className="text-2xl text-white font-bold mb-4">1. Sign Up</h3>
            <div className="w-full h-[35vh] bg-[#D9D9D9] rounded-xl mb-2">
              <img
                src="/assets/images/ms3.png"
                className="w-full h-full rounded-xl object-cover"
              />
            </div>
            <p className="text-md text-white">
              Create your account to get started.
            </p>
          </div>
          <div className="flex-shrink-0 w-[90%] md:w-[30%] bg-[#323232] rounded-xl p-6">
            <h3 className="text-2xl text-white font-bold mb-4">
              2. Choose Your Mood
            </h3>
            <div className="w-full h-[35vh] bg-[#D9D9D9] rounded-xl mb-2">
              <img
                src="/assets/images/ms4.png"
                className="w-full h-full rounded-xl object-cover"
              />
            </div>
            <p className="text-md text-white">
              Select the type of music that fits your study style.
            </p>
          </div>
          <div className="flex-shrink-0 w-[90%] md:w-[30%] bg-[#323232] rounded-xl p-6">
            <h3 className="text-2xl text-white font-bold mb-4">
              3. Start Listening
            </h3>
            <div className="w-full h-[35vh] bg-[#D9D9D9] rounded-xl mb-2">
              <img
                src="/assets/images/ms5.png"
                className="w-full h-full rounded-xl object-cover"
              />
            </div>
            <p className="text-md text-white">
              Enjoy distraction-free, focus-enhancing music.
            </p>
          </div>
        </div>
      </div>
      <BenefitCards data={benefitsData} />
      <FeatureFooter data={footerData} />
      <div className=" bg-gray-100 pt-24 "></div>
      <Footer />
    </>
  );
}

export default MusicToStudy;

import React from "react";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import CardCarousel from "../../components/CardCarousel";
import FeatureHeader from "../../components/FeatureHeader";
import FeatureHeroSection from "../../components/FeatureHeroSection";
import SubFeatures from "../../components/SubFeatures";
import FeatureFooter from "../../components/FeatureFooter";
import FeatureUpload from "../../components/FeatureUpload";
import FeatureBenefitsType2 from "../../components/FeatureBenefitsType2";
import FAQSection from "../../components/FAQSection";

const topSectionData = {
  title: "Unify Data, Enhance Clarity with Entity Resolution",
  description:
    "Effortlessly identify and merge duplicate entities in your datasets with Scoolish’s Entity Resolution feature. Perfect for data analysts, researchers, and businesses seeking accurate and reliable data.",
  ctaText: "Try Entity Resolution Now",
  ctaText2: "Learn More",
  image: "/assets/images/entity1.png",
  altText: "AI Homework Helper",
};

const heroSectionData = {
  title: "What is Entity Resolution?",
  description:
    "Entity Resolution is the process of identifying and merging duplicate records in datasets to create a single, accurate representation of each entity. With Scoolish, you can clean and enrich your data, ensuring that your analyses and insights are based on reliable information.",
  radioOptions: [
    {
      id: "duplicate",
      label: "Eliminate duplicate records for cleaner datasets.",
    },
    {
      id: "quality",
      label: "Improve data quality and accuracy for better decision-making.",
    },
    {
      id: "data management",
      label:
        "Streamline data management processes and enhance operational efficiency.",
    },
  ],
  image: "/assets/images/entity2.png",
  altText: "Topic Modeling",
};

const cardsData = [
  {
    image: "/assets/images/entity2.png",
    title: "Automated Duplicate Detection",
    description:
      "Automatically identify duplicate entities using advanced algorithms to save time and reduce manual effort.",
    features: null,
  },
  {
    image: "/assets/images/similarity6.png",
    title: "Customizable Matching Criteria",
    description:
      "Tailor the criteria used for matching entities to fit your specific data needs and contexts.",
    features: null,
  },
  {
    image: "/assets/images/topicModeling5.png",
    title: "Data Enrichment",
    description:
      "Enhance your unified records with additional information from various sources for a comprehensive view.",
    features: null,
  },
  {
    image: "/assets/images/topicModeling6.png",
    title: "Real-Time Processing:",
    description:
      "Process and resolve duplicates in real-time, ensuring your data is always up to date.",
    features: null,
  },
];
const subFeaturesData = [
  {
    title: "Fuzzy Matching",
    description:
      "Utilize fuzzy matching techniques to identify similar entities that may have slight variations in spelling or formatting.",
    icon: "fa fa-language",
  },
  {
    title: "Linkage Analysis",
    description:
      "Analyze relationships between entities to understand connections and dependencies.",
    icon: "fa fa-keyboard",
  },
  {
    title: "Batch Processing",
    description:
      "Resolve duplicates in large datasets efficiently with batch processing capabilities.",
    icon: "fa fa-rainbow ",
  },
  {
    title: "User-Defined Rules",
    description:
      "Create custom rules for entity resolution based on your specific business logic.",
    icon: "fa-regular fa-clipboard",
  },
  {
    title: "Export and Share",
    description:
      "Export resolved datasets in multiple formats (CSV, JSON, or interactive web embeds) for easy sharing and integration.",
    icon: "fa fa-clock-rotate-left",
  },
];

const uploadData = {
  headerSubtitle: "How It Works",
  headerTitle: "How Does Entity Resolution Feature Work?",
  image: "/assets/images/segs.png",
  altText: "Fun Learning",
  steps: [
    {
      title: "Upload Dataset",
      description: "Upload your dataset containing potential duplicates.",
    },
    {
      title: "Define Matching",
      description: "Define your matching criteria and resolution rules.",
    },
    {
      title: "Review and confirm",
      description: "Review and confirm the identified duplicates for merging.",
    },
    {
      title: "Analyze Customize",
      description: "Analyze, customize, and export your unified dataset.",
    },
  ],
};

const featureType2Data = {
  headerTitle: "Who Benefits from Entity Resolution Feature?",
  headerDescription: null,
  cardsData: [
    {
      title: "For Data Analysts",
      description:
        "Ensure data integrity by resolving duplicates before analysis, leading to more accurate insights.",
      icon: "fa-solid fa-location-crosshairs text-3xl",
    },
    {
      title: "For Businesses",
      description:
        "Maintain a single customer view by merging duplicate records, enhancing customer relationship management.",
      icon: "fa-solid fa-location-crosshairs text-3xl",
    },
    {
      title: "For Researchers",
      description:
        "Clean and unify datasets for reliable research outcomes and publications.",
      icon: "fa-solid fa-location-crosshairs text-3xl",
    },
    {
      title: "For Data Engineers",
      description:
        "Streamline data pipelines by ensuring that only unique records are processed and stored.",
      icon: "fa-solid fa-location-crosshairs text-3xl",
    },
  ],
};

const questions = [
  {
    title: "What Types Of Files Are Supported?",
    content: "We support text, Word documents, PDFs, and more.",
  },
  {
    title: "How Accurate Is The Summary?",
    content:
      "Our summaries aim for high accuracy, though results may vary depending on the content provided.",
  },
  {
    title: "Can I Customize The Length Of The Summary?",
    content: "Yes, you can customize the length to suit your needs.",
  },
  {
    title: "Collaborative Mind Mapping",
    content:
      "Work with others in real-time to create structured summaries and ideas.",
  },
];

const footerData = {
  title: "Achieve Data Clarity with Scoolish",
  description:
    "Empower your decisions with data-driven insights, simplifying the complexities of education management with Scoolish.",
  ctaText1: "Try Entity Resolution",
  ctaText2: "Sign Up for Scoolish",
  image: "/assets/images/er.png",
  altText: "Future Learning",
  className: "pt-18",
};

function EntityResolution() {
  return (
    <>
      <Navbar />
      <FeatureHeader data={topSectionData} />
      <FeatureHeroSection data={heroSectionData} />
      <div className="bg-gray-100 h-[60%] text-left pt-0 pb-16">
        <CardCarousel cardsData={cardsData} buttonText={null} />
      </div>
      <SubFeatures features={subFeaturesData} heading={"Entity Resolution"} />
      <FeatureUpload data={uploadData} />
      <div
        className="bg-cover bg-center bg-no-repeat h-[65vh] w-full"
        style={{ backgroundImage: "url('/assets/images/background.png')" }}
      >
        <FeatureBenefitsType2 data={featureType2Data} />
      </div>
      <FAQSection questions={questions} />
      <FeatureFooter data={footerData} />
      <div className=" bg-gray-100 pt-24 "></div>
      <Footer />
    </>
  );
}

export default EntityResolution;

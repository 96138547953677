import React, { useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";

const featuresData = [
  {
    title: "AI-Powered Homework Helper",
    description:
      "Get instant assistance with homework questions and enhance your understanding with personalized AI support.",
    image: "/assets/images/tools/ai-powered-homework-helper.png",
  },
  {
    title: "Visual Study Guide Maker",
    description:
      "Transform your notes into visually engaging study guides that simplify complex concepts and enhance retention.",
    image: "/assets/images/tools/visual-study-guide-maker.png",
  },
  {
    title: "Math Problem Visualizer",
    description:
      "Turn complex math problems into clear visual representations to grasp concepts more effectively.",
    image: "/assets/images/tools/math-problem-visualizer.png",
  },
  {
    title: "Language Learning Games",
    description:
      "Make language acquisition fun and interactive with engaging games that enhance vocabulary and grammar skills.",
    image: "/assets/images/tools/language-learning-games.png",
  },
  {
    title: "Creative Writing Prompts",
    description:
      "Spark your imagination with unique writing prompts that inspire creativity and storytelling.",
    image: "/assets/images/tools/creative-writing-prompts.png",
  },
  {
    title: "Interactive Quiz Creator",
    description:
      "Design engaging quizzes that test knowledge and promote learning in a fun, interactive way.",
    image: "/assets/images/tools/interactive-quiz-creator.png",
  },
  {
    title: "Story To Comics Converter",
    description:
      "Transform your narratives into vibrant comic strips, bringing your stories to life visually.",
    image: "/assets/images/tools/story-to-comics-converter.png",
  },
  {
    title: "AI Summarizer",
    description:
      "Quickly distill lengthy texts into concise summaries with our AI Summarizer, making information easier to digest and retain.",
    image: "/assets/images/tools/ai-summarizer.png",
  },
  {
    title: "Digital Debate Platform",
    description:
      "Engage in thoughtful discussions and sharpen your argumentation skills in a dynamic online environment.",
    image: "/assets/images/tools/digital-debate-platform.png",
  },
  {
    title: "3D Model Builder",
    description:
      "Bring your ideas to life by creating detailed 3D models with an intuitive design platform.",
    image: "/assets/images/tools/3d-model-builder.png",
  },
  {
    title: "Virtual Science Lab",
    description:
      "Conduct experiments in a safe, virtual environment that enhances hands-on learning and exploration.",
    image: "/assets/images/tools/virtual-science-lab.png",
  },
  {
    title: "Learn By Drawing",
    description:
      "Enhance understanding and retention by learning concepts through creative drawing activities.",
    image: "/assets/images/tools/learn-by-drawing.png",
  },
  {
    title: "Historical Timeline Builder",
    description:
      "Create visual timelines that chronicle significant events, making history more relatable and engaging.",
    image: "/assets/images/tools/historical-timeline-builder.png",
  },
  {
    title: "Collaborative Mind Mapping",
    description:
      "Organize thoughts and ideas collaboratively with interactive mind maps that boost creativity and productivity.",
    image: "/assets/images/tools/collaborative-mind-mapping.png",
  },
  {
    title: "Music To Study By",
    description:
      "Enhance focus and retention with curated playlists designed to create the perfect study ambiance.",
    image: "/assets/images/tools/music-to-study-by.png",
  },
  {
    title: "Code Playground For Kids",
    description:
      "Introduce kids to programming through a fun, interactive platform that encourages creativity and problem-solving.",
    image: "/assets/images/tools/code-playground-for-kids.png",
  },
  {
    title: "Interactive Comic Strip Builder",
    description:
      "Create visual timelines that chronicle significant events, making history more relatable and engaging.",
    image: "/assets/images/tools/interactive-comic-strip-builde.png",
  },
  {
    title: "AI Presentation Builder",
    description:
      "Create stunning presentations effortlessly with our AI Presentation Builder that generates tailored slides based on your content and style preferences.",
    image: "/assets/images/tools/ai-presentation-builder.png",
  },
  {
    title: "Mindful Study Planner",
    description:
      "Stay organized and focused with our Mindful Study Planner, designed to optimize your study time and enhance productivity.",
    image: "/assets/images/tools/mindful-study-planner.png",
  },
  {
    title: "Ethical AI Tutor",
    description:
      "Experience personalized learning with our Ethical AI Tutor, providing tailored guidance while upholding ethical standards in education.",
    image: "/assets/images/tools/ethical-ai-tutor.png",
  },
  {
    title: "Sentence Simplifier",
    description:
      "Transform complex sentences into clear, concise language with our Sentence Simplifier, making reading and writing easier.",
    image: "/assets/images/tools/sentence-simplifier.png",
  },
  {
    title: "Virtual Language Coach",
    description:
      "Boost your language skills with our Virtual Language Coach, offering interactive practice and personalized feedback in real-time.",
    image: "/assets/images/tools/virtual-language-coach.png",
  },
  {
    title: "AI Art Creator For Kids",
    description:
      "Unleash creativity with our AI Art Creator for Kids, enabling young artists to generate imaginative artwork with just a few clicks.",
    image: "/assets/images/tools/ai-art-creator-for-kids.png",
  },
  {
    title: "Interactive Timeline Explorer",
    description:
      "Explore history dynamically with our Interactive Timeline Explorer, allowing users to visualize events and their connections effortlessly.",
    image: "/assets/images/tools/interactive-timeline-explorer.png",
  },
  {
    title: "Customizable Flashcard Creator",
    description:
      "Enhance your learning experience with our Customizable Flashcard Creator, enabling tailored study aids for any subject.",
    image: "/assets/images/tools/customizable-flashcard-creator.png",
  },
  {
    title: "STEM Challenge Generator",
    description:
      "Ignite curiosity with our STEM Challenge Generator, providing engaging and innovative activities for young scientists and engineers.",
    image: "/assets/images/tools/stem-challenge-generator.png",
  },
  {
    title: "Data Story Builder",
    description:
      "Transform raw data into compelling narratives with our Data Story Builder, making insights accessible and engaging.",
    image: "/assets/images/tools/data-story-builder.png",
  },
  {
    title: "AI Lesson Plan Designer",
    description:
      "Simplify lesson planning with our AI Lesson Plan Designer, generating tailored educational plans to meet your teaching goals.",
    image: "/assets/images/tools/ai-lesson-plan-designer.png",
  },
  {
    title: "Mood-Based Study Music Generator",
    description:
      "Enhance your study sessions with our Mood-Based Study Music Generator, curating playlists that match your focus and energy levels.",
    image: "/assets/images/tools/mood-based-study-music-generator.png",
  },
  {
    title: "AI Segmenter",
    description:
      "Organize and break down content into manageable sections with our Segmenter, enhancing clarity and improving comprehension.",
    image: "/assets/images/tools/ai-segmenter.png",
  },
];

const headerFilter = [
  "Students",
  "Professionals",
  "Educators",
  "Lifelong Learners",
];

const subCategories = [
  "All",
  "Knowledge Management",
  "AI Insights",
  "Interactive Tools",
  "Collaboration",
];

const filterData = {
  Students: {
    All: [
      "AI-Powered Homework Helper",
      "Interactive Quiz Creator",
      "Historical Timeline Builder",
      "Story To Comics Converter",
      "Learn By Drawing",
      "Virtual Science Lab",
      "Creative Writing Prompts",
      "Math Problem Visualizer",
      "Visual Study Guide Maker",
      "Language Learning Games",
      "Music To Study By",
      "Code Playground For Kids",
      "Interactive Comic Strip Builder",
      "Sentence Simplifier",
      "Virtual Language Coach",
      "AI Art Creator For Kids",
      "Customizable Flashcard Creator",
      "STEM Challenge Generator",
      "Mood-Based Study Music Generator",
    ],
    "Knowledge Management": [
      "AI-Powered Homework Helper",
      "Historical Timeline Builder",
      "Visual Study Guide Maker",
      "Collaborative Mind Mapping",
    ],
    "AI Insights": [
      "AI-Powered Homework Helper",
      "Math Problem Visualizer",
      "Visual Study Guide Maker",
      "Sentence Simplifier",
      "Virtual Language Coach",
    ],
    "Interactive Tools": [
      "Interactive Quiz Creator",
      "Story To Comics Converter",
      "Learn By Drawing",
      "Virtual Science Lab",
      "Music To Study By",
      "Code Playground For Kids",
      "Interactive Comic Strip Builder",
      "Customizable Flashcard Creator",
      "STEM Challenge Generator",
      "Mood-Based Study Music Generator",
    ],
    Collaboration: ["Digital Debate Platform"],
  },
  Professionals: {
    All: [
      "Digital Debate Platform",
      "3D Model Builder",
      "Data Story Builder",
      "AI Presentation Builder",
    ],
    "Knowledge Management": ["Data Story Builder", "AI Presentation Builder"],
    "AI Insights": [],
    "Interactive Tools": [],
    Collaboration: ["Digital Debate Platform"],
  },
  Educators: {
    All: [
      "AI Summarizer",
      "AI Segmenter",
      "AI-Powered Homework Helper",
      "Interactive Quiz Creator",
      "Historical Timeline Builder",
      "Digital Debate Platform",
      "Collaborative Mind Mapping",
      "AI Lesson Plan Designer",
    ],
    "Knowledge Management": [
      "AI Summarizer",
      "AI Segmenter",
      "Historical Timeline Builder",
      "Collaborative Mind Mapping",
      "AI Lesson Plan Designer",
    ],
    "AI Insights": [
      "AI-Powered Homework Helper",
      "Math Problem Visualizer",
      "Visual Study Guide Maker",
    ],
    "Interactive Tools": [
      "Interactive Quiz Creator",
      "Digital Debate Platform",
    ],
    Collaboration: ["Digital Debate Platform", "Collaborative Mind Mapping"],
  },
  "Lifelong Learners": {
    All: [
      "Language Learning Games",
      "Creative Writing Prompts",
      "Music To Study By",
      "Ethical AI Tutor",
      "Mindful Study Planner",
      "AI Art Creator For Kids",
    ],
    "Knowledge Management": [],
    "AI Insights": ["Ethical AI Tutor", "Virtual Language Coach"],
    "Interactive Tools": [
      "Music To Study By",
      "AI Art Creator For Kids",
      "Language Learning Games",
      "Creative Writing Prompts",
    ],
    Collaboration: [],
  },
};

const imageData = [
  {
    title: "For Students: Study smarter with AI",
    description:
      "Scoolish transforms learning with AI-powered tools that simplify topics and create engaging study aids. Tailored to each student, it makes studying smarter and more efficient, unlocking academic success.",
    image: "/assets/images/common/students.png",
    buttonText: "Discover Tools",
  },
  {
    title: "For Professionals: Master new skills",
    description:
      "Scoolish helps professionals master new skills with AI-driven tools, tailored for upskilling, reskilling, and career growth. Unlock your potential and innovate with resources designed for your success.",
    image: "/assets/images/common/professionals.png",
    buttonText: "Explore Career Growth",
  },
  {
    title: "For Educators: Engage your students",
    description:
      "Scoolish empowers educators with AI tools to engage students and enhance learning. From creating interactive content to personalized teaching aids, we simplify and elevate your classroom experience.",
    image: "/assets/images/common/educators.png",
    buttonText: "Learn More",
  },
  {
    title: "For Lifelong Learners: Explore passions",
    description:
      "Scoolish empowers lifelong learners with AI-driven tools to explore passions and fuel growth. From new hobbies to expanding knowledge, we make learning engaging and meaningful at any stage of life.",
    image: "/assets/images/common/learners.png",
    buttonText: "Get Inspired",
  },
];

function Tools() {
  const sectionRefs = useRef([]);
  const navigate = useNavigate();
  const [activeIndex, setActiveIndex] = useState(0);
  const [activeSection, setActiveSection] = useState(null);
  const features = useMemo(
    () => [
      {
        title: "Smart Content Breakdown",
        name: "Breaking Down Complexity Into Clarity",
        description:
          "Divide large text into meaningful sections or segments. This helps you focus on the most relevant parts without getting lost in the details. Perfect for structured analysis of research papers, contracts, or long-form content.",
        tools: ["AI Summarizer", "AI Segmenter"],
        image: "/assets/images/segmentation.png",
        buttonClickHandler: () => {
          navigate("/segmentation");
          window.scrollTo(0, 10);
        },
      },
      {
        title: "Insight Discovery and Knowledge Mapping",
        name: "Turn Pages Into Paragraphs",
        description:
          "Transform complex topics into structured insights with our advanced topic modeling and knowledge extraction tools, making information more accessible and actionable.",
        tools: ["Data Story Builder", "Historical Timeline Builder"],
        image: "/assets/images/aiSummary.png",
        buttonClickHandler: () => {
          navigate("/summarization");
          window.scrollTo(0, 10);
        },
      },
      {
        title: "Entity Identification and Timeline Structuring",
        name: "Discover the Themes Behind the Text",
        description:
          "Effortlessly identify key entities and create chronological timelines with our entity and chronology resolution feature, ensuring clarity and context in your data.",
        tools: ["Interactive Timeline Explorer", "Historical Timeline Builder"],
        image: "/assets/images/topicModeling.png",
        buttonClickHandler: () => {
          navigate("/topic-modeling");
          window.scrollTo(0, 10);
        },
      },
      {
        title: "Contextual Matching and Language Comprehension",
        name: "Gauge the Emotional Pulse of Your Content",
        description:
          "Enhance your understanding with our similarity detection and language comprehension capabilities, designed to identify relationships and improve language skills.",
        tools: ["Sentence Simplifier", "Virtual Language Coach"],
        image: "/assets/images/sentiment.png",
        buttonClickHandler: () => {
          navigate("/sentiment-analysis");
          window.scrollTo(0, 10);
        },
      },
      {
        title: "Creative & Interactive Content Generation",
        name: "Unravel the Timeline of Events",
        description:
          "Unleash your creativity with our creative and interactive content generation tools, allowing you to produce engaging material that captivates your audience.",
        tools: [
          "AI Art Creator for Kids",
          "AI Presentation Builder",
          "Story to Comics Converter",
        ],
        image: "/assets/images/chronology.png",
        buttonClickHandler: () => {
          navigate("/chronology");
          window.scrollTo(0, 10);
        },
      },
      {
        title: "Mathematical & Logical Problem Solving",
        name: "Find the Closest Match",
        description:
          "Tackle complex challenges with our mathematical and logical problem-solving features, empowering you to find efficient solutions with ease.",
        tools: [
          "Math Problem Visualizer",
          "STEM Challenge Generator",
          "Code Playground for Kids",
        ],
        image: "/assets/images/similarity.png",
        buttonClickHandler: () => {
          navigate("/similarity");
          window.scrollTo(0, 10);
        },
      },
      {
        title: "Multimedia Data Analysis",
        name: "Connect the Dots Across Data",
        description:
          "Process and analyze both audio and visual data seamlessly with our audio and visual data processing tools, enhancing your understanding of multimedia content.",
        tools: [
          "Audio-to-Text Conversion",
          "3D Model Builder",
          "Music to Study By",
        ],
        image: "/assets/images/entity.png",
        buttonClickHandler: () => {
          navigate("/entity-resolution");
          window.scrollTo(0, 10);
        },
      },
    ],
    [navigate]
  );
  const handleScrollToSection = (index) => {
    const audienceFilters = filterData[headerFilter[activeHeaderIndex]] || {};
    const subCategoryFilters = audienceFilters[activeSubCategory] || [];
    const newFilteredFeatures = featuresData.filter((feature) =>
      subCategoryFilters.includes(feature.title)
    );
    setFilteredFeatures(newFilteredFeatures);
    setActiveIndex(index);
    setActiveSection(features[index].title);
  };

  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [hasStarted, setHasStarted] = useState(false); // Ensure autoplay happens only once

  const handlePlayPause = () => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.pause();
      } else {
        videoRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  const [activeHeaderIndex, setActiveHeaderIndex] = useState(0);
  const [activeSubCategory, setActiveSubCategory] = useState("All");
  const [filteredFeatures, setFilteredFeatures] = useState([]);
  const [isPaused, setIsPaused] = useState(false);
  const [isPaused2, setIsPaused2] = useState(false);

  const handleScrollForHeader = (index) => {
    setActiveHeaderIndex(index);
    setActiveSubCategory("All");
  };

  useEffect(() => {
    if (!hasStarted) {
      const timer = setTimeout(() => {
        if (videoRef.current) {
          videoRef.current.muted = true; // Ensure muted autoplay
          videoRef.current
            .play()
            .then(() => {
              setIsPlaying(true);
              setHasStarted(true); // Prevent subsequent autoplay attempts
            })
            .catch((err) => {
              console.error("Autoplay failed:", err);
            });
        }
      }, 2000); // 2 seconds delay

      return () => clearTimeout(timer); // Cleanup timeout
    }
    const audienceFilters = filterData[headerFilter[activeHeaderIndex]] || {};
    const subCategoryFilters = audienceFilters[activeSubCategory] || [];
    const newFilteredFeatures = featuresData.filter((feature) =>
      subCategoryFilters.includes(feature.title)
    );
    setFilteredFeatures(newFilteredFeatures);
    setActiveSection(features[0].title);
  }, [features, hasStarted, activeHeaderIndex, activeSubCategory]);

  return (
    <>
      <Navbar />
      {/* Hero Section */}
      <div className="bg-black h-auto p-6 lg:pb-24">
        <div className="flex flex-col sm:flex-row items-center bg-black mt-24 sm:pt-2 md:pt-14 lg:pt-20 sm:px-6 lg:px-20 pb-10 justify-between">
          {/* Left Section */}
          <div className="w-full sm:w-1/2 mb-6 sm:mb-0 px-2 sm:px-4 sm:pr-6 lg:pr-20">
            <h3 className="lg:text-6xl text-4xl sm:text-3xl text-white font-bold mb-2">
              Powerful Tools, Tailored for You
            </h3>
            <p className="lg:text-xl text-lg sm:text-base mb-4 text-white">
              Discover AI-powered, interactive, and collaborative tools tailored
              for learning, creating, and growth.
            </p>

            {/* Button Section */}
            <div className="flex flex-col sm:flex-row sm:space-x-4">
              <button className="bg-white text-black px-8 py-3 mr-2 rounded-md font-bold hover:bg-gray-200 mb-4 sm:mb-0">
                Try Tools Now
              </button>

              <button className="border border-white text-white px-10 py-3 rounded-md font-bold hover:bg-gray-200">
                Sign Up Free
              </button>
            </div>
          </div>

          {/* Right Section (Image) */}
          <div className="w-full sm:w-1/2 lg:pl-6 relative">
            <video
              className="w-full rounded-xl shadow-lg"
              loop={true}
              autoPlay={true}
              ref={videoRef}
              onClick={handlePlayPause}
            >
              <source
                src="https://scoolishopenmedia.blob.core.windows.net/scoolish-open-media/Feature_Tools_Video.mp4"
                type="video/mp4"
              />
              Your browser does not support the video tag.
            </video>

            {/* Play/Pause Button */}
            <div
              className="absolute inset-0 flex items-center justify-center cursor-pointer"
              onClick={handlePlayPause}
            >
              {!isPlaying && (
                <div className="bg-white p-4 rounded-full">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="black"
                    viewBox="0 0 24 24"
                    strokeWidth={2}
                    stroke="black"
                    className="w-8 h-8"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M5 3l14 9-14 9V3z"
                    />
                  </svg>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {/* Scoolish Tools */}
      <div className="bg-gray-50 text-black p-8 pb-0 sm:p-12 lg:p-32 pt-8 sm:pt-12 sm:pb-4 lg:pt-16 lg:pb-0">
        <p className="text-center text-base sm:text-lg font-semibold pb-2">
          EXPLORE SCOOLISH FEATURES
        </p>
        <h3 className="text-3xl sm:text-4xl lg:text-5xl text-center font-semibold pb-4 sm:pb-6">
          Unlock the Power of Text, Scoollish Tools
        </h3>

        {/* Navigation */}
        <div className="rounded-2xl flex justify-center gap-1">
          {headerFilter.map((feature, index) => (
            <button
              key={index}
              onClick={() => handleScrollForHeader(index)}
              className={`flex-1 md:flex-none lg:w-1/3 xl:w-1/4 bg-white py-2 sm:py-3 md:py-4 px-3 sm:px-4 md:px-6 text-center text-sm sm:text-base md:text-lg xl:text-xl shadow-lg ${
                index === activeHeaderIndex
                  ? "border-b-2 border-black"
                  : "hover:bg-gray-500"
              } transition-all`}
            >
              {feature}
            </button>
          ))}
        </div>

        {/* Sub Navigation */}
        <nav className="flex flex-wrap items-center justify-center gap-4 sm:gap-6 lg:gap-8 pt-4 sm:pt-6 lg:pt-8">
          {subCategories.map((category, index) => (
            <button
              key={index}
              onClick={() => setActiveSubCategory(category)}
              className={`px-3 sm:px-4 md:px-6 py-1 sm:py-2 text-sm sm:text-md lg:text-lg font-medium rounded-full transition-colors ${
                activeSubCategory === category
                  ? "bg-black text-white"
                  : "text-gray-700 hover:text-gray-900"
              }`}
            >
              {category}
            </button>
          ))}
        </nav>
      </div>
      {/* Features */}
      <div className="bg-gray-50 p-4 sm:p-8 lg:p-16 pt-4 sm:pt-6 lg:pt-8">
        {/** For data that is 5 or less */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-5 gap-4 sm:gap-6 lg:gap-8">
          {filteredFeatures.length > 0 &&
            filteredFeatures.length <= 5 &&
            filteredFeatures.map((feature, index) => (
              <div
                key={index}
                className="bg-white rounded-lg shadow-lg p-4 flex flex-col"
              >
                <div className="rounded-xl overflow-hidden w-full h-32 h-auto mb-4">
                  <img
                    src={feature.image}
                    alt={feature.title}
                    className="w-full h-full object-cover"
                  />
                </div>
                <div className="flex-1 flex flex-col items-start">
                  <h4 className="text-sm sm:text-md font-bold">
                    {feature.title}
                  </h4>
                  <p className="text-xs sm:text-[12px] mt-2 mb-4">
                    {feature.description}
                  </p>
                  <button className="border border-black px-2 sm:px-3 py-1 font-semibold rounded-md self-start hover:bg-black hover:text-white transition-colors mt-auto">
                    Try Now
                  </button>
                </div>
              </div>
            ))}
        </div>

        {/** For data that is greater than 5 and less than 12 */}
        <div className="relative  overflow-hidden">
          <div
            className="flex"
            style={{
              animation: "scroll 15s linear infinite",
              animationPlayState: isPaused ? "paused" : "running",
              width: "fit-content",
            }}
            onMouseEnter={() => setIsPaused(true)}
            onMouseLeave={() => setIsPaused(false)}
          >
            {filteredFeatures.length > 5 &&
              filteredFeatures.length < 12 &&
              [
                ...filteredFeatures,
                ...filteredFeatures,
                ...filteredFeatures,
              ].map((feature, index) => (
                <div
                  key={index}
                  className="min-w-[40vw] sm:min-w-[25vw] lg:min-w-[19vw] px-2 sm:px-3"
                >
                  <div className="bg-white rounded-lg shadow-lg p-4 flex flex-col h-full">
                    <div className="rounded-xl overflow-hidden w-full h-32 sm:h-36 lg:h-40 mb-4">
                      <img
                        src={feature.image}
                        alt={feature.title}
                        className="w-full h-full object-cover"
                      />
                    </div>
                    <div className="flex-1 flex flex-col items-start">
                      <h4 className="text-sm sm:text-md font-bold">
                        {feature.title}
                      </h4>
                      <p className="text-xs sm:text-[12px] mt-2 mb-4">
                        {feature.description}
                      </p>
                      <button className="border border-black px-2 sm:px-3 py-1 font-semibold rounded-md self-start hover:bg-black hover:text-white transition-colors mt-auto">
                        Try Now
                      </button>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>

        {/** For data that is greater than 12 */}
        <div className="relative overflow-hidden">
          <div
            className="flex"
            style={{
              animation: "scroll 30s linear infinite",
              animationPlayState: isPaused ? "paused" : "running",
              width: "fit-content",
            }}
            onMouseEnter={() => setIsPaused(true)}
            onMouseLeave={() => setIsPaused(false)}
          >
            {filteredFeatures.length > 12 &&
              [
                ...filteredFeatures.slice(0, filteredFeatures.length / 2),
                ...filteredFeatures.slice(0, filteredFeatures.length / 2),
                ...filteredFeatures.slice(0, filteredFeatures.length / 2),
              ].map((feature, index) => (
                <div
                  key={index}
                  className="min-w-[40vw] sm:min-w-[25vw] lg:min-w-[19vw] px-2 sm:px-3"
                >
                  <div className="bg-white rounded-lg shadow-lg p-4 flex flex-col h-full">
                    <div className="rounded-xl overflow-hidden w-full h-32 sm:h-36 lg:h-40 mb-4">
                      <img
                        src={feature.image}
                        alt={feature.title}
                        className="w-full h-full object-cover"
                      />
                    </div>
                    <div className="flex-1 flex flex-col items-start">
                      <h4 className="text-sm sm:text-md font-bold">
                        {feature.title}
                      </h4>
                      <p className="text-xs sm:text-[12px] mt-2 mb-4">
                        {feature.description}
                      </p>
                      <button className="border border-black px-2 sm:px-3 py-1 font-semibold rounded-md self-start hover:bg-black hover:text-white transition-colors mt-auto">
                        Try Now
                      </button>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>

        <div className="relative overflow-hidden pt-4">
          <div
            className="flex"
            style={{
              animation: "scroll 30s linear infinite",
              animationPlayState: isPaused2 ? "paused" : "running",
              width: "fit-content",
            }}
            onMouseEnter={() => setIsPaused2(true)}
            onMouseLeave={() => setIsPaused2(false)}
          >
            {filteredFeatures.length > 12 &&
              [
                ...filteredFeatures.slice(filteredFeatures.length / 2),
                ...filteredFeatures.slice(filteredFeatures.length / 2),
                ...filteredFeatures.slice(filteredFeatures.length / 2),
              ].map((feature, index) => (
                <div
                  key={index}
                  className="min-w-[40vw] sm:min-w-[25vw] lg:min-w-[19vw] px-2 sm:px-3"
                >
                  <div className="bg-white rounded-lg shadow-lg p-4 flex flex-col h-full">
                    <div className="rounded-xl overflow-hidden w-full h-32 sm:h-36 lg:h-40 mb-4">
                      <img
                        src={feature.image}
                        alt={feature.title}
                        className="w-full h-full object-cover"
                      />
                    </div>
                    <div className="flex-1 flex flex-col items-start">
                      <h4 className="text-sm sm:text-md font-bold">
                        {feature.title}
                      </h4>
                      <p className="text-xs sm:text-[12px] mt-2 mb-4">
                        {feature.description}
                      </p>
                      <button className="border border-black px-2 sm:px-3 py-1 font-semibold rounded-md self-start hover:bg-black hover:text-white transition-colors mt-auto">
                        Try Now
                      </button>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
        {filteredFeatures.length === 0 && (
          <p className="text-center text-sm sm:text-md lg:text-lg text-gray-500 mt-8 h-[41vh] w-full">
            No tools found for the selected category and audience.
          </p>
        )}
      </div>
      {/* EXPLORE SCOOLISH FEATURES */}
      <div className="bg-black text-white px-4 sm:px-8 md:px-16 lg:px-32 pt-8 sm:pt-12 md:pt-16 pb-8">
        <p className="text-center text-xs sm:text-sm md:text-md font-semibold pb-2">
          EXPLORE SCOOLISH FEATURES
        </p>
        <h3 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl text-center font-semibold pb-2">
          Discover How Our Features Work
        </h3>
        <p className="text-center text-xs sm:text-sm md:text-lg lg:text-xl pb-6 sm:pb-8">
          Discover How Scoollish Features Simplify Text Analysis, Enhance
          Understanding, <br className="hidden sm:block" /> and Unlock Hidden
          Insights for Smarter Decisions
        </p>

        {/* Navigation */}
        <div className="relative flex flex-col sm:flex-row items-center rounded-xl bg-gray-800 overflow-hidden">
          <div className="flex overflow-x-auto no-scrollbar scroll-container w-full">
            {features.map((feature, index) => (
              <button
                key={index}
                onClick={() => handleScrollToSection(index)}
                className={`flex-shrink-0 w-1/3 sm:w-1/4 md:w-[16.66%] text-xs sm:text-sm md:text-md text-white bg-[#4a4949] mr-[1px] text-center py-2 sm:py-3 px-2 ${
                  index === activeIndex
                    ? "border-b-4 border-white"
                    : "hover:bg-gray-500"
                }`}
              >
                {feature.title}
              </button>
            ))}
          </div>

          {/* Gradient and Scroll Button */}
          <div className="absolute top-0 right-0 flex items-center h-full z-10">
            {/* Gradient Fade */}
            <div className="h-full w-6 bg-gradient-to-l from-black to-transparent pointer-events-none"></div>
            {/* Scroll Button */}
            <button
              onClick={() => {
                const scrollContainer =
                  document.querySelector(".scroll-container");
                scrollContainer.scrollBy({ left: 300, behavior: "smooth" });
              }}
              className="h-full w-12 bg-white flex items-center justify-center"
            >
              <i className="fas fa-chevron-right text-black text-lg"></i>
            </button>
          </div>
        </div>
      </div>
      <div className="space-y-6 px-4 sm:px-8 md:px-16 lg:px-32 pt-6 pb-16 sm:pt-8 mt-0 bg-black">
        {features.map((feature, index) => (
          <div
            key={index}
            ref={(el) => (sectionRefs.current[index] = el)}
            className={`flex flex-col md:flex-row items-center justify-center border border-gray rounded-xl bg-white h-auto md:h-[540px] ${
              index % 2 === 0 ? "md:flex-row-reverse" : ""
            } px-4 sm:px-6 md:px-12 ${
              activeSection === feature.title ? "block" : "hidden"
            }`}
          >
            {/* Image */}
            <div
              className={`w-full md:w-7/12 flex ${
                index % 2 === 0
                  ? "lg:pl-6 lg:pr-2 md:pl-6 md:pr-2 pt-4 sm:pl-12 sm:pr-12"
                  : "lg:pl-2 lg:pr-6 md:pl-2 md:pr-6 pt-4 sm:pl-12 sm:pr-12"
              }`}
            >
              <img
                src={feature.image}
                alt={feature.title}
                className="rounded-lg w-full h-48 sm:h-[40vh] md:h-[50vh] lg:h-[60vh] object-cover"
              />
            </div>

            {/* Text */}
            <div className="w-full md:w-5/12 p-4 sm:p-6">
              <span className="bg-gray-200 text-xs sm:text-sm md:text-md px-4 py-2 rounded-3xl">
                {feature.title}
              </span>
              <h2 className="text-lg sm:text-2xl md:text-3xl text-black font-bold mt-4 mb-2">
                {feature.name}
              </h2>
              <p className="text-xs sm:text-sm md:text-md lg:text-lg text-black">
                {feature.description}
              </p>
              {feature.tools && (
                <div className="flex flex-wrap space-x-4">
                  {feature.tools.map((tool, index) => (
                    <div
                      key={index}
                      className="flex items-center justify-center px-4 py-2 mt-2 border border-gray-400 rounded-lg text-black text-xs sm:text-sm md:text-md font-medium"
                    >
                      <i className="fas fa-check text-grey-500 mr-2"></i>
                      {tool}
                    </div>
                  ))}
                </div>
              )}
              <button
                className="bg-black text-white px-4 sm:px-6 py-2 sm:py-3 mt-4 rounded-md hover:bg-gray-800 transition"
                onClick={feature.buttonClickHandler || (() => {})}
              >
                Try Feature
              </button>
            </div>
          </div>
        ))}
      </div>
      {/** Discover How Our Features Work */}
      <div className="p-4 sm:p-8 md:p-16 bg-gray-100">
        {/* Header Section */}
        <div className="flex flex-col items-center md:flex-row md:justify-between">
          <div className="flex-1">
            <h1 className="text-2xl sm:text-3xl md:text-4xl text-center text-poppins font-bold text-gray-900 mb-4">
              Discover How Our Features Work
            </h1>
            <p className="text-sm sm:text-lg md:text-xl text-center text-[#212121] mb-6">
              Use the Tools That Make Your Experience Seamless and Powerful
            </p>
            <nav className="flex flex-wrap items-center justify-center gap-4 sm:gap-6 md:gap-8 pt-4 pb-8">
              <button className="px-4 sm:px-6 py-2 text-sm sm:text-md font-medium text-white bg-black rounded-full hover:bg-gray-800 transition-colors">
                AI-Powered Summarization
              </button>
              <button className="px-4 sm:px-6 py-2 text-sm sm:text-md font-medium text-gray-700 hover:text-gray-900 transition-colors">
                Historical Timeline Builder
              </button>
              <button className="px-4 sm:px-6 py-2 text-sm sm:text-md font-medium text-gray-700 hover:text-gray-900 transition-colors">
                Science Experiment Simulator
              </button>
            </nav>
          </div>
        </div>

        {/* Image Section */}
        <div className="px-4 sm:px-12 md:px-24">
          <div className="p-2 sm:p-3 bg-white rounded-lg shadow-md">
            <img
              src="/assets/images/building.png"
              className="w-full h-64 sm:h-[60vh] md:h-[85vh] object-cover rounded-md"
              alt="Segmentation"
            />
          </div>
        </div>

        {/* Explore All Tools Button */}
        <div className="flex justify-center pt-6 sm:pt-8">
          <button className="px-6 sm:px-8 md:px-10 py-3 sm:py-4 text-sm sm:text-md font-medium text-black border border-black rounded-xl hover:bg-gray-800 transition-colors">
            Explore All Tools
          </button>
        </div>
      </div>
      {/* Your Tools, Your Way */}
      <div className="bg-gray-100 h-auto p-4 md:p-6 pt-12 md:pt-16 pb-0">
        <p className="text-center text-[#212121] text-sm md:text-base">
          WHO BENEFITS FROM SCOOLISH
        </p>
        <h1 className="text-3xl md:text-5xl font-bold text-center mb-6 md:mb-8">
          Your Tools, Your Way
        </h1>
        <div className="grid grid-cols-1 gap-6 md:grid-cols-2 md:pl-28 md:pr-28 pt-8">
          {imageData.map((item, index) => (
            <div
              key={index}
              className="relative bg-cover bg-center rounded-lg shadow-lg overflow-hidden h-[40vh] md:h-[50vh] flex"
              style={{ backgroundImage: `url(${item.image})` }}
            >
              {/* Gradient Overlay */}
              <div className="absolute inset-0 bg-gradient-to-t from-black to-transparent"></div>

              {/* Text Content */}
              <div className="relative z-10 text-white flex flex-col justify-end p-4 md:p-6">
                <h3 className="text-lg md:text-xl font-bold">{item.title}</h3>
                <p className="text-sm md:text-md mb-2">{item.description}</p>
                <button className="text-white font-bold border border-white px-3 py-1 rounded-md self-start">
                  {item.buttonText}
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
      {/* Start Exploring Today */}
      <div className="bg-gray-100 h-auto p-4 pt-8 pb-8 rounded md:p-8 lg:p-32">
        <div className="flex flex-wrap bg-black rounded-xl h-auto lg:h-[55vh]">
          <div className="w-full lg:w-[55%] bg-green p-4 lg:pt-32 lg:p-16 mt-4 lg:mt-0 h-auto lg:h-[400px]">
            <h1 className="text-3xl lg:text-5xl text-left font-bold text-white mb-4 mr-4">
              Start Exploring Today
            </h1>
            <p className="text-lg text-white">
              "Transform how you learn, create, and grow."
            </p>
            <div className="flex justify-start mt-8">
              <button className="bg-white hover:bg-gray-500 text-black font-bold py-2.5 px-8 rounded">
                Start Up Free <i className="fa fa-arrow-right ml-2"></i>
              </button>
              <button className="bg-black hover:bg-gray-500 border border-white text-white font-bold ml-2 py-2.5 px-8 rounded">
                Try Demo Tools Now
              </button>
            </div>
          </div>
          <div className="w-full lg:w-[45%] bg-red p-4 lg:p-16 h-auto lg:h-[55vh]">
            <img
              src="/assets/images/home/footer-feature.png"
              alt="AI Homework Helper"
              className="w-full h-full rounded-2xl object-cover"
            />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Tools;

import React, { useRef } from "react";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import KeyFeatures from "../../components/KeyFeatures";
import BenefitCards from "../../components/BenefitCards";
import FeatureFooter from "../../components/FeatureFooter";

const featureData = {
  headerTitle: "Key Features of Interactive Quiz Creator",
  cardsData: [
    {
      icon: "fas fa-rocket text-3xl",
      title: "Customizable Templates",
      description:
        "Create quizzes with a user-friendly interface and intuitive design.",
    },
    {
      icon: "fas fa-cogs text-3xl",
      title: "Diverse Question Types",
      description:
        "Incorporate Multiple Choice, Fil-In-The-Blank, Matching, And... Essay Questions To Engage Learners.",
    },
    {
      icon: "fas fa-chart-line text-3xl",
      title: "Real-Time Collaboration",
      description:
        "Work With Peers. To Create Quizzes Collaboratively, Making It Easy To Share ideas And Resources.",
    },
    {
      icon: "fas fa-chart-bar text-3xl",
      title: "Instant Feedback",
      description:
        "Provide Immediate Feedback To Students. Helping Them Learn From Their Mistakes And Reinforcing Concepts.",
    },
    {
      icon: "fas fa-user-friends text-3xl",
      title: "Analytics Dashboard",
      description:
        "Access Detailed Analytics To Track Quiz Performance And Identify Areas For Improvement.",
    },
    {
      icon: "fas fa-award text-3xl",
      title: "Visual Element",
      description:
        "An Infographic Displaying The Features As Interactive Elements, Showcasing How Users Can Engage With The Platform.",
    },
  ],
};

const benefitsData = {
  title: "Who Benefits from Interactive Quiz Creator?",
  benefits: [
    {
      image: "/assets/images/benefits1.avif",
      title: "For Teachers",
      description:
        "Design Quizzes That Align With Your Curriculum And Assess Student Understanding Effectively.",
    },
    {
      image: "/assets/images/benefits1.avif",
      title: "For Students",
      description:
        "Create Self-Assessment Quizzes To Reinforce Learning And Prepare For Exams.",
    },
    {
      image: "/assets/images/benefits1.avif",
      title: "For Educational Institutions",
      description:
        "Implement interactive Quizzes As Part Of Your Online Learning Platform To Enhance Student Engagement.",
    },
  ],
};

const footerData = {
  title: "Empower Learning with      Interactive Quiz Creator",
  description:
    "Create engaging quizzes with ease to inspire curiosity, enhance learning outcomes, and make education interactive and fun for students of all ages.",
  ctaText1: "Get Started",
  image: "/assets/images/vrlab2.png",
  altText: "Fun Learning",
  className: "pt-15",
};

function InteractiveQuiz() {
  const scrollContainerRef = useRef(null);

  const scrollLeft = () => {
    scrollContainerRef.current.scrollBy({
      left: -400, // Adjust based on card width
      behavior: "smooth",
    });
  };

  const scrollRight = () => {
    scrollContainerRef.current.scrollBy({
      left: 400, // Adjust based on card width
      behavior: "smooth",
    });
  };

  return (
    <>
      <Navbar />
      <div className="bg-black w-full h-screen p-6">
        <div className="flex flex-col md:flex-row items-center bg-black mt-24 pt-14 pr-20 pl-20 pb-10 justify-between">
          <div className="w-full md:w-1/2 mb-6 md:mb-0 px-2">
            <h3 className="text-5xl text-white font-bold mb-2">
              Create Engaging Quizzes Tailored to Your Needs with Interactive
              Quiz Creator
            </h3>
            <p className="text-lg mb-4 text-white pr-10">
              Empower teachers and students to design customized quizzes that
              enhance learning and engagement. Perfect for classrooms, online
              courses, and self-assessment!
            </p>
            <button className="bg-white hover:bg-gray-300 font-bold text-lg py-3 px-6 rounded-lg mt-6 mr-4">
              Start Creating Quizzes Now
            </button>
            <button className="bg-black text-white border-2 border-white hover:bg-gray-300 font-bold text-lg py-2.5 px-16 rounded-lg mt-6 mr-4">
              Learn More
            </button>
          </div>
          <div className="w-full md:w-1/2 pl-6">
            <img
              src="/assets/images/quiz.png"
              alt="AI Homework Helper"
              className="w-full h-auto rounded-xl"
            />
          </div>
        </div>
      </div>
      <div className="bg-gray-100 w-full h-[90vh] p-24 pb-0">
        <div className="flex flex-col md:flex-row items-center  p-6 justify-between">
          <div className="w-full md:w-1/2 mb-6 md:mb-0 px-2">
            <h3 className="text-3xl font-bold mb-2">
              What is Interactive Quiz Creator?
            </h3>
            <p className="text-lg text-bold mb-8 pr-10 text-justify">
              The Interactive Quiz Creator is a powerful tool that allows
              educators and learners to create, customize, and share quizzes
              effortlessly. With a user-friendly interface and a variety of
              question types, you can enhance the learning experience and assess
              knowledge effectively.
            </p>
            <div className="flex items-center mb-4">
              <input
                type="radio"
                name="solution-type"
                id="design"
                className="mr-2"
              />
              <label htmlFor="design" className="text-lg font-inter">
                Design quizzes that cater to different learning styles and
                objectives.
              </label>
            </div>
            <div className="flex items-center">
              <input
                type="radio"
                name="solution-type"
                id="mcqs"
                className="mr-2"
              />
              <label htmlFor="mcqs" className="text-lg font-inter pr-12 mb-4">
                Use a variety of question formats, including multiple choice,
                true/false, and open-ended questions.
              </label>
            </div>
            <div className="flex items-center">
              <input
                type="radio"
                name="solution-type"
                id="progress"
                className="mr-2"
              />
              <label htmlFor="progress" className="text-lg font-inter pr-12">
                Track progress and performance with detailed analytics and
                feedback.
              </label>
            </div>
            <button className="bg-gray-100 border border-black hover:bg-gray-500 font-bold py-3 text-lg  px-8 rounded-xl mt-6">
              Try Now
            </button>
          </div>
          <div className="w-full md:w-1/2">
            <img
              src="/assets/images/quiz2.png"
              alt="AI Homework Helper"
              className="w-full h-[60vh] rounded-xl object-cover"
            />
          </div>
        </div>
      </div>
      <KeyFeatures data={featureData} />
      <div className="bg-black h-screen p-24">
        {/* Header Section */}
        <div className="flex justify-between items-center mb-10">
          <h1 className="text-5xl font-semibold text-white text-left mb-0">
            How It Works
          </h1>
          <div className="flex">
            <button
              onClick={scrollLeft}
              className="px-4 py-2 border border-white rounded-lg hover:bg-gray-500"
            >
              <i className="fa fa-angle-left text-white"></i>
            </button>
            <button
              onClick={scrollRight}
              className="ml-6 px-4 py-2 border border-white rounded-lg hover:bg-gray-500"
            >
              <i className="fa fa-angle-right text-white"></i>
            </button>
          </div>
        </div>

        {/* Scrollable Cards Section */}
        <div
          ref={scrollContainerRef}
          className="flex space-x-6 overflow-x-scroll scroll-smooth no-scrollbar"
        >
          <div className="flex-shrink-0 w-[90%] md:w-[30%] bg-[#323232] rounded-xl p-6">
            <h3 className="text-2xl text-white font-bold mb-4">1. Sign Up</h3>
            <div className="w-full h-[35vh] bg-[#D9D9D9] rounded-xl mb-2">
              <img
                src="/assets/images/how4.png"
                className="w-full h-full rounded-xl object-cover"
              />
            </div>
            <p className="text-md text-white">
               Create your account to access the Interactive Quiz Creator.
            </p>
          </div>
          <div className="flex-shrink-0 w-[90%] md:w-[30%] bg-[#323232] rounded-xl p-6">
            <h3 className="text-2xl text-white font-bold mb-4">
              2. Choose a Template
            </h3>
            <div className="w-full h-[35vh] bg-[#D9D9D9] rounded-xl mb-2">
              <img
                src="/assets/images/how5.png"
                className="w-full h-full rounded-xl object-cover"
              />
            </div>
            <p className="text-md text-white">
              Select a quiz template that suits your needs.
            </p>
          </div>
          <div className="flex-shrink-0 w-[90%] md:w-[30%] bg-[#323232] rounded-xl p-6">
            <h3 className="text-2xl text-white font-bold mb-4">
              3. Add Questions
            </h3>
            <div className="w-full h-[35vh] bg-[#D9D9D9] rounded-xl mb-2">
              <img
                src="/assets/images/how6.png"
                className="w-full h-full rounded-xl object-cover"
              />
            </div>
            <p className="text-md text-white">
              Customize your quiz by adding various question types.
            </p>
          </div>
          <div className="flex-shrink-0 w-[90%] md:w-[30%] bg-[#323232] rounded-xl p-6">
            <h3 className="text-2xl text-white font-bold mb-4">
              4. Share and Assign
            </h3>
            <div className="w-full h-[35vh] bg-[#D9D9D9] rounded-xl mb-2"></div>
            <p className="text-md text-white">
              Distribute your quiz to students or peers for completion.
            </p>
          </div>
        </div>
      </div>
      <BenefitCards data={benefitsData} />
      <FeatureFooter data={footerData} />
      <div className=" bg-gray-100 pt-24 "></div>
      <Footer />
    </>
  );
}

export default InteractiveQuiz;

import React, { useRef } from "react";

const CardCarousel = ({ cardsData, buttonText, heading }) => {
  const scrollContainerRef = useRef(null);

  const scrollLeft = () => {
    scrollContainerRef.current.scrollBy({
      left: -400, // Adjust based on card width
      behavior: "smooth",
    });
  };

  const scrollRight = () => {
    scrollContainerRef.current.scrollBy({
      left: 400, // Adjust based on card width
      behavior: "smooth",
    });
  };

  return (
    <div className="bg-gray-100 w-[90%] px-12 mx-auto ">
      {/* Scrollable Container */}
      {heading && (
        <h1 className="text-5xl font-bold pb-10 pl-2">
          {" "}
          Key Features of {heading}
        </h1>
      )}
      <div
        ref={scrollContainerRef}
        className="flex space-x-4 overflow-x-scroll no-scrollbar scroll-smooth"
        style={{ scrollbarWidth: "none" }}
      >
        {cardsData.map((card, index) => (
          <div
            key={index}
            className="flex-shrink-0 w-[26%] bg-white rounded-lg shadow-md p-4 flex flex-col justify-between"
          >
            <div>
              <img
                src={card.image}
                alt={card.title}
                className="w-full h-[25vh] object-cover rounded-md mb-4"
              />
              <h2 className="font-bold text-xl mb-2">{card.title}</h2>
              <p className="text-black text-sm mb-3">{card.description}</p>
              {card.features !== null && (
                <ul className="list-disc pl-5 mb-4 text-sm text-black">
                  {card.features.map((feature, idx) => (
                    <li key={idx}>{feature}</li>
                  ))}
                </ul>
              )}
            </div>
            {buttonText && (
              <button
                onClick={card.buttonClickHandler || (() => {})}
                className={`mt-auto self-start px-8 py-2.5 rounded-md ${card.buttonClass}`}
              >
                {buttonText}
              </button>
            )}
          </div>
        ))}
      </div>

      {/* Scroll Buttons */}
      <div className="mt-12">
        <button
          onClick={scrollLeft}
          className="px-4 py-2 bg-black rounded-lg hover:bg-gray-500"
        >
          <i class="fa fa-angle-left text-white"></i>
        </button>
        <button
          onClick={scrollRight}
          className="ml-2 px-4 py-2 bg-black rounded-lg hover:bg-gray-500 mb-10"
        >
          <i class="fa fa-angle-right text-white"></i>
        </button>
      </div>
    </div>
  );
};

export default CardCarousel;

import React from "react";

const FeatureBenefitsType1 = ({ data }) => {
  return (
    <div className="p-24 mx-auto pt-16 pb-10">
      {/* Header Section */}
      <div className="flex justify-between items-center mb-12">
        <div className="flex-1">
          <h1 className="text-4xl font-bold text-gray-900 mb-8">
            {data.headerTitle}
          </h1>
          <p className="text-[#212121] text-xl">{data.headerDescription}</p>
        </div>
      </div>

      {/* Main Content */}
      <div className="flex">
        {/* Left Section: Banner */}
        <div className="relative w-1/4 h-[55vh] bg-gradient-to-b from-transparent via-transparent to-black rounded-lg overflow-hidden">
          <img
            src={data.bannerImage}
            alt={data.bannerAltText}
            className="w-full h-full object-cover"
          />
          <div className="absolute inset-0 bg-black bg-opacity-50 flex flex-col justify-end p-16 pl-6">
            <h2 className="text-white text-3xl font-bold mb-2">
              {data.bannerTitle}
            </h2>
            <button
              className={
                "bg-transparent text-black px-4 py-2 rounded-3xl gap-2 font-bold text-white border border-white"
              }
            >
              {data.bannerCtaText} <i className="fa fa-angle-right  "></i>
            </button>
          </div>
        </div>

        {/* Right Section: Cards */}
        <div className="w-3/4 grid grid-cols-3 gap-4 ml-6">
          {data.cardsData.slice(0, 3).map((card, index) => (
            <div
              key={index}
              className="border border-gray-200 rounded-lg p-4 shadow-sm hover:shadow-lg transition duration-300 bg-white"
            >
              {/* Icon Placeholder */}
              <div className="w-16 h-16 mb-4 border border-gray rounded-lg flex items-center justify-center">
                <i className={card.icon}></i>
              </div>
              <h3 className="font-bold text-inner text-xl mb-2">
                {card.title}
              </h3>
              <p className="text-[#212121] font-inner text-lg">
                {card.description}
              </p>
            </div>
          ))}
          <div className="grid grid-cols-2 gap-6 col-span-3">
            {data.cardsData.slice(3).map((card, index) => (
              <div
                key={index}
                className="border border-gray-200 rounded-lg p-4 shadow-sm hover:shadow-lg transition duration-300 bg-white"
              >
                {/* Icon Placeholder */}
                <div className="w-16 h-16 mb-4 border border-gray rounded-lg flex items-center justify-center">
                  <i className={card.icon}></i>
                </div>
                <h3 className="font-bold text-inner text-xl mb-2">
                  {card.title}
                </h3>
                <p className="text-[#212121] font-inner text-lg">
                  {card.description}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeatureBenefitsType1;

// FeatureUpload.js
import React from "react";

const FeatureUpload = ({ data }) => {
  return (
    <div className="bg-gray-100 min-h-screen flex flex-col items-center justify-center p-8 pt-0">
      {/* Header Section */}
      <div className="text-center mb-8">
        <p className="text-sm uppercase font-semibold text-black">
          {data.headerSubtitle}
        </p>
        <h2 className="text-4xl font-bold text-black pb-8">
          {data.headerTitle}
        </h2>
      </div>

      {/* Main Content */}
      <div className="flex flex-col md:flex-row w-full max-w-7xl h-[65vh] bg-white rounded ">
        {/* Left Section (Image Placeholder) */}
        <div className="w-full md:w-1/2 bg-black flex items-center rounded-lg justify-center">
          <div className="text-gray-500 text-lg font-semibold">
            <img
              src={data.image}
              alt={data.altText}
              className="w-full h-[60vh] rounded-lg object-cover py-4"
            />
          </div>
        </div>

        {/* Right Section (Steps) */}
        <div className="w-full md:w-1/2 bg-gray-100 p-8">
          <div className="space-y-8">
            {data.steps.map((step, index) => (
              <div
                key={index}
                className="flex items-start space-x-4 ml-16 hover:bg-gray-300 p-4 rounded"
              >
                <div className="text-xl font-semibold text-white bg-purple-200 rounded-lg h-8 w-8 flex items-center justify-center">
                  <span className="text-sm text-black">{index + 1}</span>
                </div>
                <div>
                  <h3 className="text-lg font-bold text-gray-800">
                    {step.title}
                  </h3>
                  <p className="text-sm">{step.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeatureUpload;

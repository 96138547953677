import React from "react";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import CardCarousel from "../../components/CardCarousel";
import FeatureHeader from "../../components/FeatureHeader";
import FeatureHeroSection from "../../components/FeatureHeroSection";
import SubFeatures from "../../components/SubFeatures";
import FeatureFooter from "../../components/FeatureFooter";
import FeatureUpload from "../../components/FeatureUpload";
import FeatureBenefitsType2 from "../../components/FeatureBenefitsType2";
import FAQSection from "../../components/FAQSection";

const topSectionData = {
  title: "Discover Hidden Themes with Topic Modeling",
  subtitle: "",
  description:
    "Effortlessly analyze vast amounts of text and uncover the topics that matter. Scoolish’s Topic Modeling feature empowers researchers, students, and professionals to navigate data with precision.",
  ctaText: "Try Topic Modeling Now",
  ctaText2: "Explore More Features",
  image: "/assets/images/topicModeling1.png",
  altText: "AI Homework Helper",
};

const heroSectionData = {
  title: "What is Topic Modeling?",
  description:
    "Topic Modeling is the process of identifying hidden themes in large datasets of text. With Scoolish, you can uncover these patterns in seconds, transforming complex text into actionable insights.",
  radioOptions: [
    {
      id: "timesave",
      label: "Simplify analysis of unstructured text.",
    },
    {
      id: "accuracy",
      label:
        "Gain deep insights from academic papers, customer feedback, or historical documents.",
    },
    {
      id: "versatile",
      label:
        "Enhance decision-making with clear and concise summaries of themes.",
    },
  ],
  image: "/assets/images/topicModeling2.png",
  altText: "Topic Modeling",
};

const cardsData = [
  {
    image: "/assets/images/topicModeling3.png",
    title: "Latent Dirichlet Allocation (LDA)",
    description:
      "Harness LDA algorithms to identify clusters of words representing specific topics",
    features: [
      "Enables efficient topic modeling by automatically discovering hidden thematic structure",
      "LDA enhances information retrieval and improves the relevance of search results",
    ],
  },
  {
    image: "/assets/images/topicModeling4.png",
    title: "Dynamic Topic Detection",
    description:
      " Analyze evolving topics in real-time datasets, such as news streams or social media",
    features: [
      "Allows for real-time identification of emerging themes and trends in streaming data",
      "This approach enhances decision-making and strategic planning",
    ],
  },
  {
    image: "/assets/images/topicModeling5.png",
    title: "Topic Relevance Scoring",
    description:
      "Measure the importance of each topic with relevance scores for prioritization",
    features: [
      "Quantifies the significance of topics within a dataset, helping users prioritize and focus on the most impactful themes for their analysis",
      "This scoring system enhances content curation and improves the effectiveness of information retrieval",
    ],
  },
  {
    image: "/assets/images/topicModeling6.png",
    title: "Document Categorization",
    description:
      "Automatically group similar documents based on their dominant topics",
    features: [
      "Automates the classification of documents into predefined categories, streamlining information management and enhancing retrieval efficiency",
      "This process improves user experience and enables quicker access to relevant information",
    ],
  },
];

const subFeaturesData = [
  {
    title: "Multi-Language Support",
    description:
      "Analyze text in over 100 languages with advanced language models, ensuring accurate results across global datasets.",
    icon: "fa fa-language",
  },
  {
    title: "Keyword Extraction",
    description:
      "Highlight essential keywords for each topic, offering deeper insights into specific themes.",
    icon: "fa fa-keyboard",
  },
  {
    title: "Heatmap Visualization",
    description:
      "See the distribution of topics across documents through interactive heatmaps.",
    icon: "fa fa-rainbow ",
  },
  {
    title: "Temporal Analysis",
    description:
      "Track how topics evolve over time to detect trends and shifts in conversations.",
    icon: "fa-regular fa-clipboard",
  },
  {
    title: "Sentiment Integration",
    description:
      "Combine topic modeling with sentiment analysis to understand the emotional tone of each theme.",
    icon: "fa fa-clock-rotate-left",
  },
];

const uploadData = {
  headerSubtitle: "How It Works",
  headerTitle: "How Does Topic Modeling Work?",
  image: "/assets/images/segs.png",
  altText: "Fun Learning",
  steps: [
    {
      title: "Upload Your Content",
      description: "Upload your dataset or paste your text.",
    },
    {
      title: "Choose Numbers",
      description: "Choose the number of topics you want to extract.",
    },
    {
      title: "Refine Results",
      description: "Refine results with filters and custom stop words.",
    },
    {
      title: "Visualize Topics",
      description: "Visualize the topics and their connections.",
    },
  ],
};

const featureType2Data = {
  headerTitle: "Who Benefits from Topic modeling?",
  headerDescription:
    "Assistance To 3D Modeling And Virtual Labs, Every Tool is Crafted To Inspire And Empower Young Minds.",
  cardsData: [
    {
      title: "For Educators",
      description:
        "Quickly summarize and organize academic literature for easier lesson planning.",
      icon: "fa-solid fa-location-crosshairs text-3xl",
    },
    {
      title: "For Businesses",
      description:
        "Identify customer pain points and preferences from reviews and survey feedback.",
      icon: "fa-solid fa-location-crosshairs text-3xl",
    },
    {
      title: "For Researchers",
      description:
        "Analyze historical data to uncover emerging patterns and correlations.",
      icon: "fa-solid fa-location-crosshairs text-3xl",
    },
    {
      title: "Content Creators",
      description: "Generate concise outlines for articles, videos, or blogs.",
      icon: "fa-solid fa-location-crosshairs text-3xl",
    },
  ],
};

const questions = [
  {
    title: "What Types Of Files Are Supported?",
    content: "We support text, Word documents, PDFs, and more.",
  },
  {
    title: "How Accurate Is The Summary?",
    content:
      "Our summaries aim for high accuracy, though results may vary depending on the content provided.",
  },
  {
    title: "Can I Customize The Length Of The Summary?",
    content: "Yes, you can customize the length to suit your needs.",
  },
  {
    title: "Collaborative Mind Mapping",
    content:
      "Work with others in real-time to create structured summaries and ideas.",
  },
];

const footerData = {
  title: "Start Modeling Topics with Scoolish",
  description:
    "At Scoolish, we believe in the synergy between human creativity and artificial intelligence. Our mission is to empower users to harness AI as a supportive tool that complements their unique skills and insights, fostering independence and originality in their projects.",
  ctaText1: "Try Topic Modeling",
  ctaText2: "Sign Up for Scoolish",
  image: "/assets/images/tp.png",
  altText: "Future Learning",
  className: "pt-18",
};

function TopicModeling() {
  return (
    <>
      <Navbar />
      <FeatureHeader data={topSectionData} />
      <FeatureHeroSection data={heroSectionData} />
      <div className="bg-gray-100 h-[60%] text-left pt-0 pb-16">
        <CardCarousel
          cardsData={cardsData}
          buttonText={null}
          heading={"Topic Modeling"}
        />
      </div>
      <SubFeatures features={subFeaturesData} heading={"Topic Modeling"} />
      <FeatureUpload data={uploadData} />
      <div
        className="bg-cover bg-center bg-no-repeat h-[65vh] w-full"
        style={{ backgroundImage: "url('/assets/images/background.png')" }}
      >
        <FeatureBenefitsType2 data={featureType2Data} />
      </div>
      <div className="p-16 bg-gray-100">
        {/* Header Section */}
        <div className="flex justify-between items-center">
          <div className="flex-1">
            <h1 className="text-4xl text-center text-poppins font-bold text-gray-900 mb-4">
              Try Topic Modeling in Action!
            </h1>
            <p className="text-[#212121] text-center text-xl mb-6">
              {" "}
              Discover How Scoollish Features Simplify Text Analysis, Enhance
              Understanding,
              <br /> and Unlock Hidden Insights for Smarter Decisions
            </p>
          </div>
        </div>

        <div className="px-24">
          <div className="p-3 bg-white rounded-lg shadow-md">
            {" "}
            <img
              src="/assets/images/tp1.png"
              className="w-full h-[85vh] object-cover rounded-md"
              alt="Segmentation"
            />
          </div>
        </div>
      </div>
      <FAQSection questions={questions} />
      <FeatureFooter data={footerData} />
      <div className=" bg-gray-100 pt-24 "></div>
      <Footer />
    </>
  );
}

export default TopicModeling;

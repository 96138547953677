import React from "react";

const BenefitCard = ({ image, title, description }) => {
  return (
    <div
      className="relative bg-cover bg-center rounded-lg shadow-lg overflow-hidden h-[50vh]"
      style={{ backgroundImage: `url(${image})` }}
    >
      {/* Gradient Overlay */}
      <div className="absolute inset-0 bg-gradient-to-t from-black to-transparent"></div>
      {/* Text Content */}
      <div className="absolute bottom-4 left-4 right-4 text-white">
        <h3 className="text-xl font-bold pl-2">{title}</h3>
        <p className="text-md mt-2 pl-2 pr-2 pb-4">{description}</p>
      </div>
    </div>
  );
};

export default BenefitCard;

import React from "react";

function SubFeatures({ features, heading }) {
  return (
    <div className="bg-black text-white pt-16 pb-16 p-32">
      <h1 className="text-5xl font-bold font-inner text-left mb-8">
        Sub-Features of {heading}
      </h1>
      <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-5 gap-2">
        {features.map((feature, index) => (
          <div
            key={index}
            className="bg-[#FAFAFA] text-black font-inner rounded-lg p-4 px-4 "
          >
            <i
              className={`${feature.icon} p-4 text-3xl border border-gray mb-4`}
            ></i>
            <h2 className="text-xl font-bold mb-2">{feature.title}</h2>
            <p className="text-lg   ">{feature.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default SubFeatures;

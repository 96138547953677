import React from "react";

function FeatureHeader({ data }) {
  return (
    <div className="bg-black w-full h-[90vh] p-6">
      <div className="flex flex-col md:flex-row items-center bg-black mt-24 pt-14 pr-20 pl-20 pb-10 justify-between">
        <div className="w-full md:w-1/2 mb-6 md:mb-0 px-2 pr-10">
          <h3 className="text-5xl text-white font-semibold mb-2">
            {data.title}
            <br />
            {data.subtitle}
          </h3>
          <p className="text-lg mb-2 text-white pr-20">{data.description}</p>
          <button className="bg-white hover:bg-gray-300 font-bold text-lg py-3 px-6 rounded-lg mt-6 mr-4">
            {data.ctaText}
          </button>
          <button className="bg-black text-white border border-white hover:bg-gray-300 font-bold text-lg py-3 px-6 rounded-lg mt-6 mr-4">
            {data.ctaText2}
          </button>
        </div>
        <div className="w-full md:w-1/2 pl-6">
          <img
            src={data.image}
            alt={data.altText}
            className="w-full h-[50vh] rounded-xl object-cover"
          />
        </div>
      </div>
    </div>
  );
}

export default FeatureHeader;

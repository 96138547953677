import React, { useRef } from "react";
import Footer from "../../components/Footer";
import Navbar from "../../components/Navbar";
import { useNavigate } from "react-router-dom";

function Feature() {
  const sectionRefs = useRef([]);
  const navigate = useNavigate();

  const features = [
    {
      name: "Breaking Down Complexity Into Clarity",
      description:
        "Divide large text into meaningful sections or segments. This helps you focus on the most relevant parts without getting lost in the details. Perfect for structured analysis of research papers, contracts, or long-form content.",
      image: "/assets/images/segmentation.png",
      buttonClickHandler: () => {
        navigate("/segmentation");
        window.scrollTo(0, 10);
      },
    },
    {
      name: "Turn Pages Into Paragraphs",
      description:
        "Get concise and accurate summaries of lengthy content in seconds. Ideal for reports, articles, or any long document when you’re short on time but need all the key details.",
      image: "/assets/images/aiSummary.png",
      buttonClickHandler: () => {
        navigate("/summarization");
        window.scrollTo(0, 10);
      },
    },
    {
      name: "Discover the Themes Behind the Text",
      description:
        "Automatically uncover the hidden topics in your text. Gain insights into trends, discussions, and core ideas driving your content.",
      image: "/assets/images/topicModeling.png",
      buttonClickHandler: () => {
        navigate("/topic-modeling");
        window.scrollTo(0, 10);
      },
    },
    {
      name: "Gauge the Emotional Pulse of Your Content",
      description:
        "Analyze the tone of your text—positive, negative, or neutral. Perfect for understanding customer feedback, reviews, or social media sentiment.",
      image: "/assets/images/sentiment.png",
      buttonClickHandler: () => {
        navigate("/sentiment-analysis");
        window.scrollTo(0, 10);
      },
    },
    {
      name: "Unravel the Timeline of Events",
      description:
        "Extract and organize events in chronological order. This feature helps you track histories, project timelines, or story progressions with ease.",
      image: "/assets/images/chronology.png",
      buttonClickHandler: () => {
        navigate("/chronology");
        window.scrollTo(0, 10);
      },
    },
    {
      name: "Find the Closest Match",
      description:
        "Compare documents or sections to identify similarities. Ideal for plagiarism detection, content comparison, or finding the most relevant references.",
      image: "/assets/images/similarity.png",
      buttonClickHandler: () => {
        navigate("/similarity");
        window.scrollTo(0, 10);
      },
    },
    {
      name: "Connect the Dots Across Data",
      description:
        "Identify and resolve entities like names, places, or organizations across different documents. Gain a unified understanding of your data by linking related information.",
      image: "/assets/images/entity.png",
      buttonClickHandler: () => {
        navigate("/entity-resolution");
        window.scrollTo(0, 10);
      },
    },
  ];

  // Scroll to the specific section
  const handleScrollToSection = (index) => {
    sectionRefs.current[index].scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  };

  return (
    <>
      <Navbar />
      <div className="bg-black w-full h-screen p-6">
        <div className="flex flex-col md:flex-row items-center bg-black mt-24 pt-14 pr-20 pl-20 pb-10 justify-between">
          <div className="w-full md:w-1/2 mb-6 md:mb-0 px-2 pr-20">
            <h3 className="text-5xl text-white font-semibold mb-2">
              Transforming Education
              <br /> with Cutting-Edge <br /> AI Features
            </h3>
            <p className="text-lg mb-2 text-white">
              Explore a world of possibilities with Scoolish. From Summarization
              to Entity Resolution, empower your learning journey and
              decision-making with our innovative features tailored to meet the
              demands of modern education and research.
            </p>
            <button className="bg-white hover:bg-gray-300 font-bold text-lg py-3 px-6 rounded-lg mt-6 mr-4">
              Explore Features
            </button>
            <button className="bg-black text-white border-2 border-white hover:bg-gray-300 font-bold text-lg py-3 px-6 rounded-lg mt-6 mr-4">
              Get Started Now
            </button>
          </div>
          <div className="w-full md:w-1/2 pl-6">
            <img
              src="/assets/images/featureHeader.png"
              alt="AI Homework Helper"
              className="w-full h-auto rounded-xl"
            />
          </div>
        </div>
      </div>
      <div className=" bg-gray-50 p-32 pt-16 pb-0">
        <p className="text-center text-sm font-semibold ">
          EXPLORE SCOOLISH FEATURES
        </p>
        <h3 className="text-5xl text-center font-semibold mb-2">
          Unlock the Power of Text, Scoollish Features
        </h3>
        <p className="text-center text-lg">
          Discover How Scoolish Features Simplify Text Analysis, Enhance
          Understanding, <br /> and Unlock Hidden Insights for Smarter Decisions
        </p>
      </div>

      {/* Navigation Buttons */}
      <div className="flex p-32 pt-8 pb-0 justify-around bg-gray-50">
        {features.map((feature, index) => (
          <button
            key={index}
            onClick={() => handleScrollToSection(index)}
            className="py-3 px-9 text-black bg-white border border-gray-300 rounded-md hover:bg-gray-200 transition"
          >
            {feature.name}
          </button>
        ))}
      </div>

      {/* Features Section */}
      <div className="space-y-6 p-32 pt-8 bg-gray-50">
        {features.map((feature, index) => (
          <div
            key={index}
            ref={(el) => (sectionRefs.current[index] = el)}
            className={`flex flex-col md:flex-row items-center justify-center border border-gray rounded-xl bg-white h-[540px] ${
              index % 2 === 0 ? "md:flex-row-reverse" : ""
            } px-6 lg:px-12`}
          >
            {/* Image */}
            <div className="w-full md:w-7/12 flex">
              <img
                src={feature.image}
                alt={feature.title}
                className="rounded-lg w-full h-[45vh] object-cover"
              />
            </div>

            {/* Text */}
            <div className="w-full md:w-5/12 p-6">
              <span className="bg-gray-200 text-md px-4 py-2 rounded-3xl">
                {feature.title}
              </span>
              <h2 className="text-2xl text-black font-bold mt-4 mr-20 mb-2">
                {feature.name}
              </h2>
              <p className="text-black mb-4">{feature.description}</p>
              <button
                className="bg-black text-white px-4 py-2 rounded-md hover:bg-gray-800 transition"
                onClick={feature.buttonClickHandler || (() => {})}
              >
                {feature.button}
              </button>
            </div>
          </div>
        ))}
      </div>
      <Footer />
    </>
  );
}

export default Feature;

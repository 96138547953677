import React from "react";

function KeyFeatures({ data }) {
  return (
    <div className="bg-gray-100 p-16 pt-0">
      {/* Header Section */}
      <div className="flex justify-between items-center p-6">
        <div className="flex-1">
          <h1 className="text-5xl font-bold text-gray-900">
            {data.headerTitle}
          </h1>
        </div>
        <div className="self-start py-2">
          <button className=" text-black font-bold border border-black px-10 py-4 rounded-md">
            Get This Now
          </button>
        </div>
      </div>

      {/* Main Content */}
      <div className="flex pt-0">
        {/* Right Section: Cards */}
        <div className="grid grid-cols-3 gap-2 pt-0 p-6">
          {data.cardsData.map((card, index) => (
            <div
              key={index}
              className="border border-gray-200 rounded-lg p-4 shadow-sm transition duration-300 bg-white text-black hover:bg-black hover:text-white"
            >
              {/* Icon Placeholder */}
              <div className="w-16 h-16 mb-4 border border-gray rounded-lg flex items-center justify-center hover:border-white">
                <i className={card.icon}></i>
              </div>
              <h3 className="font-bold text-inner text-xl mb-2 hover:text-white">
                {card.title}
              </h3>
              <p className="text-[#212121] font-inner text-lg hover:text-white">
                {card.description}
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default KeyFeatures;

import React from "react";
import FooterBottom from "./FooterBottom";
import FooterColumn from "./FooterColumn";
import ButtonFooterColumn from "./ButtonFooterColumn";

function Footer() {
  return (
    <footer className="bg-black text-white">
      {/* Footer Section */}
      <div className="py-6 px-6">
        <div className="grid grid-cols-4 lg:grid-cols-12 gap-8">
          {/* Column 1: Branding */}
          <div className="col-span-4 lg:col-span-3">
            <h3 className="text-2xl">
              Scoolish <span className="text-purple-700">AI</span>
            </h3>
            <p className="mt-4 text-gray-400">
              Unlock your potential with Scoolish—your all-in-one platform for
              smarter learning and growth!
            </p>
          </div>
          {/* Column 2-5: Footer Columns */}
          <div className="col-span-4 lg:col-span-9 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-8">
            {/* Column 2: Navigation */}
            <FooterColumn
              title="Features"
              items={[
                { text: "Segmentation", handlerText: "/",  },
                { text: "Summarization", handlerText: "/",  },
                { text: "Topic Modelling", handlerText: "/",  },
                {
                  text: "Sentiment Analysis",
                  handlerText: "/", 
                },
                { text: "Chronology", handlerText: "/",  },
                { text: "Similarity", handlerText: "/",  },
                {
                  text: "Entity Resolution",
                  handlerText: "/", 
                },
              ]}
            />
            {/* Column 3: What We Do */}
            <FooterColumn
              title="Tools"
              items={[
                {
                  text: "AI-Powered Homework Helper",
                  handlerText: "/", 
                },
                {
                  text: "Interactive Quiz Creator",
                  handlerText: "/",
                },
                { text: "Virtual Science Lab", handlerText: "/" },
                { text: "AI Writing Assistant", handlerText: "/" },
                { text: "Learn by Drawing", handlerText: "/" },
                { text: "Digital Debate Platform", handlerText: "/" },
                { text: "3D Model Builder", handlerText: "/" },
              ]}
            />
            {/* Column 4: Legal */}
            <FooterColumn
              title="Explore"
              items={[
                { text: "About Us", handlerText: "/" },
                { text: "How It Works", handlerText: "/" },
                { text: "Success Stories", handlerText: "/" },
                { text: "Blog & News", handlerText: "/" },
                { text: "Careers", handlerText: "/" },
                { text: "Research Initiatives", handlerText: "/" },
              ]}
            />
            {/* Column 5: Contact Info */}
            <FooterColumn
              title="Contact Info"
              items={[
                { text: "support@scoolish.com", handlerText: "/" },
                { text: "+91-9871188346", handlerText: "/" },
                {
                  text: "E310, E Block, East of Kailash, Delhi, 110065",
                  handlerText: "/",
                },
              ]}
            />
          </div>
        </div>
      </div>
      {/* Footer Bottom Section */}
      <FooterBottom />
    </footer>
  );
}

export default Footer;

import React from "react";
import { useNavigate } from "react-router-dom";

function FooterColumn({ title, items }) {
  const navigate = useNavigate();
  return (
    <>
      <div className="flex-1">
        <ul className="text-gray-400">
          <h4 className="text-lg font-semibold text-white">{title}</h4>
          {items.map((item, index) => (
            <li
              key={index}
              onClick={() => {
                navigate(`${item.handlerText}`);
                window.scrollTo(0, 10);
              }}
            >
              {item.text}
            </li>
          ))}
        </ul>
      </div>
    </>
  );
}

export default FooterColumn;

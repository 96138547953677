import React, { useState } from "react";

const FAQSection = ({ questions }) => {
  const [openIndex, setOpenIndex] = useState(0); // First question open by default

  return (
    <div className="bg-gray-100 py-12 ">
      {/* Header */}
      <h1 className="text-center text-gray-800 text-6xl font-inner font-bold mb-24">
        Frequently Asked Questions !
      </h1>

      {/* Accordion Section */}
      <div className="max-w-7xl mx-auto bg-white shadow-lg rounded-lg">
        {questions.map((feature, index) => (
          <div
            key={index}
            className={`border-b ${
              openIndex === index ? "border-l-4 border-black" : "border-l"
            }`}
          >
            <div
              className="flex justify-between items-center px-8 py-5 cursor-pointer"
              onClick={() => setOpenIndex(index === openIndex ? null : index)}
            >
              <h2
                className={`text-xl font-semibold ${
                  openIndex === index ? "text-black" : "text-black"
                }`}
              >
                {feature.title}
              </h2>
              <span>
                {openIndex === index ? (
                  <i className="fa fa-angle-up"></i>
                ) : (
                  <i className="fa fa-angle-down"></i>
                )}
              </span>
            </div>
            {openIndex === index && (
              <div className="px-8 pb-5 text-black">{feature.content}</div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default FAQSection;

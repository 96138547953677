import React, { useEffect, useRef, useState } from "react";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import Plans from "../../components/Plans";
import { useNavigate } from "react-router-dom";
import FeatureSection from "../../components/FeatureSection.jsx";

const heroData = [
  {
    title: "Knowledge Management",
    description: "Centralize and organize your resources effortlessly.",
    image: "/assets/images/1.png",
  },
  {
    title: "AI-Powered Insights",
    description: "Analyze, summarize, and simplify complex content quickly.",
    image: "/assets/images/2.png",
  },
  {
    title: "Creative Learning Tools",
    description: "Turn learning into fun, interactive experiences.",
    image: "/assets/images/3.png",
  },
  {
    title: "Collaboration",
    description: "Connect and work together seamlessly.",
    image: "/assets/images/4.png",
  },
];

const imageData = [
  {
    title: "Students: Study smarter and stay ahead",
    image: "/assets/images/common/students.png",
    buttonText: "Discover Tools",
  },

  {
    title: "Educators: Engage students with creative and fun tools",
    image: "/assets/images/common/educators.png",
    buttonText: "Learn More",
  },
  {
    title: "Parents: Support your child’s learning journey with curated tools",
    image: "/assets/images/common/professionals.png",
    buttonText: "Support Learning",
  },
  {
    title: "Researchers: Streamline analysis and gain deeper insights",
    image: "/assets/images/common/learners.png",
    buttonText: "Explore Insigits",
  },
  {
    title: "Professionals: Build skills and collaborate with ease",
    image: "/assets/images/common/professionals.png",
    buttonText: "See Professional Tools",
  },
  {
    title: "Organizations: Empower teams with smarter learning tools",
    image: "/assets/images/common/professionals.png",
    buttonText: "Schedule a Demo",
  },
];

const features = [
  {
    title: "Unified Knowledge Hub",
    content: "Search, store, and organize resources from multiple platforms",
  },
  {
    title: "Easy Upload",
    content: "Quickly add resources in any format",
  },
  {
    title: "Smart Organization",
    content: "AI categorizes and tags resources for you.",
  },
];

const features2 = [
  {
    title: "Summarization",
    content: "Condense large documents into key takeaways.",
  },
  {
    title: "Topic Modeling",
    content: "Identify and explore key themes within your content.",
  },
  {
    title: "Content Segmentation",
    content: "Divide long materials into manageable sections.",
  },
  {
    title: "Sentiment Analysis",
    content: "Understand the tone and intent behind the information.",
  },
];

const features3 = [
  {
    title: "AI Homework Helper",
    content: "Your personal tutor for solving complex problems",
  },
  {
    title: "Comics Converter",
    content: "Make learning fun by turning lessons into comics",
  },
];

const Home = () => {
  const navigate = useNavigate();
  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [hasStarted, setHasStarted] = useState(false); // Ensure autoplay happens only once

  const handlePlayPause = () => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.pause();
      } else {
        videoRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  useEffect(() => {
    if (!hasStarted) {
      const timer = setTimeout(() => {
        if (videoRef.current) {
          videoRef.current.muted = true; // Ensure muted autoplay
          videoRef.current
            .play()
            .then(() => {
              setIsPlaying(true);
              setHasStarted(true); // Prevent subsequent autoplay attempts
            })
            .catch((err) => {
              console.error("Autoplay failed:", err);
            });
        }
      }, 2000); // 2 seconds delay

      return () => clearTimeout(timer); // Cleanup timeout
    }
  }, [hasStarted]); // Dependency on hasStarted to avoid re-trigger

  return (
    <>
      <Navbar />
      <div className="bg-black p-16 pb-6 text-white">
        {/* Header Section */}
        <div className="text-center lg:px-6 py-12">
          <h1 className="text-3xl md:text-5xl font-semibold">
            Unlock Your Potential with Scoolish
          </h1>
          <p className="mt-4 text-white font-inner text-2xl">
            Transform the way you learn, collaborate, and grow - all in one
            place.
          </p>
        </div>
      </div>
      {/* Video Section */}
      <div className="bg-gradient-to-b from-black via-black to-gray-100 text-white">
        {/* Video Section */}
        <div className="relative px-6">
          <div className="relative mx-auto max-w-7xl object-fit">
            {/* Video */}
            <video
              ref={videoRef}
              className="w-full rounded-xl shadow-lg"
              onClick={handlePlayPause}
              loop={true}
            >
              <source
                src="https://scoolishopenmedia.blob.core.windows.net/scoolish-open-media/scoolish_video4_compressed.mp4"
                type="video/mp4"
              />
              Your browser does not support the video tag.
            </video>

            <div
              onClick={handlePlayPause}
              className="absolute inset-0 flex items-center justify-center cursor-pointer"
            >
              <div className="mt-6 flex justify-center gap-4">
                <button className="bg-white text-black px-6 py-2 rounded-md font-bold hover:bg-gray-200">
                  Try For Free
                </button>
                <button className=" px-5 py-2 rounded-md font-bold bg-black">
                  Book a Demo
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Hero Section*/}
      <div className="p-6 md:p-32 md:pt-16 sm:pt-16 md:pb-0 bg-gray-100">
        {/* Header Section */}
        <div className="flex flex-col md:flex-row justify-center items-center md:mb-12">
          <h1 className="lg:text-4xl md:text-3.5xl text-xl font-bold text-center">
            Your All-in-One Platform for <br />
            Learning, Collaboration, and Knowledge Management
          </h1>
        </div>

        {/* Features Section */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
          {heroData.map((feature, index) => (
            <div
              key={index}
              className="bg-white rounded-lg shadow-lg p-4 flex flex-col"
              onClick={() => {
                navigate("/tool-page");
                window.scrollTo(0, 10);
              }}
              style={{ cursor: "pointer" }}
            >
              {/* Image */}
              <div className="rounded-xl overflow-hidden w-full h-auto mb-4">
                <img
                  src={feature.image}
                  alt={feature.title}
                  className="w-full h-full object-cover"
                />
              </div>
              {/* Text and Button */}
              <div className="flex-1 flex flex-col items-start">
                {/* Title */}
                <h4 className="text-md font-bold">{feature.title}</h4>
                {/* Description */}
                <p className="text-[12px] text-black mt-2">
                  {feature.description}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="bg-gray-100 flex flex-col items-center justify-center pt-8 lg:pt-20 md:pt-16">
        <h3 className="text-lg sm:text-xl md:text-2xl lg:text-3xl xl:text-4xl text-center font-semibold mb-4">
          Discover What What Makes Scoolish Unique
        </h3>
      </div>
      <FeatureSection
        title="Knowledge Management"
        subtitle="All Your Knowledge, Organized in One Place"
        features={features}
        imageSrc="/assets/images/home/social-media.png"
        buttonLabel="Start Organizing"
        theme="light"
      />
      <FeatureSection
        title="AI-Powered Insights"
        subtitle="Turn Information Into Actionable Knowledge"
        features={features2}
        imageSrc="/assets/images/home/ai-summary.png"
        buttonLabel="Explore AI Tools"
        theme="light"
        imagePosition="right"
      />
      <FeatureSection
        title="Creative Learning Tools"
        subtitle="Learn and Create Like Never Before"
        features={features3}
        imageSrc="/assets/images/home/ai-homework-helper.png"
        buttonLabel="Discover Tools"
        theme="dark"
      />
      {/* Features Section 4*/}
      <div className="p-6 md:p-12 lg:p-32 lg:pb-10 bg-gray-100">
        <div className="flex flex-col md:flex-row items-center justify-center border border-gray-300 rounded-xl bg-white p-4 md:p-6 lg:p-12 max-w-screen-2xl mx-auto">
          {/* Image */}
          <div className="w-full md:w-[55%] flex mb-6 md:mb-0">
            <img
              src="/assets/images/aiSummary.png"
              alt="AI Summarization"
              className="rounded-lg w-full h-[auto] object-cover"
            />
          </div>

          {/* Text */}
          <div className="w-full md:w-[45%] p-4 md:p-6">
            <span className="bg-gray-200 text-sm md:text-lg px-4 py-2 rounded-3xl">
              Collaboration and Community
            </span>
            <h2 className="text-2xl md:text-4xl text-black font-bold mt-4 mr-0 md:mr-10 mb-2 mt-2">
              Achieve More Together
            </h2>
            <div className="flex items-center mb-2">
              <label className="text-sm md:text-md font-inter inline-flex">
                <div className="w-4 h-4 mr-2 mt-1 bg-white border border-black rounded-full"></div>
                Mind-Mapping: Collaborate on ideas in real-time.
              </label>
            </div>
            <div className="flex items-center mb-2">
              <label className="text-sm md:text-md font-inter inline-flex">
                <div className="w-4 h-4 mr-2 mt-1 bg-white border border-black rounded-full"></div>
                Debate Platform: Engage in structured, AI-moderated discussions.
              </label>
            </div>
            <div className="flex items-center mb-2">
              <label className="text-sm md:text-md font-inter inline-flex">
                <div className="w-4 h-4 mr-2 mt-1 bg-white border border-black rounded-full"></div>
                Study Circle: Work together in small learning groups.
              </label>
            </div>
            <div className="flex items-center mb-2">
              <label className="text-sm md:text-md font-inter inline-flex">
                <div className="w-4 h-4 mr-2 mt-1 bg-white border border-black rounded-full"></div>
                Public Learning Circles: Collaborate globally on projects and
                discussions.
              </label>
            </div>
            <button className="bg-black text-sm md:text-base text-white px-4 py-2 rounded-md hover:bg-gray-800 transition mt-4">
              Start Collaborating
            </button>
          </div>
        </div>
      </div>
      {/* Designed for Every Learner */}
      <div className="bg-gray-100 h-auto p-4 md:p-6 pt-12 md:pt-16 pb-0">
        <p className="text-center text-[#212121] text-sm md:text-base">
          WHO BENEFITS FROM SCOOLISH
        </p>
        <h1 className="text-3xl md:text-5xl font-bold text-center mb-6 md:mb-8">
          A Platform Built for Everyone
        </h1>
        <div className="grid grid-cols-1 gap-6 md:grid-cols-2 md:pl-28 md:pr-28 pt-8">
          {imageData.map((item, index) => (
            <div
              key={index}
              className="relative bg-cover bg-center rounded-lg shadow-lg overflow-hidden h-[40vh] md:h-[50vh] flex"
              style={{ backgroundImage: `url(${item.image})` }}
            >
              {/* Gradient Overlay */}
              <div className="absolute inset-0 bg-gradient-to-t from-black to-transparent"></div>

              {/* Text Content */}
              <div className="relative z-10 text-white flex flex-col justify-end p-4 md:p-6">
                <h3 className="text-lg md:text-xl font-bold">{item.title}</h3>
                <p className="text-sm md:text-md mb-2">{item.description}</p>
                <button className="text-white font-bold border border-white px-3 py-1 rounded-md self-start">
                  {item.buttonText}
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
      {/* Premium Plan */}
      <div className="bg-gray-100 h-auto p-4 pt-8 pb-8 rounded md:p-8 lg:p-32">
        <div className="flex flex-wrap bg-black rounded-xl h-auto lg:h-[55vh]">
          <div className="w-full lg:w-[55%] bg-green p-4 lg:p-16 mt-4 lg:mt-8 h-auto lg:h-[400px]">
            <h1 className="text-4xl lg:text-5xl text-left font-bold text-white mb-4 ">
              Your Journey Starts Today
            </h1>
            <p className="text-lg text-white">
              Visualize the skills you could master, time saved with AI, and the
              impact you can make—don’t wait, start your transformation now!
            </p>
            <div className="flex justify-start mt-8">
              <button className="bg-black hover:bg-gray-500 border border-white text-white font-bold py-2 px-4 rounded">
                Sign Up Free
              </button>
              <button className="bg-black hover:bg-gray-500 border border-white text-white font-bold py-2 px-4 ml-2 rounded">
                Schedule a Demo <i className="fa fa-arrow-right ml-2"></i>
              </button>
            </div>
          </div>
          <div className="w-full lg:w-[45%] bg-red p-4 lg:p-16 h-auto lg:h-[55vh]">
            <img
              src="/assets/images/home/footer-feature.png"
              alt="AI Homework Helper"
              className="w-full h-full rounded-2xl object-cover"
            />
          </div>
        </div>
      </div>
      {/* Premium Plan */}
      <Plans />
      {/* Contact Us */}
      <div className="h-auto bg-black">
        <div className="bg-black w-full h-auto px-6 py-12 md:p-16 lg:p-32">
          <div className="flex flex-col md:flex-row justify-between gap-8">
            {/* Left Section */}
            <div className="w-full md:w-[50%] lg:w-[40%] bg-black text-white">
              <h2 className="text-2xl md:text-4xl lg:text-5xl font-bold mt-6 md:mt-12 mb-8">
                Contact us anytime for quick assistance
              </h2>

              {/* Contact Info Items */}
              <div className="space-y-6">
                {/* Phone */}
                <div className="flex items-center gap-4">
                  <div className="bg-green-900 px-4 py-2 rounded-full">
                    <i className="text-white fa fa-phone" />
                  </div>
                  <div>
                    <p className="text-sm md:text-lg font-medium">
                      Phone Number
                    </p>
                    <p className="text-sm md:text-lg">+91-9871188346</p>
                  </div>
                </div>

                {/* Email */}
                <div className="flex items-center gap-4">
                  <div className="bg-blue-900 px-4 py-2 rounded-full">
                    <i className="text-white fa fa-envelope" />
                  </div>
                  <div>
                    <p className="text-sm md:text-lg font-medium">
                      Email Address
                    </p>
                    <p className="text-sm md:text-lg">enquire@scoolish.com</p>
                  </div>
                </div>

                {/* Address */}
                <div className="flex items-center gap-4">
                  <div className="bg-yellow-900 px-4 py-2 rounded-full">
                    <i className="text-white fa fa-map" />
                  </div>
                  <div>
                    <p className="text-sm md:text-lg font-medium">Address</p>
                    <p className="text-sm md:text-lg">
                      E310, East of Kailash, New Delhi, 110065
                    </p>
                  </div>
                </div>
              </div>

              {/* Social Links */}
              <div className="mt-8">
                <p className="text-sm md:text-lg font-medium mb-4">
                  Social Links
                </p>
                <div className="flex items-center gap-4">
                  <a
                    href="#"
                    className="bg-gray-900 px-4 py-2 rounded-full hover:bg-gray-700 transition"
                  >
                    <i className="text-white text-xl fa-brands fa-facebook" />
                  </a>
                  <a
                    href="https://www.instagram.com/scoolish_/"
                    target="_blank"
                    className="bg-gray-900 px-4 py-2 rounded-full hover:bg-gray-700 transition"
                  >
                    <i className="text-white text-xl fa-brands fa-instagram" />
                  </a>
                  <a
                    href="#"
                    className="bg-gray-900 px-4 py-2 rounded-full hover:bg-gray-700 transition"
                  >
                    <i className="text-white text-xl fa-brands fa-x" />
                  </a>
                  <a
                    href="#"
                    className="bg-gray-900 px-4 py-2 rounded-full hover:bg-gray-700 transition"
                  >
                    <i className="text-white text-xl fa-brands fa-linkedin" />
                  </a>
                </div>
              </div>
            </div>

            {/* Right Section */}
            <div className="w-full md:w-[50%] bg-white text-black p-6 md:p-8 rounded-lg shadow-md">
              <form className="space-y-6">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                  {/* Full Name */}
                  <div>
                    <label className="block text-sm md:text-lg font-medium text-gray-700">
                      Full Name
                    </label>
                    <input
                      type="text"
                      placeholder="Enter Name"
                      className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-black focus:border-black"
                    />
                  </div>

                  {/* Email */}
                  <div>
                    <label className="block text-sm md:text-lg font-medium text-gray-700">
                      Email
                    </label>
                    <input
                      type="email"
                      placeholder="Example@gmail.com"
                      className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-black focus:border-black"
                    />
                  </div>

                  {/* Phone */}
                  <div>
                    <label className="block text-sm md:text-lg font-medium text-gray-700">
                      Phone Number
                    </label>
                    <input
                      type="text"
                      placeholder="+90 20260 000"
                      className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-black focus:border-black"
                    />
                  </div>

                  {/* Subject */}
                  <div>
                    <label className="block text-sm md:text-lg font-medium text-gray-700">
                      Subject
                    </label>
                    <input
                      type="text"
                      placeholder="Type your subject"
                      className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-black focus:border-black"
                    />
                  </div>
                </div>

                {/* Message */}
                <div>
                  <label className="block text-sm md:text-lg font-medium text-gray-700">
                    Message
                  </label>
                  <textarea
                    placeholder="Enter Message"
                    rows="6"
                    className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-black focus:border-black"
                  ></textarea>
                </div>

                {/* Submit Button */}
                <div className="flex justify-start">
                  <button
                    type="submit"
                    className="bg-black text-white px-6 py-2 rounded-lg shadow hover:bg-gray-800 transition"
                  >
                    Submit Message
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Home;

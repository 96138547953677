import React, { useState } from "react";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import CardCarousel from "../../components/CardCarousel";
import FeatureHeroSection from "../../components/FeatureHeroSection";
import FeatureHeader from "../../components/FeatureHeader";
import FeatureUpload from "../../components/FeatureUpload";
import FeatureFooter from "../../components/FeatureFooter";
import FeatureBenefitsType1 from "../../components/FeatureBenefitsType1";
import FAQSection from "../../components/FAQSection";

const topSectionData = {
  title: "Divide and Conquer with AI-Powered Segmentation",
  subtitle: "",
  description:
    "Break down complex content into meaningful sections, chapters, or themes effortlessly. Gain a clear structure and deeper insights at scale.",
  ctaText: "Try Segmentation Now",
  ctaText2: "Explore More Features",
  image: "/assets/images/segmentation2.png",
  altText: "Segmentation",
};

const heroSectionData = {
  title: "What is Segmentation?",
  description:
    "Segmentation divides long-form content into smaller, coherent parts based on themes, topics, or sections. This feature helps you organize, analyze, and utilize data more effectively across various industries and formats, such as text, video, and audio.",
  radioOptions: [
    {
      id: "clarity",
      label: "Enhanced Clarity: Understand content in digestible segments.",
    },
    {
      id: "org",
      label:
        "Improved Organization: Easily locate and focus on specific sections.",
    },
    {
      id: "versatile",
      label:
        "Versatility: Supports multiple formats like documents, videos, and audio.",
    },
    {
      id: "productivity",
      label:
        "Automated Workflow: Streamline analysis by cutting manual effort.",
    },
  ],
  image: "/assets/images/cars.png",
  altText: "Segmentation",
};

const features = [
  {
    title: "3D Model Builder",
    content: `Allows Students To Design And Visualize 3D Models For Projects.
        <br />
        Key Features:
        <ul style={{ listStyle: "disc", color: "black" }}>
          <li style={{ color: "black" }}> • Intuitive Editor For Adding Shapes, Textures, And Animations.</li>
          <li style={{ color: "black" }}> • Tools For Rotating, Scaling, And Exporting Models.</li>
        </ul>`,
  },
  {
    title: "Virtual Science Lab",
    content: "Explore scientific experiments virtually with interactive tools.",
  },
  {
    title: "Historical Timeline Builder",
    content: "Build dynamic timelines to visualize historical events.",
  },
  {
    title: "Collaborative Mind Mapping",
    content: "Collaborate on brainstorming ideas using interactive mind maps.",
  },
];

const cardsData = [
  {
    image: "/assets/images/segmentation3.png",
    title: "Text Segmentation",
    description:
      "What It Does: Divides documents into chapters, sections, or paragraphs based on logical or thematic breaks.",
    features: [
      "Breaking down reports into sections for analysis.",
      "Organizing notes into well-defined topics.",
    ],
  },
  {
    image: "/assets/images/segmentation4.png",
    title: "Audio Segmentation",
    description:
      "What It Does: Divides audio files into segments based on pauses, speakers, or topics.",
    features: [
      "Speaker diarization in interviews or podcasts.",
      "Extracting specific sections from webinars or meetings.",
    ],
  },
  {
    image: "/assets/images/segmentation5.png",
    title: "Video Segmentation",
    description:
      "What It Does: Identifies and labels key scenes or topics in video content.",
    features: [
      "Creating scene-by-scene highlights for movies or lectures.",
      "Tagging thematic sections in marketing or training videos.",
    ],
  },
  {
    image: "/assets/images/segmentation6.png",
    title: "Topic-Based Segmentation",
    description:
      "What It Does: Organizes content by grouping similar ideas or topics into sections.",
    features: [
      "Research papers divided by themes",
      "Breaking down customer feedback by common issues.",
    ],
  },
];

const uploadData = {
  headerSubtitle: "How It Works",
  headerTitle: "How Does Segmentation Work?",
  image: "/assets/images/segs.png",
  altText: "Fun Learning",
  steps: [
    {
      title: "Upload Your File",
      description: "Add text, audio, or video for segmentation.",
    },
    {
      title: "Select Segmentation Type",
      description: "Select topic-based, time-based, or custom segmentation.",
    },
    {
      title: "View Segments",
      description:
        "Instantly see structured content with labels and timestamps.",
    },
    {
      title: "Export Or Share",
      description: "Download segmented content or refine it as needed.",
    },
  ],
};

const featureType1Data = {
  headerTitle: "Who Benefits from Segmentation?",
  headerDescription:
    "Unlock a world of possibilities with Scoolish’s AI-powered tools, designed to make learning interactive, creative, and fun. From homework assistance to 3D modeling and virtual labs, every tool is crafted to inspire and empower young minds.",
  bannerImage: "/assets/images/sun.png",
  bannerAltText: "Focused Group",
  bannerTitle: "Benefit From Segmentation",
  bannerCtaText: "Get in Touch",
  cardsData: [
    {
      title: "Media Professionals",
      description:
        "Segment podcasts, interviews, or news reports into highlight reels.",
      icon: "fa-solid fa-location-crosshairs text-3xl",
    },
    {
      title: "Researchers",
      description:
        "Divide research materials into thematic sections for easy review.",
      icon: "fa-solid fa-location-crosshairs text-3xl",
    },
    {
      title: "Students & Educators",
      description:
        "Organize study material or lectures into topic-specific sections.",
      icon: "fa-solid fa-location-crosshairs text-3xl",
    },
    {
      title: "Marketing Teams",
      description:
        "Analyze video or audio content for insights and key themes.",
      icon: "fa-solid fa-location-crosshairs text-3xl",
    },
    {
      title: "Legal Professionals",
      description: "Break contracts or case files into manageable clauses.",
      icon: "fa-solid fa-location-crosshairs text-3xl",
    },
  ],
};

const questions = [
  {
    title: "What Types Of Files Are Supported?",
    content: "We support text, Word documents, PDFs, and more.",
  },
  {
    title: "How Accurate Is The Summary?",
    content:
      "Our summaries aim for high accuracy, though results may vary depending on the content provided.",
  },
  {
    title: "Can I Customize The Length Of The Summary?",
    content: "Yes, you can customize the length to suit your needs.",
  },
  {
    title: "Collaborative Mind Mapping",
    content:
      "Work with others in real-time to create structured summaries and ideas.",
  },
];

const footerData = {
  title: "Ready to Transform Your Content?",
  description:
    "At Scoolish, we believe in the synergy between human creativity and artificial intelligence. Our mission is to empower users to harness AI as a supportive tool that complements their unique skills and insights, fostering independence and originality in their projects.",
  ctaText1: "Try Segmentation Now",
  ctaText2: "Sign Up for Scoolish",
  image: "/assets/images/future2.png",
  altText: "Future Learning",
  className: "pt-18",
};

function Segmentation() {
  const [openIndex, setOpenIndex] = useState(0);
  return (
    <>
      <Navbar />
      <FeatureHeader data={topSectionData} />
      <FeatureHeroSection data={heroSectionData} />
      <div className="bg-gray-100 h-[60%] pl-4 pr-4 text-left pt-0">
        <CardCarousel cardsData={cardsData} buttonText={null} />
      </div>
      <FeatureUpload data={uploadData} />
      <div
        className="bg-cover bg-center bg-no-repeat h-screen w-full "
        style={{ backgroundImage: "url('/assets/images/background.png')" }}
      >
        <FeatureBenefitsType1 data={featureType1Data} />
      </div>
      <div className="p-16 bg-gray-100">
        {/* Header Section */}
        <div className="flex justify-between items-center">
          <div className="flex-1">
            <h1 className="text-4xl text-center text-poppins font-bold text-gray-900 mb-4">
              Try Segmentation in Action!
            </h1>
            <p className="text-[#212121] text-center text-xl mb-6">
              {" "}
              Discover How Scoollish Features Simplify Text Analysis, Enhance
              Understanding, <br />
              and Unlock Hidden Insights for Smarter Decisions
            </p>
          </div>
        </div>

        <div className="px-24">
          <div className="p-3 bg-white rounded-lg shadow-md">
            {" "}
            <img
              src="/assets/images/building.png"
              className="w-full h-[85vh] object-cover rounded-md"
              alt="Segmentation"
            />
          </div>
        </div>
      </div>
      <FAQSection questions={questions} />
      <FeatureFooter data={footerData} />
      <div className=" bg-gray-100 pt-24 "></div>
      <Footer />
    </>
  );
}

export default Segmentation;

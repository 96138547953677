import React from "react";

const FeatureHeroSection = ({ data }) => {
  return (
    <div className="bg-gray-100 w-full h-[85vh] p-24 pb-0 pt-16">
      <div className="flex flex-col md:flex-row items-center p-6 justify-between">
        <div className="w-full md:w-1/2 mb-6 md:mb-0 px-8">
          <h3 className="text-4xl font-semibold mb-2 mt-1 text-left text-inner">
            {data.title}
          </h3>
          <p className="text-md mb-8 text-left">{data.description}</p>
          {data.radioOptions.map((option, index) => (
            <div key={index} className="flex items-center mb-2">
              <input
                type="radio"
                name="solution-type"
                id={option.id}
                className="mr-2"
              />
              <label
                htmlFor={option.id}
                className="text-md font-inter text-left"
              >
                {option.label}
              </label>
            </div>
          ))}
        </div>
        <div className="w-full md:w-1/2 p-8">
          <img
            src={data.image}
            alt={data.altText}
            className="w-full h-[50vh] object-cover rounded-xl"
          />
        </div>
      </div>
    </div>
  );
};

export default FeatureHeroSection;

import React from "react";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import CardCarousel from "../../components/CardCarousel";
import FeatureHeader from "../../components/FeatureHeader";
import FeatureHeroSection from "../../components/FeatureHeroSection";
import SubFeatures from "../../components/SubFeatures";
import FeatureFooter from "../../components/FeatureFooter";
import FeatureUpload from "../../components/FeatureUpload";
import FeatureBenefitsType2 from "../../components/FeatureBenefitsType2";
import FAQSection from "../../components/FAQSection";

const topSectionData = {
  title: "Trace Timelines, Discover Insights with Chronology",
  subtitle: "",
  description:
    "Visualize events, trends, and patterns over time with Scoolish’s Chronology feature. Perfect for researchers, students, and professionals who seek clarity in temporal data.",
  ctaText: "Try Chronology Now",
  ctaText2: "Learn More",
  image: "/assets/images/chronology1.png",
  altText: "AI Homework Helper",
};

const heroSectionData = {
  title: "What is Chronology?",
  description:
    "Chronology is the art of organizing events in their temporal sequence. With Scoolish, you can transform disorganized data into meaningful narratives, unlocking insights from historical trends, project timelines, or evolving datasets.",
  radioOptions: [
    {
      id: "organize",
      label: "Organize and analyze time-bound data effortlessly.",
    },
    {
      id: "identify",
      label: "Identify key moments, trends, and changes over time.",
    },
    {
      id: "narrative",
      label:
        "Create compelling narratives and visualizations for presentations or research.",
    },
  ],
  image: "/assets/images/chronology2.JPG",
  altText: "Chronology",
};

const cardsData = [
  {
    image: "/assets/images/chrono1.png",
    title: "Dynamic Timelines",
    description:
      "Generate timelines that adjust dynamically as new data is added.",
    features: null,
  },
  {
    image: "/assets/images/chrono2.png",
    title: "Trend Analysis",
    description:
      "Identify patterns and correlations within temporal data for actionable insights.",
    features: null,
  },
  {
    image: "/assets/images/chrono3.png",
    title: "Event Clustering",
    description:
      "Group similar events for clarity and better data storytelling.",
    features: null,
  },
  {
    image: "/assets/images/chrono4.png",
    title: "Granular Date Control",
    description:
      "Zoom in for day-by-day details or zoom out to see trends across decades.",
    features: null,
  },
];

const subFeaturesData = [
  {
    title: "Multi-Dimensional Event Mapping",
    description:
      "Plot events with multiple dimensions, such as geographical location or category, for richer insights.",
    icon: "fa fa-language",
  },
  {
    title: "Predictive Insights",
    description:
      "Leverage historical data to forecast future trends and changes.",
    icon: "fa fa-keyboard",
  },
  {
    title: "Annotation and Notes",
    description:
      "Add context to events with customizable annotations and linked resources.",
    icon: "fa fa-rainbow ",
  },
  {
    title: "Real-Time Updates",
    description:
      "Automatically refresh timelines with real-time data feeds, ensuring you’re always up to date.",
    icon: "fa-regular fa-clipboard",
  },
  {
    title: "Export and Share",
    description:
      "Export timelines in multiple formats (PDF, PNG, or interactive web embeds) for seamless sharing.",
    icon: "fa fa-clock-rotate-left",
  },
];

const uploadData = {
  headerSubtitle: "How It Works",
  headerTitle: "How Does Chronology Work?",
  image: "/assets/images/segs.png",
  altText: "Fun Learning",
  steps: [
    {
      title: "Upload Your Content",
      description: "Upload your dataset with dates and events.",
    },
    {
      title: "Choose visualization",
      description: "Choose your visualization preferences and timeline range.",
    },
    {
      title: "Key Filter",
      description: "Highlight key events with annotations and filters.  ",
    },
    {
      title: "Analyze Timeline",
      description: "Analyze, customize, and share your timeline.",
    },
  ],
};

const featureType2Data = {
  headerTitle: "Who Benefits from Chronology?",
  headerDescription: null,
  cardsData: [
    {
      title: "For Educators",
      description:
        "Create vivid timelines to engage students and illustrate historical events.",
      icon: "fa-solid fa-location-crosshairs text-3xl",
    },
    {
      title: "For Businesses",
      description:
        "Identify customer pain points and preferences from reviews and survey feedback.",
      icon: "fa-solid fa-location-crosshairs text-3xl",
    },
    {
      title: "For Researchers",
      description:
        "Analyze temporal relationships and patterns in scientific or historical datasets.",
      icon: "fa-solid fa-location-crosshairs text-3xl",
    },
    {
      title: "Content Creators",
      description:
        "Design captivating timelines to enhance storytelling in blogs, videos, or presentations.",
      icon: "fa-solid fa-location-crosshairs text-3xl",
    },
  ],
};

const questions = [
  {
    title: "What Types Of Files Are Supported?",
    content: "We support text, Word documents, PDFs, and more.",
  },
  {
    title: "How Accurate Is The Summary?",
    content:
      "Our summaries aim for high accuracy, though results may vary depending on the content provided.",
  },
  {
    title: "Can I Customize The Length Of The Summary?",
    content: "Yes, you can customize the length to suit your needs.",
  },
  {
    title: "Collaborative Mind Mapping",
    content:
      "Work with others in real-time to create structured summaries and ideas.",
  },
];

const footerData = {
  title: "Ready to Get Started",
  description:
    "A vibrant image of a user presenting a detailed timeline on a large screen to a captivated audience.",
  ctaText1: "Try Chronology",
  ctaText2: "Sign Up for Scoolish",
  image: "/assets/images/chrono5.png",
  altText: "Future Learning",
  className: "pt-24",
};

function Chronology() {
  return (
    <>
      <Navbar />
      <FeatureHeader data={topSectionData} />
      <FeatureHeroSection data={heroSectionData} />
      <div className="bg-gray-100 h-[60%] text-left pt-0 pb-16">
        <CardCarousel
          cardsData={cardsData}
          buttonText={null}
          heading={"Chronology"}
        />
      </div>
      <SubFeatures features={subFeaturesData} heading={"Chronology"} />
      <FeatureUpload data={uploadData} />
      <div
        className="bg-cover bg-center bg-no-repeat h-[65vh] w-full"
        style={{ backgroundImage: "url('/assets/images/background.png')" }}
      >
        <FeatureBenefitsType2 data={featureType2Data} />
      </div>
      <FAQSection questions={questions} />
      <FeatureFooter data={footerData} />
      <div className=" bg-gray-100 pt-24 "></div>
      <Footer />
    </>
  );
}

export default Chronology;

import React from "react";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import FeatureFooter from "../../components/FeatureFooter";

const footerData = {
  title: "Join Us on Our Journey",
  description:
    "Bring your imagination to life with stunning visuals and captivating storytelling. Transform your ideas into vibrant comics and share your unique narratives with the world. Start creating today and turn your stories into art that inspires, entertains, and leaves a lasting impact!",
  ctaText1: "Download the App",
  ctaText2: "Learn More",
  image: "/assets/images/ourPhilosophy2.png",
  altText: "Fun Learning",
  className: "pt-15 ",
};

function Philosophy() {
  return (
    <>
      <Navbar />
      {/* Our Philosophy */}
      <div className="bg-black w-full h-screen p-6">
        <div className="flex flex-col md:flex-row items-center bg-black mt-24 pt-14 pr-20 pl-20 pb-10 justify-between">
          <div className="w-full md:w-1/2 mb-6 md:mb-0 px-2">
            <h3 className="text-5xl text-white font-bold mb-2">
              Our Philosophy: The Vision Behind Scoolish
            </h3>
            <p className="text-lg mb-4 text-white pr-10">
              "Discover the passion and principles that guide our journey in
              creating a transformative experience for our users."
            </p>
            <button className="bg-white hover:bg-gray-300 font-bold text-lg py-3 px-6 rounded-lg mt-6 mr-4">
              Start Creating Your Comic Now
            </button>
            <button className="bg-black text-white border-2 border-white hover:bg-gray-300 font-bold text-lg py-2.5 px-16 rounded-lg mt-6 mr-4">
              Learn More
            </button>
          </div>
          <div className="w-full md:w-1/2 pl-6">
            <img
              src="/assets/images/ourPhilosophy.png"
              alt="Our Philosophy"
              className="w-full h-auto rounded-xl"
            />
          </div>
        </div>
      </div>
      {/* Meet the Visionaries */}
      <div className="bg-gray-100 w-full h-auto text-center p-32 pt-16 pb-16">
        <h1 className="text-SM font-inter font mb-2">MEET THE VISIONARIES</h1>
        <h2 className="text-5xl font-inter font-bold mb-2">
          Meet the Minds Behind Scoolish
        </h2>
        <div className="flex flex-col md:flex-row items-center  p-6 px-10 justify-between mt-10">
          <div className="w-full md:w-1/2">
            <img
              src="/assets/images/dev2.png"
              alt="AI Friend"
              className="w-[80%] h-[60vh] rounded-xl"
            />
          </div>
          <div className="w-full md:w-1/2 mb-6 md:mb-0 px-6">
            <h1 className="text-SM font-inter text-left font mb-2">
              Co-Founder & Chief Visionary
            </h1>
            <h3 className="text-3xl font-bold mb-2 text-left text-jusitfy">
              Malika Tinfou
            </h3>
            <p className="text-lg text-justify mr-6">
              The Interactive Quiz Creator is a powerful tool that allows
              educators and learners to create, customize, and share quizzes
              effortlessly. With a user-friendly interface and a variety of
              question types, you can enhance the learning experience and assess
              knowledge effectively.
              <br />
              <br /> With a user-friendly interface and a variety of question
              types, you can enhance the learning experience and assess
              knowledge effectively.
            </p>
            <div className="flex items-left">
              <button className="hover:bg-gray-500 text-black font-bold py-4 px-12 border border-black rounded-lg mt-6 items-left">
                <i className="fab fa-linkedin mr-2"></i>
                Connect to Linkedin
              </button>
            </div>
          </div>
        </div>
        <div className="flex flex-col md:flex-row items-center  p-6 px-10 justify-between mt-10">
          <div className="w-full md:w-1/2 mb-6 md:mb-0 px-6">
            <h1 className="text-SM font-inter text-left font mb-2">
              CTO & Lead Developer
            </h1>
            <h3 className="text-3xl font-bold mb-2 text-left text-jusitfy">
              Novneet Patnaik
            </h3>
            <p className="text-lg text-justify mr-6">
              The Interactive Quiz Creator is a powerful tool that allows
              educators and learners to create, customize, and share quizzes
              effortlessly. With a user-friendly interface and a variety of
              question types, you can enhance the learning experience and assess
              knowledge effectively. <br />
              <br />
              With a user-friendly interface and a variety of question types,
              you can enhance the learning experience and assess knowledge
              effectively.
            </p>
            <div className="flex items-left">
              <button className="hover:bg-gray-500 text-black font-bold py-4 px-12 border border-black rounded-lg mt-6 items-left">
                <i className="fab fa-linkedin mr-2"></i>
                Connect to Linkedin
              </button>
            </div>
          </div>

          <div className="w-full md:w-1/2">
            <img
              src="/assets/images/dev1.png"
              alt="AI Friend"
              className="w-full h-[60vh] object-cover rounded-xl"
            />
          </div>
        </div>
      </div>
      {/* Core Values */}
      <div className="bg-black h-[70vh] p-32 pt-10 pb-10">
        <div className="flex justify-center items-center mb-10">
          <h1 className="text-5xl font-bold text-white mb-0">
            Our Core Values
          </h1>
        </div>

        <div className="flex gap-6">
          <div className="w-full h-[45vh] bg-white rounded-xl p-4">
            <img
              src="/assets/images/core1.png"
              className="w-full h-[35vh] px-20"
              alt="Our Philosophy"
            />
            <h2 className="text-2xl font-bold text-black mt-4 flex justify-center">
              {" "}
              Access To Everyone
            </h2>
          </div>
          <div className="w-full h-[45vh] bg-white rounded-xl">
            <div className="w-full h-[45vh] bg-white rounded-xl p-4">
              <img
                src="/assets/images/core2.png"
                className="w-full h-[35vh] px-20"
                alt="Our Philosophy"
              />
              <h2 className="text-2xl font-bold text-black mt-4 flex justify-center">
                {" "}
                AI Understanding
              </h2>
            </div>
          </div>
          <div className="w-full h-[45vh] bg-white rounded-xl">
            <div className="w-full h-[45vh] bg-white rounded-xl p-4">
              <img
                src="/assets/images/core3.png"
                className="w-full h-[35vh] px-20"
                alt="Our Philosophy"
              />
              <h2 className="text-2xl font-bold text-black mt-4 flex justify-center">
                {" "}
                Ethical AI
              </h2>
            </div>
          </div>
        </div>
      </div>
      {/* Philosophy in Action */}
      <div className="bg-gray-100 w-full h-auto text-center p-32 pt-16 pb-16">
        <h1 className="text-SM font-inter font mb-2">
          THE PHILOSOPHY IN ACTION
        </h1>
        <h2 className="text-5xl font-inter font-bold mb-2">
          Our Philosophy in Action
        </h2>
        <p className="text-2xl mb-16">
          Discover How Scoollish Features Simplify Text Analysis, Enhance
          Understanding, <br />
          and Unlock Hidden Insights for Smarter Decisions
        </p>

        <div className="flex flex-col md:flex-row items-center bg-white  p-6 px-10 justify-between border border-[#5556D133] rounded-3xl mt-10">
          <div className="w-full md:w-1/2 mb-6 md:mb-0 px-6">
            <h3 className="text-3xl font-bold mb-2 text-left text-jusitfy">
              AI is like a helpful friend
            </h3>
            <p className="text-lg text-left">
              AI is like a helpful friend that can assist us in learning and
              solving problems. Just as we ask our friends for help with
              homework or to play games, we can use AI to find information,
              practice skills, and explore new ideas. It’s important to remember
              that while AI can provide support, we should always think for
              ourselves and use our creativity to make the best choices.
            </p>
            <div className="flex items-left">
              <button className="bg-black hover:bg-gray-500 text-white font-bold py-4 px-12 rounded-lg mt-6 items-left">
                Explore Now
              </button>
            </div>
          </div>

          <div className="w-full md:w-1/2">
            <img
              src="/assets/images/action1.png"
              alt="AI Friend"
              className="w-full h-[60vh] object-cover rounded-xl"
            />
          </div>
        </div>

        <div className="flex flex-col md:flex-row items-center bg-white  p-6 px-10 justify-between border border-[#5556D133] rounded-3xl mt-10">
          <div className="w-full md:w-1/2">
            <img
              src="/assets/images/action2.png"
              alt="AI Tool"
              className="w-full h-[60vh] object-cover rounded-xl"
            />
          </div>

          <div className="w-full md:w-1/2 mb-6 md:mb-0 px-6">
            <h3 className="text-3xl font-bold mb-2 text-left text-jusitfy">
              AI is a tool that works best
            </h3>
            <p className="text-lg text-left">
              AI is a tool that works best when we team up with it. Just like in
              sports or group projects, where everyone has a role to play, AI
              can help us analyze data, understand patterns, and make decisions.
              By working together with AI, we can achieve amazing things, but we
              must also be responsible and ensure that we use it in ways that
              are fair and kind to everyone.
            </p>
            <div className="flex items-left">
              <button className="bg-black hover:bg-gray-500 text-white font-bold py-4 px-12 rounded-lg mt-6 items-left">
                Explore Now
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* Contact Us */}
      <div className="bg-black w-full h-auto p-32 pt-16 pb-16 ">
        <div className="flex flex-col md:flex-row justify-between p-4">
          {/* Left Section */}
          <div className="w-[40%]  bg-black text-white">
            <h2 className="text-5xl text-left font-bold mt-12 mb-12">
              Contact us anytime for quick assistance
            </h2>
            {/* Phone Number */}
            <div className="flex items-center gap-4 mb-6">
              <div className="bg-green-900 px-4 py-2.5 rounded-full">
                <i className="text-white fa fa-phone" />
              </div>
              <div>
                <p className="text-lg font-medium">Phone Number</p>
                <p className="text-lg">+1-415-735-4966</p>
              </div>
            </div>

            {/* Email Address */}
            <div className="flex items-center gap-4 mb-6">
              <div className="bg-blue-900 px-4 py-2.5 rounded-full">
                <i className="text-white fa fa-envelope" />
              </div>
              <div>
                <p className="text-lg font-medium">Email Address</p>
                <p className="text-lg">enquire@scoolish.com</p>
              </div>
            </div>

            {/* Address */}
            <div className="flex items-center gap-4 mb-6">
              <div className="bg-yellow-900 px-4 py-2.5 rounded-full">
                <i className="text-white fa fa-map" />
              </div>
              <div>
                <p className="text-lg font-medium">Address</p>
                <p className="text-lg">
                  E310, East of Kailash, New Delhi, 110065
                </p>
              </div>
            </div>

            {/* Social Links */}
            <div>
              <p className="text-lg font-medium mb-4">Social Links</p>
              <div className="flex items-center gap-4">
                <a
                  href="#"
                  className="bg-gray-900 px-4 py-2.5 rounded-full hover:bg-gray-700 transition"
                >
                  <i className="text-white text-xl fa-brands fa-facebook" />
                </a>
                <a
                  href="https://www.instagram.com/scoolish_/"
                  target="_blank"
                  className="bg-gray-900 px-4 py-2.5 rounded-full hover:bg-gray-700 transition"
                >
                  <i className="text-white text-xl fa-brands fa-instagram" />
                </a>
                <a
                  href="#"
                  className="bg-gray-900 px-4 py-2.5 rounded-full hover:bg-gray-700 transition"
                >
                  <i className="text-white text-xl fa-brands fa-x" />
                </a>
                <a
                  href="#"
                  className="bg-gray-900 px-4 py-2.5 rounded-full hover:bg-gray-700 transition"
                >
                  <i className="text-white text-xl fa-brands fa-linkedin" />
                </a>
              </div>
            </div>
          </div>
          {/* Right Section */}
          <div className="w-[50%] p-8 rounded-2xl bg-white text-black">
            <form className="w-full max-w-3xl  bg-white ">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                {/* Full Name */}
                <div>
                  <label className="block text-lg font-medium text-gray-700">
                    Full Name
                  </label>
                  <input
                    type="text"
                    placeholder="Enter Name"
                    className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-black focus:border-black"
                  />
                </div>

                {/* Email */}
                <div>
                  <label className="block text-lg font-medium text-gray-700">
                    Enter Email
                  </label>
                  <input
                    type="email"
                    placeholder="Example@gmail.com"
                    className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-black focus:border-black"
                  />
                </div>

                {/* Phone Number */}
                <div>
                  <label className="block text-lg font-medium text-gray-700">
                    Phone Number
                  </label>
                  <input
                    type="text"
                    placeholder="+90 20260 000"
                    className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-black focus:border-black"
                  />
                </div>

                {/* Subject */}
                <div>
                  <label className="block text-lg font-medium text-gray-700">
                    Subject
                  </label>
                  <input
                    type="text"
                    placeholder="Type your subject"
                    className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-black focus:border-black"
                  />
                </div>
              </div>

              {/* Message */}
              <div className="mt-6">
                <label className="block text-lg font-medium text-gray-700">
                  Message
                </label>
                <textarea
                  placeholder="Enter Message"
                  rows="8"
                  className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-black focus:border-black"
                ></textarea>
              </div>

              {/* Submit Button */}
              <div className="mt-6 flex justify-left">
                <button
                  type="submit"
                  className="bg-black text-lg text-white px-6 py-2 rounded-lg shadow hover:bg-gray-800 transition"
                >
                  Submit Message
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <FeatureFooter data={footerData} />
      <div className=" bg-gray-100 pt-12 "></div>
      <Footer />
    </>
  );
}

export default Philosophy;

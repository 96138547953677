import React, { useRef } from "react";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import KeyFeatures from "../../components/KeyFeatures";
import BenefitCards from "../../components/BenefitCards";
import FeatureFooter from "../../components/FeatureFooter";

const featureData = {
  headerTitle: "Key Features of Comics Converter",
  cardsData: [
    {
      icon: "fas fa-rocket text-3xl",
      title: "Character Customization",
      description:
        "Choose from a variety of characters and backgrounds to bring your story to life.",
    },
    {
      icon: "fas fa-cogs text-3xl",
      title: "Panel Layout Options",
      description:
        "Select different comic panel layouts to best fit your narrative style.",
    },
    {
      icon: "fas fa-chart-line text-3xl",
      title: "Text and Speech Bubbles",
      description:
        "Easily add dialogue and narration with customizable text and speech bubbles.",
    },
    {
      icon: "fas fa-chart-bar text-3xl",
      title: "Artistic Filters",
      description:
        "Apply fun artistic filters to enhance the visual appeal of your comic.",
    },
    {
      icon: "fas fa-user-friends text-3xl",
      title: "Save and Share",
      description:
        "Save your comics and share them with friends and family or print them out for display.",
    },
    {
      icon: "fas fa-award text-3xl",
      title: "Visual Element",
      description:
        "An infographic displaying the sub-features as interactive elements, showcasing how users can engage with the tool.",
    },
  ],
};

const benefitsData = {
  title: "Who Benefits from Comics Converter?",
  benefits: [
    {
      image: "/assets/images/benefits1.avif",
      title: "For Teachers",
      description:
        "Design quizzes that align with your curriculum and assess student understanding effectively.",
    },
    {
      image: "/assets/images/benefits1.avif",
      title: "For Students",
      description:
        "Create self-assessment quizzes to reinforce learning and prepare for exams.",
    },
    {
      image: "/assets/images/benefits1.avif",
      title: "For Parents",
      description:
        "Provide a fun and educational activity that fosters creativity and literacy skills at home.",
    },
  ],
};

const footerData = {
  title: "Transform Your Stories into Comics Today!",
  description:
    "Bring your imagination to life with stunning visuals and captivating storytelling. Transform your ideas into vibrant comics and share your unique narratives with the world. Start creating today and turn your stories into art that inspires, entertains, and leaves a lasting impact!",
  ctaText1: "Try Comics Converter",
  image: "/assets/images/cc6.png",
  altText: "Fun Learning",
  className: "pt-15",
};

function CosmicConverter() {
  const scrollContainerRef = useRef(null);

  const scrollLeft = () => {
    scrollContainerRef.current.scrollBy({
      left: -400, // Adjust based on card width
      behavior: "smooth",
    });
  };

  const scrollRight = () => {
    scrollContainerRef.current.scrollBy({
      left: 400, // Adjust based on card width
      behavior: "smooth",
    });
  };
  return (
    <>
      <Navbar />
      <div className="bg-black w-full h-screen p-6">
        <div className="flex flex-col md:flex-row items-center bg-black mt-24 pt-14 pr-20 pl-20 pb-10 justify-between">
          <div className="w-full md:w-1/2 mb-6 md:mb-0 px-2">
            <h3 className="text-5xl text-white font-bold mb-2">
              Transform Your Stories into Comics with Story to Comics Converter
            </h3>
            <p className="text-lg mb-4 text-white pr-10">
              Unleash your creativity! Kids can easily turn their imaginative
              stories into vibrant comics, bringing their characters and
              adventures to life.
            </p>
            <button className="bg-white hover:bg-gray-300 font-bold text-lg py-3 px-6 rounded-lg mt-6 mr-4">
              Start Creating Your Comic Now
            </button>
            <button className="bg-black text-white border-2 border-white hover:bg-gray-300 font-bold text-lg py-2.5 px-16 rounded-lg mt-6 mr-4">
              Learn More
            </button>
          </div>
          <div className="w-full md:w-1/2 pl-6">
            <img
              src="/assets/images/cc.png"
              alt="AI Homework Helper"
              className="w-full h-auto rounded-xl"
            />
          </div>
        </div>
      </div>
      <div className="bg-gray-100 w-full h-[90vh] p-24 pb-0">
        <div className="flex flex-col md:flex-row items-center  p-6 justify-between">
          <div className="w-full md:w-1/2 mb-6 md:mb-0 px-2">
            <h3 className="text-3xl font-bold mb-2">
              Tool Overview “Comics Converter”
            </h3>
            <p className="text-lg text-bold mb-8 pr-10 text-justify">
              The Story to Comics Converter is an innovative tool designed for
              kids to express their creativity by converting written stories
              into visually appealing comics. With an easy-to-use interface,
              children can add text, choose characters, and customize their
              comic panels."
            </p>
            <div className="flex items-center">
              <input
                type="radio"
                name="solution-type"
                id="skills"
                className="mr-2"
              />
              <label htmlFor="skills" className="text-lg font-inter">
                Encourages creativity and storytelling skills.
              </label>
            </div>
            <div className="flex items-center">
              <input
                type="radio"
                name="solution-type"
                id="engage"
                className="mr-2"
              />
              <label htmlFor="engage" className="text-lg font-inter pr-12">
                Provides a fun and interactive way to engage with literature.
              </label>
            </div>
            <div className="flex items-center">
              <input
                type="radio"
                name="solution-type"
                id="develop"
                className="mr-2"
              />
              <label htmlFor="develop" className="text-lg font-inter pr-12">
                Helps develop visual literacy and artistic expression.
              </label>
            </div>
            <button className="bg-gray-100 border border-black hover:bg-gray-500 font-bold py-3 text-lg  px-8 rounded-xl mt-6">
              Try Now
            </button>
          </div>
          <div className="w-full md:w-1/2">
            <img
              src="/assets/images/cc2.png"
              alt="AI Homework Helper"
              className="w-full h-[60vh] rounded-xl object-cover"
            />
          </div>
        </div>
      </div>
      <KeyFeatures data={featureData} />
      <div className="bg-black h-screen p-24">
        {/* Header Section */}
        <div className="flex justify-between items-center mb-10">
          <h1 className="text-5xl font-semibold text-white text-left mb-0">
            How It Works
          </h1>
          <div className="flex">
            <button
              onClick={scrollLeft}
              className="px-4 py-2 border border-white rounded-lg hover:bg-gray-500"
            >
              <i className="fa fa-angle-left text-white"></i>
            </button>
            <button
              onClick={scrollRight}
              className="ml-6 px-4 py-2 border border-white rounded-lg hover:bg-gray-500"
            >
              <i className="fa fa-angle-right text-white"></i>
            </button>
          </div>
        </div>

        {/* Scrollable Cards Section */}
        <div
          ref={scrollContainerRef}
          className="flex space-x-6 overflow-x-scroll scroll-smooth no-scrollbar"
        >
          <div className="flex-shrink-0 w-[90%] md:w-[30%] bg-[#323232] rounded-xl p-6">
            <h3 className="text-2xl text-white font-bold mb-4">
              1. Write Your Story
            </h3>
            <div className="w-full h-[35vh] bg-[#D9D9D9] rounded-xl mb-2">
              <img
                src="/assets/images/cc3.png"
                className="w-full h-full rounded-xl"
              />
            </div>
            <p className="text-md text-white">
              Start by typing or pasting your story into the converter.
            </p>
          </div>
          <div className="flex-shrink-0 w-[90%] md:w-[30%] bg-[#323232] rounded-xl p-6">
            <h3 className="text-2xl text-white font-bold mb-4">
              2. Customize Characters
            </h3>
            <div className="w-full h-[35vh] bg-[#D9D9D9] rounded-xl mb-2">
              <img
                src="/assets/images/cc4.png"
                className="w-full h-full rounded-xl "
              />
            </div>
            <p className="text-md text-white">
              Choose characters and backgrounds that match your story.
            </p>
          </div>
          <div className="flex-shrink-0 w-[90%] md:w-[30%] bg-[#323232] rounded-xl p-6">
            <h3 className="text-2xl text-white font-bold mb-4">
              3. Arrange Panels
            </h3>
            <div className="w-full h-[35vh] bg-[#D9D9D9] rounded-xl mb-2">
              <img
                src="/assets/images/cc5.png"
                className="w-full h-full rounded-xl "
              />
            </div>
            <p className="text-md text-white">
              Select a layout and arrange your comic panels.
            </p>
          </div>
          <div className="flex-shrink-0 w-[90%] md:w-[30%] bg-[#323232] rounded-xl p-6">
            <h3 className="text-2xl text-white font-bold mb-4">4. Add Text</h3>
            <div className="w-full h-[35vh] bg-[#D9D9D9] rounded-xl mb-2"></div>
            <p className="text-md text-white">
              Insert dialogue and narration using speech bubbles and text boxes.
            </p>
          </div>
        </div>
      </div>
      <BenefitCards data={benefitsData} />
      <FeatureFooter data={footerData} />
      <div className=" bg-gray-100 pt-24 "></div>
      <Footer />
    </>
  );
}

export default CosmicConverter;

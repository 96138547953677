import React from "react";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import CardCarousel from "../../components/CardCarousel";
import FeatureHeader from "../../components/FeatureHeader";
import FeatureHeroSection from "../../components/FeatureHeroSection";
import SubFeatures from "../../components/SubFeatures";
import FeatureFooter from "../../components/FeatureFooter";
import FeatureUpload from "../../components/FeatureUpload";
import FeatureBenefitsType2 from "../../components/FeatureBenefitsType2";
import FAQSection from "../../components/FAQSection";

const topSectionData = {
  title: "Uncover Connections, Reveal Patterns with Similarity",
  subtitle: "",
  description:
    "Identify relationships and similarities in data effortlessly with Scoolish’s Similarity feature. Ideal for researchers, students, and professionals seeking deeper insights into their datasets.",
  ctaText: "Try Similarity Now",
  ctaText2: "Learn More",
  image: "/assets/images/similarity2.png",
  altText: "AI Homework Helper",
};

const heroSectionData = {
  title: "What is Similarity??",
  description:
    "Similarity is the process of identifying and analyzing relationships between data points. With Scoolish, you can transform raw data into meaningful insights, revealing patterns and connections that drive informed decision-making.",
  radioOptions: [
    {
      id: "discover",
      label: "Discover hidden relationships within your data.",
    },
    {
      id: "analyze",
      label: "Enhance data analysis with visual representations of similarity.",
    },
    {
      id: "reports",
      label:
        "Create compelling reports and presentations based on insightful comparisons.",
    },
  ],
  image: "/assets/images/similarity3.png",
  altText: "Topic Modeling",
};

const cardsData = [
  {
    image: "/assets/images/similarity4.png",
    title: "Dynamic Clustering",
    description:
      "Automatically group similar data points to simplify analysis and visualization.",
    features: null,
  },
  {
    image: "/assets/images/similarity5.jpg",
    title: "Pattern Recognition",
    description:
      " Identify recurring themes and trends within your datasets for actionable insights.",
    features: null,
  },
  {
    image: "/assets/images/similarity6.png",
    title: "Comparative Analysis",
    description:
      "Easily compare different datasets to uncover similarities and differences.",
    features: null,
  },
  {
    image: "/assets/images/similarity7.jpg",
    title: "Customizable Metrics",
    description:
      "Tailor the similarity metrics used to fit your specific analysis needs.",
    features: null,
  },
];

const subFeaturesData = [
  {
    title: "Multi-Dimensional Event Mapping",
    description:
      "Plot events with multiple dimensions, such as geographical location or category, for richer insights.",
    icon: "fa fa-language",
  },
  {
    title: "Anomaly Detection",
    description:
      "Identify outliers and unusual patterns within your data for deeper insights.",
    icon: "fa fa-keyboard",
  },
  {
    title: "Data Linking",
    description:
      "Link related data points for comprehensive analysis and storytelling.",
    icon: "fa fa-rainbow ",
  },
  {
    title: "Real-Time Similarity Updates",
    description:
      "Automatically refresh similarity metrics as new data is added, ensuring you have the latest insights.",
    icon: "fa-regular fa-clipboard",
  },
  {
    title: "Export and Share",
    description:
      "Export similarity reports in various formats (PDF, PNG, or interactive web embeds) for easy sharing.",
    icon: "fa fa-clock-rotate-left",
  },
];

const uploadData = {
  headerSubtitle: "How It Works",
  headerTitle: "How Does Similarity Feature Work?",
  image: "/assets/images/segs.png",
  altText: "Fun Learning",
  steps: [
    {
      title: "Upload Dataset",
      description: "Upload your dataset for analysis.",
    },
    {
      title: "Choose Similarity",
      description: "Choose your similarity metrics and parameters.",
    },
    {
      title: "Visualize and explore",
      description: "Visualize and explore the relationships within your data..",
    },
    {
      title: "Analyze Customize",
      description: "Analyze, customize, and share your findings.",
    },
  ],
};

const featureType2Data = {
  headerTitle: "Who Benefits from Similarity?",
  headerDescription: null,
  cardsData: [
    {
      title: "For Educators",
      description:
        "Create engaging lessons that illustrate relationships between concepts and data.",
      icon: "fa-solid fa-location-crosshairs text-3xl",
    },
    {
      title: "For Businesses",
      description:
        "Analyze customer behavior and preferences to refine marketing strategies.",
      icon: "fa-solid fa-location-crosshairs text-3xl",
    },
    {
      title: "For Researchers",
      description:
        "Identify patterns in scientific data to support hypotheses and findings.",
      icon: "fa-solid fa-location-crosshairs text-3xl",
    },
    {
      title: "For Content Creators",
      description:
        "Design compelling narratives that highlight similarities in stories or themes.",
      icon: "fa-solid fa-location-crosshairs text-3xl",
    },
  ],
};

const questions = [
  {
    title: "What Types Of Files Are Supported?",
    content: "We support text, Word documents, PDFs, and more.",
  },
  {
    title: "How Accurate Is The Summary?",
    content:
      "Our summaries aim for high accuracy, though results may vary depending on the content provided.",
  },
  {
    title: "Can I Customize The Length Of The Summary?",
    content: "Yes, you can customize the length to suit your needs.",
  },
  {
    title: "Collaborative Mind Mapping",
    content:
      "Work with others in real-time to create structured summaries and ideas.",
  },
];

const footerData = {
  title: "Reveal the Power of Similarity with Scoolish",
  description:
    "A vibrant image of a user presenting a detailed timeline on a large screen to a captivated audience.",
  ctaText1: "Try Similarity",
  ctaText2: "Sign Up for Scoolish",
  image: "/assets/images/simi.png",
  altText: "Future Learning",
  className: "pt-18",
};

function Similarity() {
  return (
    <>
      <Navbar />
      <FeatureHeader data={topSectionData} />
      <FeatureHeroSection data={heroSectionData} />
      <div className="bg-gray-100 h-[60%] text-left pt-0 pb-16">
        <CardCarousel cardsData={cardsData} buttonText={null} />
      </div>
      <SubFeatures features={subFeaturesData} heading={"Similarity"} />
      <FeatureUpload data={uploadData} />
      <div
        className="bg-cover bg-center bg-no-repeat h-[65vh] w-full"
        style={{ backgroundImage: "url('/assets/images/background.png')" }}
      >
        <FeatureBenefitsType2 data={featureType2Data} />
      </div>
      <FAQSection questions={questions} />
      <FeatureFooter data={footerData} />
      <div className=" bg-gray-100 pt-24 "></div>
      <Footer />
    </>
  );
}

export default Similarity;

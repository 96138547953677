import React from "react";
import BenefitCard from "./BenefitCard";

function BenefitCards({ data }) {
  const { title, benefits } = data;
  return (
    <div className="bg-gray-100 h-[80vh] p-6 pt-16">
      <h1 className="text-5xl font-bold text-center mb-8">{title}</h1>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6 pl-32 pr-32 pt-8">
        {benefits.map((benefit, index) => (
          <BenefitCard
            key={index}
            image={benefit.image}
            title={benefit.title}
            description={benefit.description}
          />
        ))}
      </div>
    </div>
  );
}

export default BenefitCards;

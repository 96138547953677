import React, { useState } from "react";
import Sidebar from "../../components/Sidebar";

const Dashboard = () => {
  const [isDarkMode, setIsDarkMode] = useState(false);

  const toggleDarkMode = () => {
    setIsDarkMode(!isDarkMode);
  };

  return (
    <div
      className={`flex font-inter bg-${isDarkMode ? "black" : "white"} text-${
        isDarkMode ? "white" : "gray-600"
      } h-screen`}
    >
      <Sidebar isDarkMode={isDarkMode} toggleDarkMode={toggleDarkMode} />
      <div
        className={`w-[85%] flex-0 overflow-y-auto bg-${
          isDarkMode ? "black" : "gray-50"
        } no-scrollbar`}
      >
        <header
          className={`flex sticky top-0 pb-2 ${
            isDarkMode ? "border-b border-gray-600" : "shadow-md"
          } bg-${isDarkMode ? "black" : "white"} text-${
            isDarkMode ? "white" : "black"
          }`}
        >
          {/* Search bar */}
          <div className="flex items-left">
            <div className="flex space-x-4 ml-4 mt-1 rounded-lg border border-gray items-center flex-1">
              <span className="flex items-center">
                <button className="text-gray-400 hover:text-gray-500 p-2 rounded-l-lg">
                  <i className="fa fa-search pl-1"></i>
                </button>
                <input
                  type="text"
                  placeholder="Search Projects, features and tools"
                  className={`px-2 py-2.5 text-sm  bg-${
                    isDarkMode ? "black" : "white"
                  } placeholder-gray-400 w-[28rem]`}
                />
                <button className="text-gray-400 hover:text-gray-500 p-2 rounded-r-lg">
                  <i className="fa fa-microphone pr-1"></i>
                </button>
              </span>
            </div>
          </div>
          {/* Profile section */}
          <div className="flex ml-auto">
            <div className="flex space-x-6 text-black items-center pt-2 pl-8 pr-4 rounded-lg ">
              <div className="w-10 h-10 rounded-full flex items-center justify-center"></div>
              <img
                src="/assets/images/avatar.png"
                alt="User Avatar"
                className="w-10 h-10 rounded-full"
              />
              <span
                className={`bg-${isDarkMode ? "black" : "white"} text-${
                  isDarkMode ? "white" : "gray-600"
                }`}
              >
                Novneet <i className="fa fa-chevron-down ml-4"></i>
              </span>
            </div>
          </div>
        </header>
        {/* Main Content */}
        <main className="text-inner ">
          {/* Top Section */}
          <section className="px-5 pt-4 pb-2">
            <div className="bg-gradient-to-b from-[#6D3AFF] to-[#422399] p-8 text-gray-100 rounded-lg">
              <h2 className="text-2xl font-semibold">
                Explore the all new 3D AI Drawing Section
              </h2>
              <h2 className="text-[12px] text-gray-100">
                Ai Powered gpt models, this module will bring out your creative
                best
              </h2>
              <button className="bg-white font-bold text-black text-xs mt-4 px-6 py-2 rounded-full mr-2">
                Try It Now
              </button>
              <button className="bg-[#6633FF] font-bold text-white text-xs px-6 py-2 rounded-full">
                More Details
              </button>
            </div>
          </section>

          {/* Testing Features */}
          <section className="px-5 text-black">
            <span
              className={`text-lg font-semibold text-${
                isDarkMode ? "white" : "black"
              } `}
            >
              Start with testing Scoolish features
            </span>
            <div className="pt-1 grid grid-cols-3 gap-4">
              {/* First Button */}
              <div
                className={`flex items-center justify-center py-4 rounded-lg border ${
                  isDarkMode
                    ? "border-[#6D3AFF] bg-[#14004d]"
                    : "border-purple-300 bg-purple-50"
                }`}
              >
                <div className="flex items-center space-x-2">
                  <div
                    className={`flex items-center justify-center w-8 h-8 rounded-full ${
                      isDarkMode ? "bg-[#0e0033]" : "bg-purple-100"
                    }`}
                  >
                    <i
                      className={`fa fa-plus ${
                        isDarkMode ? "text-purple-300" : "text-purple-500"
                      }`}
                    ></i>
                  </div>
                  <h4
                    className={`font-medium ${
                      isDarkMode ? "text-white" : "text-purple-700"
                    }`}
                  >
                    Start a New Research
                  </h4>
                </div>
              </div>

              {/* Second Button */}
              <div
                className={`flex items-center justify-center py-4 rounded-lg border ${
                  isDarkMode
                    ? "border-[#663f00] bg-[#331f00]"
                    : "border-yellow-300 bg-yellow-50"
                }`}
              >
                <div className="flex items-center space-x-2">
                  <div
                    className={`flex items-center justify-center w-8 h-8 rounded-full ${
                      isDarkMode ? "bg-[#663f00]" : "bg-yellow-100"
                    }`}
                  >
                    <i
                      className={`fa fa-file-alt ${
                        isDarkMode ? "text-yellow-300" : "text-yellow-500"
                      }`}
                    ></i>
                  </div>
                  <h4
                    className={`font-medium ${
                      isDarkMode ? "text-yellow-300" : "text-yellow-700"
                    }`}
                  >
                    Create a Comic
                  </h4>
                </div>
              </div>

              {/* Third Button */}
              <div
                className={`flex items-center justify-center py-4 rounded-lg border ${
                  isDarkMode
                    ? "border-[#003066] bg-[#001933]"
                    : "border-blue-300 bg-blue-50"
                }`}
              >
                <div className="flex items-center space-x-2">
                  <div
                    className={`flex items-center justify-center w-8 h-8 rounded-full ${
                      isDarkMode ? "bg-[#00244d]" : "bg-blue-100"
                    }`}
                  >
                    <i
                      className={`fa fa-search ${
                        isDarkMode ? "text-blue-300" : "text-blue-500"
                      }`}
                    ></i>
                  </div>
                  <h4
                    className={`font-medium ${
                      isDarkMode ? "text-blue-300" : "text-blue-700"
                    }`}
                  >
                    Explore Knowledge Vault
                  </h4>
                </div>
              </div>
            </div>
          </section>

          {/* Tools Section */}
          <section className="px-5 py-1 text-black">
            <span
              className={`text-lg font-semibold text-${
                isDarkMode ? "white" : "black"
              } `}
            >
              Create
            </span>
            <div className="">
              {/* Main Content */}
              <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
                {/* First Section */}
                <div className="rounded-lg">
                  <div
                    className={`grid grid-cols-2 gap-2 ${
                      isDarkMode ? " text-white" : " text-black"
                    }`}
                  >
                    <div
                      className={`flex items-center  ${
                        isDarkMode
                          ? "bg-[#202124] border border-gray-700"
                          : "bg-white shadow-md"
                      } p-2 rounded-lg`}
                    >
                      <div className="bg-purple-500 rounded-lg">
                        <img
                          src="/assets/images/dashboard/quiz.png"
                          alt="Quiz"
                          className="h-16 w-full rounded-lg"
                        />
                      </div>
                      <p className="ml-4 font-semibold">Quiz</p>
                    </div>
                    <div
                      className={`flex items-center  ${
                        isDarkMode
                          ? "bg-[#202124] border border-gray-700"
                          : "bg-white shadow-md"
                      } p-2 rounded-lg`}
                    >
                      <div className="bg-purple-500 rounded-lg">
                        <img
                          src="/assets/images/dashboard/video.png"
                          alt="Video"
                          className="h-16 w-full rounded-lg"
                        />
                      </div>
                      <p className="ml-4 font-semibold">Video</p>
                    </div>
                    <div
                      className={`flex items-center  ${
                        isDarkMode
                          ? "bg-[#202124] border border-gray-700"
                          : "bg-white shadow-md"
                      } p-2 rounded-lg`}
                    >
                      <div className="bg-purple-500 rounded-lg">
                        <img
                          src="/assets/images/dashboard/comic.png"
                          alt="Comic"
                          className="h-16 w-full rounded-lg"
                        />
                      </div>
                      <p className="ml-4 font-semibold">Comic</p>
                    </div>
                    <div
                      className={`flex items-center  ${
                        isDarkMode
                          ? "bg-[#202124] border border-gray-700"
                          : "bg-white shadow-md"
                      } p-2 rounded-lg`}
                    >
                      <div className="bg-purple-500 rounded-lg">
                        <img
                          src="/assets/images/dashboard/others.png"
                          alt="Others"
                          className="h-16 w-full rounded-lg"
                        />
                      </div>
                      <p className="ml-4 font-semibold">Other</p>
                    </div>
                  </div>
                </div>

                {/* Second Section */}
                <div
                  className={` ${
                    isDarkMode
                      ? "bg-[#202124] border border-gray-700"
                      : "bg-white shadow-md"
                  } px-4 pb-2 pt-1 rounded-lg`}
                >
                  {/* Header */}
                  <div className="flex items-center justify-between">
                    <h2
                      className={`text-md font-semibold ${
                        isDarkMode ? "text-white" : "text-black"
                      } `}
                    >
                      Guided Templates
                    </h2>
                    <a href="#" className="text-purple-500 font-semibold">
                      View More
                    </a>
                  </div>

                  {/* Templates */}
                  <div className="grid grid-cols-3 gap-2">
                    <div className=" rounded-lg">
                      <img
                        src="/assets/images/dashboard/aiSum.png"
                        alt="Others"
                        className="h-32 w-full rounded-lg"
                      />
                    </div>
                    <div className=" rounded-lg">
                      <img
                        src="/assets/images/dashboard/aiseg.png"
                        alt="Others"
                        className="h-32 w-full rounded-lg"
                      />
                    </div>
                    <div className="rounded-lg">
                      <img
                        src="/assets/images/dashboard/topModeling.png"
                        alt="Others"
                        className="h-32 w-full rounded-lg"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          {/* Knowledge Vault */}
          <section className="px-5 py-1">
            <span
              className={`text-lg font-semibold text-${
                isDarkMode ? "white" : "black"
              } `}
            >
              Knowledge Vault
            </span>
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-2">
              {/* Left Section - Folders */}
              <div
                className={`px-2 grid grid-cols-2 border ${
                  isDarkMode
                    ? "bg-[#202124] border-gray-700 text-white"
                    : "border-gray-400 text-black"
                }   rounded-xl`}
              >
                {/* Folder Component */}
                {[
                  { name: "Research", folderCount: 25 },
                  { name: "Physics", folderCount: 25 },
                  { name: "Shared Folder", folderCount: 25 },
                  { name: "Homework", folderCount: 25 },
                ].map((folder) => (
                  <div
                    key={folder.name}
                    className="flex items-center justify-between "
                  >
                    <div className="flex">
                      <span className="text-yellow-500 text-3xl">📂</span>
                      <div className="flex flex-col ml-2">
                        <span className="text-sm font-bold">{folder.name}</span>
                        <span className="text-xs text-gray-500">
                          {folder.folderCount} Folder
                        </span>
                      </div>
                    </div>
                  </div>
                ))}
              </div>

              {/* Right Section - Upload Folder */}
              <div className="flex w-full flex-col border-dotted border-gray-400 border-2 items-center justify-center rounded-2xl px-6 py-2 w-60">
                <div
                  className={`flex items-center justify-center w-12 h-12 rounded-full ${
                    isDarkMode ? "bg-gray-600" : "bg-gray-200"
                  }`}
                >
                  <i class="fa fa-upload" aria-hidden="true"></i>
                </div>
                <p className="text-sm font-bold mt-2 text-center">
                  Upload Files
                </p>
                <p className="text-center text-gray-500">
                  On a sunny afternoon, I decided to catch up on some classic
                  films
                </p>
              </div>
            </div>
          </section>

          <section
            className={`px-5 py-2 flex text-${isDarkMode ? "white" : "black"}`}
          >
            {/* Featured Content Section */}
            <div
              className={`w-3/5 ${
                isDarkMode
                  ? "bg-[#202124] border border-gray-700"
                  : "bg-white shadow-md"
              } rounded-xl px-4 py-2`}
            >
              <div className="flex justify-between items-center mb-4">
                <h2 className="text-lg  font-semibold">Featured Content</h2>
                <span className=" font-semibold text-sm cursor-pointer">
                  Over last <span className="text-purple-600">7 days ▾</span>
                </span>
              </div>
              <div className="grid grid-cols-3 gap-4">
                <div>
                  <h3 className="pl-1 ">Quiz</h3>
                  <div className="space-y-2 mt-2">
                    <div className="flex items-center gap-2">
                      <img
                        src="/assets/images/dashboard/quiz1.png"
                        alt="Crypto Quiz"
                        className="rounded-full w-12 h-12"
                      />
                      <div>
                        <p className="text-sm font-semibold ">Crypto Quiz</p>
                        <p className="text-sm text-gray-500">All School</p>
                      </div>
                    </div>
                    <div className="flex items-center gap-2">
                      <img
                        src="/assets/images/dashboard/quiz2.png"
                        alt="Maths QuickFire"
                        className="rounded-full w-12 h-12"
                      />
                      <div>
                        <p className="text-sm font-semibold ">
                          Maths QuickFire
                        </p>
                        <p className="text-sm text-gray-500">Round 2</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div>
                  <h3 className="pl-1 ">Debates</h3>
                  <div className="space-y-2 mt-2">
                    <div className="flex items-center gap-2">
                      <img
                        src="/assets/images/dashboard/debate1.png"
                        alt="Mfers"
                        className="rounded-full w-12 h-12"
                      />
                      <div>
                        <p className="text-sm font-semibold ">Mfers</p>
                        <p className="text-sm text-gray-500">Floor price</p>
                      </div>
                    </div>
                    <div className="flex items-center gap-2">
                      <img
                        src="/assets/images/dashboard/debate2.png"
                        alt="Cool Petz"
                        className="rounded-full w-12 h-12"
                      />
                      <div>
                        <p className="text-sm font-semibold ">Cool Petz</p>
                        <p className="text-sm text-gray-500">Floor price</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div>
                  <h3 className="pl-1 ">Topics</h3>
                  <div className="space-y-2 mt-2">
                    <div className="flex items-center gap-2">
                      <img
                        src="/assets/images/dashboard/topic1.png"
                        alt="Gangsters"
                        className="rounded-full w-12 h-12"
                      />
                      <div>
                        <p className="text-sm font-semibold ">Gangsters</p>
                        <p className="text-sm text-gray-500">Floor price</p>
                      </div>
                    </div>
                    <div className="flex items-center gap-2">
                      <img
                        src="/assets/images/dashboard/topic2.png"
                        alt="Okoro Pleple"
                        className="rounded-full w-12 h-12"
                      />
                      <div>
                        <p className="text-sm font-semibold ">Okoro Pleple</p>
                        <p className="text-sm text-gray-500">Floor price</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Trending Topics Section */}
            <div
              className={`w-2/5 ${
                isDarkMode
                  ? "bg-[#202124] border border-gray-700"
                  : "bg-white shadow-md"
              } rounded-xl px-4 py-2 ml-4`}
            >
              <div className="flex justify-between items-center mb-4">
                <h2 className="text-lg  font-semibold">Trending Topics</h2>
                <span className="text-purple-600 text-sm cursor-pointer">
                  See more
                </span>
              </div>
              <div className="space-y-1">
                <div className="flex items-center justify-between">
                  <div className="flex items-center gap-2">
                    <img
                      src="/assets/images/dashboard/profile1.png"
                      alt="Pomaline Yemare"
                      className="rounded-full w-10 h-10"
                    />
                    <div>
                      <p className="font-medium">Pomaline Yemare</p>
                      <p className="text-sm text-gray-500">@Poma3</p>
                    </div>
                  </div>
                  <button className="border border-gray-500 rounded-full text-sm px-6 py-2 text-purple-600 font-semibold">
                    Following
                  </button>
                </div>
                <div className="flex items-center justify-between">
                  <div className="flex items-center gap-2">
                    <img
                      src="/assets/images/dashboard/profile2.png"
                      alt="Pomaline Sunny"
                      className="rounded-full w-10 h-10"
                    />
                    <div>
                      <p className="font-medium">Pomaline Sunny</p>
                      <p className="text-sm text-gray-500">@Poma1</p>
                    </div>
                  </div>
                  <button className="text-white text-sm bg-purple-600 rounded-full px-9 py-2">
                    Follow
                  </button>
                </div>
                <div className="flex items-center justify-between">
                  <div className="flex items-center gap-2">
                    <img
                      src="/assets/images/dashboard/profile1.png"
                      alt="Pomaline Moon"
                      className="rounded-full w-10 h-10"
                    />
                    <div>
                      <p className="font-medium">Pomaline Moon</p>
                      <p className="text-sm text-gray-500">@Poma2</p>
                    </div>
                  </div>
                  <button className="text-purple-600 border border-gray-500 rounded-full text-sm px-6 py-2 font-semibold">
                    Following
                  </button>
                </div>
              </div>
            </div>
          </section>
        </main>
      </div>
    </div>
  );
};

export default Dashboard;

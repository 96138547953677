import React from "react";
import Button from "./Button";

function Plans() {
  const plans = [
    {
      title: "Growth",
      price: "$0/",
      summary:
        "For individuals looking to explore Scoolish AI Feature and Tools.",
      containerClass: "bg-white shadow-md rounded-lg p-6 text-center flex flex-col",
      features: [
        "All Tools",
        "All Features",
        "Watermarked Outputs",
        "Limited Access Upto 100 hits",
      ],
      buttonClass: "bg-black text-white mt-auto", // Move button to the bottom
    },
    {
      title: "Basic",
      price: "$60.00/",
      summary:
        "For individuals looking for creative mix of AI features & Tools",
      containerClass:
        "bg-black border border-white text-white shadow-md rounded-lg p-6 text-center flex flex-col",
      features: ["All Tools", "All Features", "Limited Access Upto 10000 hits"],
      buttonClass: "bg-white text-black mt-auto", // Move button to the bottom
    },
    {
      title: "Scale",
      price: "$299.00/",
      summary:
        "For individuals and teams looking to create end to end artifacts.",
      containerClass: "bg-white shadow-md rounded-lg p-6 text-center flex flex-col",
      features: [
        "All Tools",
        "All Features",
        "Developer API Access",
        "Early API Access",
        "Limited Access Upto 100000 hits",
      ],
      buttonClass: "bg-black text-white mt-auto", // Move button to the bottom
    },
  ];

  return (
    <div className="mx-auto px-4 sm:px-8 md:px-16 lg:px-64 pt-16 bg-black">
      <h2 className="text-4xl sm:text-5xl italic text-white font-semibold mb-4 text-center">
        Plans We Offer
      </h2>
      <p className="text-md sm:text-lg font text-center text-gray-300 mb-12">
        Start with 7-day free trial. No credit card needed. Cancel at anytime.
      </p>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-10">
        {plans.map((plan, index) => (
          <div
            key={index}
            className={`${plan.containerClass} h-auto`}
          >
            <h3 className="text-2xl sm:text-3xl text-left font-bold mb-4">{plan.title}</h3>
            <p className="text-md sm:text-lg text-left mb-4 mb-8">{plan.summary}</p>
            <p className="text-3xl sm:text-4xl text-left font-bold mb-4">
              {plan.price}
              <span className="text-sm">month</span>
            </p>
            <div className="flex items-center justify-between my-6">
              <hr className="w-full border-gray-300 mt-4 mb-4" />
            </div>
            <ul className="list-none p-0">
              {plan.features.map((feature, index) => (
                <li key={index} className="text-left mb-2">
                  <svg
                    className="w-6 h-6 inline-block mr-2 text-gray-500"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M5 13l4 4L19 7"
                    />
                  </svg>
                  {feature}
                </li>
              ))}
            </ul>
            <Button
              className={`w-full ${plan.buttonClass} font-bold py-2 px-4`}
            >
              Start Trial
            </Button>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Plans;

const FeatureSection = ({
  title,
  subtitle,
  features,
  imageSrc,
  buttonLabel,
  theme,
  imagePosition = "left", // Default position is left
}) => {
  const textColor = theme === "dark" ? "text-white" : "text-black";
  const bgColor = theme === "dark" ? "bg-black" : "bg-gray-100";
  const buttonBg =
    theme === "dark" ? "bg-white text-black" : "bg-black text-white";

  // Determine image width based on feature count for large screens
  const imageWidthClass =
    features.length > 3 ? "lg:w-[50%] xl:w-[55%]" : "lg:w-[50%] xl:w-[50%]";
  const textWidthClass =
    features.length > 3 ? "lg:w-[50%] xl:w-[45%]" : "lg:w-[50%] xl:w-[50%]";

  // Add spacing between text and image when image is on the right
  const spacingClass =
    imagePosition === "right" ? "lg:pr-8 xl:pr-12" : "lg:pl-8 xl:pl-12";

  return (
    <div className={`${bgColor} w-full p-6 md:p-12 lg:p-32 lg:py-12`}>
      {/* Header Section */}
      <div className="flex flex-col md:flex-row justify-between items-start md:items-center mb-6">
        <div className="flex-1">
          <h1
            className={`text-2xl md:text-4xl lg:text-5xl font-bold mb-4 ${textColor}`}
          >
            {title}
          </h1>
          <p className={`text-sm md:text-lg lg:text-xl ${textColor}`}>
            {subtitle}
          </p>
        </div>
      </div>

      {/* Accordion and Image Section */}
      <div
        className={`flex flex-col lg:flex-row flex-wrap ${
          imagePosition === "right" ? "lg:flex-row-reverse" : ""
        }`}
      >
        {/* Image Section */}
        <div className={`w-full ${imageWidthClass} mt-6`}>
          <div className="rounded overflow-hidden">
            <img
              src={imageSrc}
              alt="Placeholder"
              className="lg:w-[90%] h-auto object-cover object-center"
            />
          </div>
        </div>

        {/* Accordion Section */}
        <div
          className={`w-full ${textWidthClass} mt-6 lg:mt-0 ${spacingClass}`}
        >
          {features.map((feature, index) => (
            <div
              key={index}
              className={`py-4 ${
                index === features.length - 1 ? "" : "border-b border-gray-300"
              }`}
            >
              <h2
                className={`text-lg md:text-md lg:text-2xl font-bold mb-2 ${textColor}`}
              >
                {feature.title}
              </h2>
              <div
                className={`text-sm md:text-sm lg:text-xl ${textColor}`}
                dangerouslySetInnerHTML={{ __html: feature.content }}
              />
            </div>
          ))}
          <button
            className={`${buttonBg} text-sm md:text-base py-2 md:py-3 font-bold px-4 md:px-8 rounded-md mt-4`}
          >
            {buttonLabel}
          </button>
        </div>
      </div>
    </div>
  );
};

export default FeatureSection;

import React from "react";
import Footer from "../../components/Footer";
import Navbar from "../../components/Navbar";
import FeatureFooter from "../../components/FeatureFooter";
import BenefitCard from "../../components/BenefitCard";
import KeyFeatures from "../../components/KeyFeatures";
import BenefitCards from "../../components/BenefitCards";

const data = {
  headerTitle: "Key Features of Virtual Science Lab",
  cardsData: [
    {
      title: "Interactive Simulations",
      description:
        "Engage with realistic simulations that mimic real-life experiments, allowing for hands-on learning.",
      icon: "fa-solid fa-location-crosshairs text-3xl",
    },
    {
      title: "Diverse Experiment Library",
      description:
        "Explore a vast library of experiments across multiple scientific disciplines, from simple to advanced.",
      icon: "fa-solid fa-location-crosshairs text-3xl",
    },
    {
      title: "Real-Time Data Analysis ",
      description:
        "Analyze temporal relationships and patterns in scientific or historical datasets.",
      icon: "fa-solid fa-location-crosshairs text-3xl",
    },
    {
      title: "User-Friendly Interface",
      description:
        "Navigate easily through the platform with an intuitive design that makes learning fun and accessible.",
      icon: "fa-solid fa-location-crosshairs text-3xl",
    },
    {
      title: "Collaborative Learning",
      description:
        "Work with peers in virtual labs, share findings, and collaborate on experiments.",
      icon: "fa-solid fa-location-crosshairs text-3xl",
    },
    {
      title: "Visual Element",
      description:
        "An infographic displaying the features as interactive elements, showcasing how users can engage with the platform.",
      icon: "fa-solid fa-location-crosshairs text-3xl",
    },
  ],
};

const benefitsData = {
  title: "Who Benefits From Segmentation?",
  benefits: [
    {
      image: "/assets/images/benefits1.avif",
      title: "For Students",
      description:
        "Enhance your science education with hands-on experiments that reinforce classroom learning.",
    },
    {
      image: "/assets/images/benefits1.avif",
      title: "For Educators",
      description:
        "Incorporate virtual experiments into your curriculum to engage students and foster a love for science.",
    },
    {
      image: "/assets/images/benefits1.avif",
      title: "For Science Enthusiasts",
      description:
        "Explore scientific concepts at your own pace and satisfy your curiosity with interactive experiments.",
    },
  ],
};

const footerData = {
  title: "Explore the Wonders of Science    with Virtual Science Lab",
  description:
    "At Scoolish, we believe in the synergy between human creativity and artificial intelligence. Our mission is to empower users to harness AI as a supportive tool that complements their unique skills and insights, fostering independence and originality in their projects.",
  ctaText1: "Get Started",
  image: "/assets/images/vrlab2.png",
  altText: "Fun Learning",
  className: "pt-11",
};

function VirtualLab() {
  return (
    <>
      <Navbar />
      <div className="bg-black w-full h-screen p-6">
        <div className="flex flex-col md:flex-row items-center bg-black mt-24 pt-14 pr-20 pl-20 pb-10 justify-between">
          <div className="w-full md:w-1/2 mb-6 md:mb-0 px-2">
            <h3 className="text-4xl text-white font-bold mb-2">
              Explore Scientific Experiments Virtually with Virtual Science Lab
            </h3>
            <p className="text-md mb-4 text-white pr-10">
              Dive into the world of science with interactive tools and
              experiments that bring learning to life. Perfect for students,
              educators, and science enthusiasts!
            </p>
            <button className="bg-white hover:bg-gray-300 font-bold text-lg py-3 px-6 rounded-lg mt-6 mr-4">
              Start Your Virtual Experiment Now
            </button>
            <button className="bg-black text-white border-2 border-white hover:bg-gray-300 font-bold text-lg py-2.5 px-16 rounded-lg mt-6 mr-4">
              Learn More
            </button>
          </div>
          <div className="w-full md:w-1/2 pl-6">
            <img
              src="/assets/images/virExp.png"
              alt="AI Homework Helper"
              className="w-full h-auto rounded-xl"
            />
          </div>
        </div>
      </div>
      <div className="bg-gray-100 w-full h-[90vh] p-24 pb-0">
        <div className="flex flex-col md:flex-row items-center  p-6 justify-between">
          <div className="w-full md:w-1/2 mb-6 md:mb-0 px-2">
            <h3 className="text-3xl font-bold mb-2">
              What is Virtual Science Lab?
            </h3>
            <p className="text-lg text-bold mb-8 pr-10 text-justify">
              Virtual Science Lab is an innovative platform that allows users to
              conduct scientific experiments in a virtual environment. With a
              wide range of interactive tools and simulations, you can explore
              complex scientific concepts and enhance your understanding of the
              natural world.
            </p>
            <div className="flex items-center mb-4">
              <input
                type="radio"
                name="solution-type"
                id="experiment"
                className="mr-2"
              />
              <label htmlFor="experiment" className="text-lg font-inter">
                Conduct experiments safely from the comfort of your home.
              </label>
            </div>
            <div className="flex items-center">
              <input
                type="radio"
                name="solution-type"
                id="access"
                className="mr-2"
              />
              <label htmlFor="access" className="text-lg font-inter pr-12 mb-4">
                Access a diverse range of scientific disciplines, including
                chemistry, physics, biology, and more.
              </label>
            </div>
            <div className="flex items-center">
              <input
                type="radio"
                name="solution-type"
                id="enhance"
                className="mr-2"
              />
              <label htmlFor="enhance" className="text-lg font-inter pr-12">
                Enhance learning with interactive simulations that visualize
                complex concepts.
              </label>
            </div>
            <button className="bg-gray-100 border border-black hover:bg-gray-500 font-bold py-3 text-lg  px-8 rounded-xl mt-6">
              Try Now
            </button>
          </div>
          <div className="w-full md:w-1/2">
            <img
              src="/assets/images/vrlab.png"
              alt="AI Homework Helper"
              className="w-full h-[60vh] rounded-xl object-cover"
            />
          </div>
        </div>
      </div>
      <KeyFeatures data={data} />
      <div className="bg-black h-screen p-24">
        <div className="flex justify-between items-center mb-10">
          <h1 className="text-5xl font-semibold text-white text-left mb-0">
            How It Works
          </h1>
          <div className="flex">
            <button className="px-4 py-2 border border-white rounded-lg hover:bg-gray-500">
              <i class="fa fa-angle-left text-white"></i>
            </button>
            <button className="ml-6 px-4 py-2 border border-white rounded-lg hover:bg-gray-500">
              <i class="fa fa-angle-right text-white"></i>
            </button>
          </div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 pt-8">
          <div className="bg-[#323232] rounded-xl p-6">
            <h3 className="text-2xl text-white font-bold mb-4">1. Sign Up</h3>
            <div className="w-full h-[35vh] bg-[#D9D9D9] rounded-xl mb-2">
              <img
                src="/assets/images/how1.png"
                className="w-full h-full rounded-xl object-cover"
              />
            </div>
            <p className="text-md text-white">
              Analyze Text In Over 100 Languages With Advanced Language Models,
              Ensuring Accurate Results Across Global Datasets.
            </p>
          </div>
          <div className="bg-[#323232] rounded-xl p-6">
            <h3 className="text-2xl text-white font-bold mb-4">
              2. Choose an Experiment
            </h3>
            <div className="w-full h-[35vh] bg-[#D9D9D9] rounded-xl mb-2">
              <img
                src="/assets/images/how2.png"
                className="w-full h-full rounded-xl"
              />
            </div>
            <p className="text-md text-white">
              Browse Through Our Extensive Library And Select An Experiment That
              Interests You.
            </p>
          </div>
          <div className="bg-[#323232] rounded-xl p-6">
            <h3 className="text-2xl text-white font-bold mb-4">
              3. Conduct the Experiment
            </h3>
            <div className="w-full h-[35vh] bg-[#D9D9D9] rounded-xl mb-2">
              <img
                src="/assets/images/how3.png"
                className="w-full h-full rounded-xl"
              />
            </div>
            <p className="text-md text-white">
              Use Interactive Tools To Perform The Experiment Virtually.
            </p>
          </div>
        </div>
      </div>
      <BenefitCards data={benefitsData} />
      <FeatureFooter data={footerData} />
      <div className=" bg-gray-100 pt-24 "></div>
      <Footer />
    </>
  );
}

export default VirtualLab;

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "../pages/home/Home";
import LoginForm from "../pages/login/LoginForm";
import SignupForm from "../pages/login/SignupForm";
import Registration from "../pages/login/Registration";
import ProtectedRoute from "./ProtectedRoute";
import Dashboard from "../pages/dashboard/Dashboard";
import Tools from "../pages/home/Tools";
import Feature from "../pages/home/Feature";
import Summarization from "../pages/features/Summarization";
import Segmentation from "../pages/features/Segmentation";
import SentimentAnalysis from "../pages/features/SentimentAnalysis";
import TopicModeling from "../pages/features/TopicModeling";
import Chronology from "../pages/features/Chronology";
import Similarity from "../pages/features/Similarity";
import EntityResolution from "../pages/features/EntityResolution";
import AiHomework from "../pages/tools/AiHomework";
import VirtualLab from "../pages/tools/VirtualLab";
import InteractiveQuiz from "../pages/tools/InteractiveQuiz";
import CosmicConverter from "../pages/tools/CosmicConverter";
import MusicToStudy from "../pages/tools/MusicToStudy";
import Philosophy from "../pages/home/Philosophy";
import CodePlayground from "../pages/tools/CodePlayground";

const AppRouter = () => {
  return (
    <Router>
      <Routes>
        <Route path="*" element={<Home />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/register" element={<Registration />} />
        <Route path="/signup" element={<SignupForm />} />
        <Route path="/login" element={<LoginForm />} />
        <Route path="/tool-page" element={<Tools />} />
        <Route path="/feature-page" element={<Feature />} />
        <Route path="/summarization" element={<Summarization />} />
        <Route path="/segmentation" element={<Segmentation />} />
        <Route path="/sentiment-analysis" element={<SentimentAnalysis />} />
        <Route path="/topic-modeling" element={<TopicModeling />} />
        <Route path="/chronology" element={<Chronology />} />
        <Route path="/similarity" element={<Similarity />} />
        <Route path="/entity-resolution" element={<EntityResolution />} />
        <Route path="/ai-homework" element={<AiHomework />} />
        <Route path="/virtual-lab" element={<VirtualLab />} />
        <Route path="/interactive-quiz" element={<InteractiveQuiz />} />
        <Route path="/cosmic-converter" element={<CosmicConverter />} />
        <Route path="/music-to-study" element={<MusicToStudy />} />
        <Route path="/philosophy" element={<Philosophy />} />
        <Route path="/code-playground" element={<CodePlayground />} />
      </Routes>
    </Router>
  );
};

export default AppRouter;

import React, { useRef } from "react";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import KeyFeatures from "../../components/KeyFeatures";
import FeatureFooter from "../../components/FeatureFooter";

const featureData = {
  headerTitle: "What Makes Code Playground Special?",
  cardsData: [
    {
      icon: "fas fa-code text-3xl",
      title: "Interactive Coding Challenges",
      description: "Engage with fun puzzles that teach real coding skills.",
    },
    {
      icon: "fas fa-gamepad text-3xl",
      title: "Project Creation",
      description: "Build your own interactive games, animations, and more!",
    },
    {
      icon: "fas fa-users text-3xl",
      title: "Kid-Friendly Interface:",
      description: "Easy to use, designed with kids in mind.",
    },
    {
      icon: "fas fa-home text-3xl",
      title: "Learn at Your Own Pace",
      description: "Complete challenges and projects at your own speed.",
    },
    {
      icon: "fas fa-graduation-cap text-3xl",
      title: "Educational Content",
      description: "Access a library of coding tutorials and lessons.",
    },
    {
      icon: "fas fa-shield text-3xl",
      title: "Safe Community",
      description:
        "Share projects and learn together with a supportive community of young coders.",
    },
  ],
};

const footerData = {
  title: "Improve your Kids with Code Playground Now",
  description: `Code Playground is a personalized tool that uses scientifically curated playlists to help you stay focused and motivated during study sessions`,
  ctaText1: "Try Code Playground Now",
  image: "/assets/images/pg5.jpg",
  altText: "Future Learning",
  className: "pt-18",
};

function CodePlayground() {
  const scrollContainerRef = useRef(null);

  const scrollLeft = () => {
    scrollContainerRef.current.scrollBy({
      left: -400, // Adjust based on card width
      behavior: "smooth",
    });
  };

  const scrollRight = () => {
    scrollContainerRef.current.scrollBy({
      left: 400, // Adjust based on card width
      behavior: "smooth",
    });
  };
  return (
    <>
      <Navbar />
      {/* Hero Section */}
      <div className="bg-black w-full h-screen p-6">
        <div className="flex flex-col md:flex-row items-center bg-black mt-24 pt-14 pr-20 pl-20 pb-10 justify-between">
          <div className="w-full md:w-1/2 mb-6 md:mb-0 px-2">
            <h3 className="text-5xl text-white font-bold mb-2">
              Welcome to Code Playground Where Kids Learn to Code and Create!
            </h3>
            <p className="text-lg mb-4 text-white pr-10">
              Explore coding through fun challenges, games, and projects
              designed just for kids!
            </p>
            <button className="bg-white hover:bg-gray-300 font-bold text-lg py-3 px-6 rounded-lg mt-6 mr-4">
              Get Started Now
            </button>
            <button className="bg-black text-white border-2 border-white hover:bg-gray-300 font-bold text-lg py-2.5 px-16 rounded-lg mt-6 mr-4">
              Play Demo
            </button>
          </div>
          <div className="w-full h-[60vh] md:w-1/2 pl-6">
            <img
              src="/assets/images/pg2.jpg"
              alt="Our Philosophy"
              className="w-full h-[60vh] rounded-xl object-cover"
            />
          </div>
        </div>
      </div>
      {/* About Section */}
      <div className="bg-gray-100 w-full h-[90vh] p-24 pb-0">
        <div className="flex flex-col md:flex-row items-center  p-6 justify-between">
          <div className="w-full md:w-1/2 mb-6 md:mb-0 px-2">
            <h3 className="text-3xl font-bold mb-2">
              What is Code Playground for Kids?
            </h3>
            <p className="text-lg text-bold mb-4 pr-10 text-justify">
              Code Playground for Kids is a vibrant, interactive platform
              designed to introduce children to the exciting world of coding.
              Through engaging challenges, fun games, and creative projects,
              kids learn real programming skills in a playful and supportive
              environment.
            </p>
            <div className="flex items-center">
              <label className="text-lg font-inter inline-flex">
                <div className="w-6 h-4 mr-2 mt-2 bg-white border border-black rounded-full"></div>
                <span className="mr-4 mb-2">
                  Interactive Learning: Kids explore coding concepts through
                  hands-on activities, puzzles, and games tailored to their
                  level.
                </span>
              </label>
            </div>
            <div className="flex items-center">
              <label className="text-lg font-inter inline-flex">
                <div className="w-6 h-4 mr-2 mt-2 bg-white border border-black rounded-full"></div>
                <span className="mr-4 mb-2">
                  Creative Freedom: They can design their own animations, build
                  games, and develop interactive projects that showcase their
                  imagination.
                </span>
              </label>
            </div>
            <div className="flex items-center">
              <label className="text-lg font-inter inline-flex">
                <div className="w-6 h-4 mr-2 mt-2 bg-white border border-black rounded-full"></div>
                <span className="mr-4 mb-2">
                  Safe & Kid-Friendly: With a secure community and intuitive
                  interface, it’s a space where kids feel safe to explore and
                  grow.
                </span>
              </label>
            </div>
            <button className="bg-gray-100 border border-black hover:bg-gray-500 font-bold py-3 text-lg  px-8 rounded-xl mt-6">
              Try Now
            </button>
          </div>
          <div className="w-full md:w-1/2">
            <img
              src="/assets/images/pg1.jpg"
              alt="AI Homework Helper"
              className="w-full h-[60vh] rounded-xl"
            />
          </div>
        </div>
      </div>
      {/* Key Features Section */}
      <KeyFeatures data={featureData} />
      {/* How It Works Section */}
      <div className="bg-black h-screen p-24">
        {/* Header Section */}
        <div className="flex justify-between items-center mb-10">
          <h1 className="text-5xl font-semibold text-white text-left mb-0">
            How Code Playground Works
          </h1>
          <div className="flex">
            <button
              onClick={scrollLeft}
              className="px-4 py-2 border border-white rounded-lg hover:bg-gray-500"
            >
              <i className="fa fa-angle-left text-white"></i>
            </button>
            <button
              onClick={scrollRight}
              className="ml-6 px-4 py-2 border border-white rounded-lg hover:bg-gray-500"
            >
              <i className="fa fa-angle-right text-white"></i>
            </button>
          </div>
        </div>

        {/* Scrollable Cards Section */}
        <div
          ref={scrollContainerRef}
          className="flex space-x-6 overflow-x-scroll scroll-smooth no-scrollbar"
        >
          <div className="flex-shrink-0 w-[90%] md:w-[30%] bg-[#323232] rounded-xl p-6">
            <h3 className="text-2xl text-white font-bold mb-4">1. Sign Up</h3>
            <div className="w-full h-[35vh] bg-[#D9D9D9] rounded-xl mb-2">
              <img
                src="/assets/images/ms3.png"
                className="w-full h-full rounded-xl"
              />
            </div>
            <p className="text-md text-white">
              Create your account and choose your coding level.
            </p>
          </div>
          <div className="flex-shrink-0 w-[90%] md:w-[30%] bg-[#323232] rounded-xl p-6">
            <h3 className="text-2xl text-white font-bold mb-4">
              2. Start Exploring
            </h3>
            <div className="w-full h-[35vh] bg-[#D9D9D9] rounded-xl mb-2">
              <img
                src="/assets/images/cp3.png"
                className="w-full h-full rounded-xl "
              />
            </div>
            <p className="text-md text-white">
              Dive into challenges and games right away!
            </p>
          </div>
          <div className="flex-shrink-0 w-[90%] md:w-[30%] bg-[#323232] rounded-xl p-6">
            <h3 className="text-2xl text-white font-bold mb-4">
              3. Build Projects
            </h3>
            <div className="w-full h-[35vh] bg-[#D9D9D9] rounded-xl mb-2">
              <img
                src="/assets/images/cp4.png"
                className="w-full h-full rounded-xl "
              />
            </div>
            <p className="text-md text-white">
              Create interactive projects to showcase your skills
            </p>
          </div>
          <div className="flex-shrink-0 w-[90%] md:w-[30%] bg-[#323232] rounded-xl p-6">
            <h3 className="text-2xl text-white font-bold mb-4">
              4. Share and Learn:
            </h3>
            <div className="w-full h-[35vh] bg-[#D9D9D9] rounded-xl mb-2">
              <img
                src="/assets/images/cp5.png"
                className="w-full h-full rounded-xl "
              />
            </div>
            <p className="text-md text-white">
              Show off your creations, get feedback, and learn from others.
            </p>
          </div>
        </div>
      </div>
      {/* Try Code Playground Section */}
      <div className="p-16 bg-gray-100">
        {/* Header Section */}
        <div className="flex justify-between items-center">
          <div className="flex-1">
            <h1 className="text-4xl text-center text-poppins font-bold text-gray-900 mb-4">
              Try Code Playground Now!
            </h1>
            <p className="text-[#212121] text-center text-xl mb-6">
              {" "}
              Discover How Scoollish Features Simplify Text Analysis, Enhance
              Understanding, <br />
              and Unlock Hidden Insights for Smarter Decisions
            </p>
          </div>
        </div>
        <div className="mx-24 bg-white flex">
          <div className="p-3 w-[50%]">
            {" "}
            <img
              src="/assets/images/pg3.png"
              className="w-full h-[85vh] "
              alt="Segmentation"
            />
          </div>
          <div className="p-3 pl-0 w-[50%]">
            {" "}
            <img
              src="/assets/images/pg4.png"
              className="w-full h-[85vh] object-cover"
              alt="Segmentation"
            />
          </div>
        </div>
      </div>
      <FeatureFooter data={footerData} />
      <div className=" bg-gray-100 pt-24 "></div>
      <Footer />
    </>
  );
}

export default CodePlayground;
